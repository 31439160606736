import { createSlice } from '@reduxjs/toolkit';
import { getPageNameMapping } from 'hooks/Adobe/utils';
import { RootState } from 'redux/store';

export interface AppState {
  isSideBarOpen: boolean;
  pageName: string;
  previousPage: string;
  isPageViewAdobeEventTriggered: boolean;
}

// initial state
const initialState: AppState = {
  isSideBarOpen: false,
  pageName: '',
  previousPage: '',
  isPageViewAdobeEventTriggered: false,
};

// reducer + actions
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    updatePageAndPreviousPage: (state, action) => {
      const { location } = action.payload;
      const { workspaceRoutes } = action.payload;

      const routeToPageNameMap = getPageNameMapping(workspaceRoutes);
      const pageName: string =
        routeToPageNameMap[
          location.pathname as keyof typeof routeToPageNameMap
        ] || location.pathname;

      state.previousPage = state.pageName;
      state.pageName = pageName;
    },

    setPageViewAdobeEventTriggered: (state) => {
      state.isPageViewAdobeEventTriggered = true;
    },
  },
});

export const {
  toggleSidebar,
  updatePageAndPreviousPage,
  setPageViewAdobeEventTriggered,
} = appSlice.actions;

// selectors
export const selectIsSideBarOpen = (state: RootState) =>
  state.app.isSideBarOpen;

export const selectPageName = (state: RootState) => state.app.pageName;

export const selectPreviousPageName = (state: RootState) =>
  state.app.previousPage;

export const selectIsPageViewAdobeEventTriggered = (state: RootState) =>
  state.app.isPageViewAdobeEventTriggered;

export default appSlice.reducer;
