import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { getPrivacyAgreement, savePrivacyAgreement } from './api';

export interface PrivacyAgreementState {
  privacyAgreementAcceptanceStatus: 'accepted' | 'unknown' | 'pending';
  privacyAgreementUpdateApiStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  privacyAgreementError: Error | null;

  privacyAgreementFetchApiStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  privacyAgreementGetError: Error | null;
}

// initial state
const initialState: PrivacyAgreementState = {
  privacyAgreementAcceptanceStatus: 'unknown',
  privacyAgreementUpdateApiStatus: 'idle',
  privacyAgreementError: null,

  privacyAgreementFetchApiStatus: 'idle',
  privacyAgreementGetError: null,
};

// reducer + actions
export const privacyAgreementSlice = createSlice({
  name: 'privacyAgreement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(savePrivacyAgreement.pending, (state) => {
      state.privacyAgreementUpdateApiStatus = 'loading';
    });
    builder.addCase(savePrivacyAgreement.fulfilled, (state, action) => {
      state.privacyAgreementUpdateApiStatus = 'succeeded';
      if (action?.payload === 'success') {
        state.privacyAgreementAcceptanceStatus = 'accepted';
      }
    });
    builder.addCase(savePrivacyAgreement.rejected, (state, action) => {
      state.privacyAgreementUpdateApiStatus = 'failed';
      state.privacyAgreementError = action.payload as Error;
    });

    builder.addCase(getPrivacyAgreement.pending, (state) => {
      state.privacyAgreementFetchApiStatus = 'loading';
    });
    builder.addCase(getPrivacyAgreement.fulfilled, (state, action) => {
      state.privacyAgreementFetchApiStatus = 'succeeded';
      if (action?.payload === 'accepted') {
        state.privacyAgreementAcceptanceStatus = 'accepted';
      } else if (action?.payload === 'pending') {
        state.privacyAgreementAcceptanceStatus = 'pending';
      }
    });
    builder.addCase(getPrivacyAgreement.rejected, (state, action) => {
      state.privacyAgreementFetchApiStatus = 'failed';
      state.privacyAgreementGetError = action.payload as Error;
    });
  },
});

// selectors

export const selectPrivacyAgreementAcceptedStatus = (state: RootState) =>
  state.privacyAgreement.privacyAgreementAcceptanceStatus;

export const selectPrivacyAgreementUpdateApiStatus = (state: RootState) =>
  state.privacyAgreement.privacyAgreementUpdateApiStatus;

export default privacyAgreementSlice.reducer;
