const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const API_ENDPOINTS = {
  chat: `${baseUrl}/genai-chat/v2/chat/`,
  chatHistory: `${baseUrl}/genai-chathistory/v1/chathistory/`,
  generative: `${baseUrl}/genai-generative/v1/generative/`,
  ingest: `${baseUrl}/genai-voyager/v1/ingest`,
  retrieval: `${baseUrl}/genai-retrieval/v1/search/`,
  user: `${baseUrl}/genai-customer-data/v1/user/`,
  workspace: `${baseUrl}/genai-voyager/v1/workspace`,
  customer: `${baseUrl}/genai-customer-data/v1/workspace/`,
  storage: `${baseUrl}/v1/storage`,
  workspaceOption: `${baseUrl}/genai-customer-data/v1/options/`,
  agents: `${baseUrl}/agents-execution-service/v1/execute/`,
  knowledgeSearch: `${baseUrl}/knowledge-search/v1/search`,
  slideLevelSearch: `${baseUrl}/slidelevelsearch/v3`,
};
