import classNames from 'classnames';
import ChatSidebar from 'components/ChatSideBar';
import MenuIconButton from 'components/Sidebar/MenuIconButton';
import SidebarContent from 'components/Sidebar/SidebarContent';
import React from 'react';

import styles from './index.module.scss';

interface SidebarDrawerProps {
  open: boolean;
  onClose(): void;
  isChatHistorySideBar?: boolean;
}

const SidebarDrawer: React.FC<SidebarDrawerProps> = ({
  open,
  onClose,
  isChatHistorySideBar = false,
}) => {
  return (
    <>
      <div
        className={classNames(
          'd-flex flex-column align-items-center flex-grow-1 fixed-top fixed-bottom position-fixed px-2 py-4 shadow-sm d-block d-md-none',
          styles.sidebarDrawerContainer,
          {
            [styles.openContainer]: open,
            [styles.closeContainer]: !open,
          },
        )}
      >
        {isChatHistorySideBar ? (
          <div>
            <div
              className={classNames(styles.chatHistoryComponent, {
                'w-100 d-flex justify-content-end align-items-center': open,
              })}
            >
              <MenuIconButton isExpanded={open} onClick={onClose} />
            </div>
            <ChatSidebar onClose={onClose} />
          </div>
        ) : (
          <SidebarContent onClickMenuIcon={onClose} isExpanded={open} />
        )}
      </div>

      <div
        onClick={onClose}
        className={classNames(
          'position-fixed bg-dark d-block d-md-none',
          styles.sidebarDrawerOverlay,
          {
            [styles.openSideBarDrawerOverlay]: open,
            [styles.closedSideBarDrawerOverlay]: !open,
          },
        )}
      />
    </>
  );
};

export default SidebarDrawer;
