import classNames from 'classnames';
import Icon, { IconSize } from 'components/Icon';
import Loading from 'components/Loading';
import DynamicFormModal from 'components/Modal';
import { AdminWorkspaceConfiguration } from 'components/WorkspaceConfigurations/typings';
import { getUserHrid } from 'oktaConfig';
import { forwardRef, ReactNode, useEffect, useState } from 'react';
import { Dropdown, Table } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { clearUsersFromState } from 'redux/User/slice';
import {
  deleteWorkspace,
  fetchAdminWorkspaceConfigurations,
  updateWorkspaceStatus,
} from 'redux/Workspace/api';
import {
  getUpdateWorkspaceStatus,
  selectAdminAvailableWorkspaceConfigurations,
  selectAdminWorkspaceStatus,
  selectShouldForceRefreshAvailableWorkspaceConfigurations,
  setShouldForceRefreshAvailableWorkspaceConfigurations,
} from 'redux/Workspace/slice';
import { DataSourceType } from 'redux/Workspace/typings';
import { FeatureFlags, isFeatureEnabled } from 'utils/featureflags';
import { formatLocalDateTimeFromISO } from 'utils/workspace';

import styles from './index.module.scss';

interface EllipsisDropdownToggleProps {
  children: ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const WorkspaceListPageContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const availableAdminWorkspaceConfigurations = useAppSelector(
    selectAdminAvailableWorkspaceConfigurations,
  );

  const adminWorkspaceStatus = useAppSelector(selectAdminWorkspaceStatus);

  const isWorkspaceUpdating =
    useAppSelector(getUpdateWorkspaceStatus) === 'loading';

  const shouldForceRefreshAvailableWorkspaceConfigurations: boolean =
    useAppSelector(selectShouldForceRefreshAvailableWorkspaceConfigurations);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [disableCustomerId, setDisableCustomerId] = useState<number>(0);
  const [disableWorkspaceId, setDisableWorkspaceId] = useState<number>(0);
  const [disableWorkspaceName, setDisableWorkspaceName] = useState<string>('');
  const [isUserAllowedWorkspaceCreation, setIsUserAllowedWorkspaceCreation] =
    useState<boolean>(false);
  const [
    isUserAllowedWorkspaceCustomisation,
    setIsUserAllowedWorkspaceCustomisation,
  ] = useState<boolean>(false);

  const allowedHrIds = [
    '413988',
    '423345',
    '415134',
    '417122',
    '435440',
    '344199',
    '383548',
    '999999988',
    '999999989',
    '999999990',
    '999999991',
    '999999980',
    '999999999',
    '999999981',
    '999999982',
    '999999983',
  ];
  const isWorkspaceCreationEnabled = isFeatureEnabled(
    FeatureFlags.WorkspaceCreation,
  );

  useEffect(() => {
    const fetchData = async () => {
      const userHrid = await getUserHrid();
      const isUserAllowed = allowedHrIds.includes(userHrid.toString());
      setIsUserAllowedWorkspaceCreation(isUserAllowed);
      setIsUserAllowedWorkspaceCustomisation(isUserAllowed);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (
      !availableAdminWorkspaceConfigurations?.length ||
      location.state?.createdWorkspaceData || // indicates a redirect from create new workspace page
      shouldForceRefreshAvailableWorkspaceConfigurations
    ) {
      dispatch(fetchAdminWorkspaceConfigurations());
      dispatch(setShouldForceRefreshAvailableWorkspaceConfigurations(false));
      navigate(location.pathname, {}); // clear location state
    }
  }, [
    dispatch,
    availableAdminWorkspaceConfigurations?.length,
    shouldForceRefreshAvailableWorkspaceConfigurations,
  ]);

  const ellipsisDropdownToggle = forwardRef<
    HTMLButtonElement,
    EllipsisDropdownToggleProps
  >(({ children, onClick }, ref) => (
    <button
      type='button'
      className='btn btn-link p-0'
      ref={ref}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <Icon name='ellipsis' size={IconSize.md} />
    </button>
  ));

  const removeInvalidWorkspaces = (
    workspaces: AdminWorkspaceConfiguration[],
  ) => {
    if (!workspaces) return [];

    return workspaces.filter((workspace) => {
      const workspaceStatus = workspace.workspaceStatus?.[1];
      return (
        // check that all properties of workspace are not null, undefined or empty
        // Object.values(workspace).every(value => value !== null && value !== undefined && value !== '') &&

        // check if workspace status is not null, undefined or empty
        workspaceStatus !== null &&
        workspaceStatus !== undefined &&
        workspaceStatus !== ''
      );
    });
  };

  const getDataSourceIcons = (dataSources: DataSourceType[]) => {
    const selectedDataSources: Set<any> = new Set();
    const dataSourceIconMap = {
      [`${DataSourceType.upload}`]: (selectedDataSources: Set<any>) =>
        selectedDataSources.add('upload_outline'),
      [`${DataSourceType.snowflake}`]: (selectedDataSources: Set<any>) =>
        selectedDataSources.add('snowflake'),
      [`${DataSourceType.sql}`]: (selectedDataSources: Set<any>) =>
        selectedDataSources.add('sql'),
      [`${DataSourceType.sharepoint}`]: (selectedDataSources: Set<any>) =>
        selectedDataSources.add('sharepoint'),
      [`${DataSourceType.sharepoint_service}`]: (
        selectedDataSources: Set<any>,
      ) => selectedDataSources.add('sharepoint'),
      [`${DataSourceType.sharepoint_sql}`]: (selectedDataSources: Set<any>) => {
        selectedDataSources.add('sharepoint');
        selectedDataSources.add('sql');
      },
    };
    dataSources.forEach((dataSource) => {
      if (dataSourceIconMap[dataSource]) {
        dataSourceIconMap[dataSource](selectedDataSources);
      } else {
        selectedDataSources.add(DataSourceType.sharepoint);
      }
    });
    return Array.from(selectedDataSources);
  };

  const handleReEnableWorkspace = async (
    customerId: number,
    workspaceId: number,
  ) => {
    console.log('Re-Enable Workspace');
    await dispatch(
      updateWorkspaceStatus({ customerId, workspaceId, status: 'Created' }),
    );
    await dispatch(fetchAdminWorkspaceConfigurations());
    dispatch(setShouldForceRefreshAvailableWorkspaceConfigurations(true));
  };

  const handleDeletionOfWorkspace = async (
    customerId: number,
    workspaceId: number,
  ) => {
    setShowConfirmationModal(false);
    await dispatch(deleteWorkspace(workspaceId));
    await dispatch(fetchAdminWorkspaceConfigurations());
    dispatch(setShouldForceRefreshAvailableWorkspaceConfigurations(true));
    setDisableCustomerId(0);
    setDisableWorkspaceId(0);
    setDisableWorkspaceName('');
    setShowConfirmationModal(true);
  };

  const showDeleteWorkspaceConfirmationModal = (
    customerId: number,
    workspaceID: number,
    workspaceName: string,
  ) => {
    setDisableCustomerId(customerId);
    setDisableWorkspaceId(workspaceID);
    setDisableWorkspaceName(workspaceName);
    setShowConfirmationModal(true);
  };

  const closeDeleteWorkspaceConfirmationModal = () => {
    setShowConfirmationModal(false);
    setDisableCustomerId(0);
    setDisableWorkspaceId(0);
    setDisableWorkspaceName('');
  };

  const renderConfirmationModal = (
    customerId: number,
    workspaceId: number,
    workspaceName: string,
  ) => {
    if (!customerId || !workspaceId) return null;
    return (
      <DynamicFormModal
        title={`Delete workspace`}
        show={showConfirmationModal}
        onHide={() => closeDeleteWorkspaceConfirmationModal()}
        onSave={() => handleDeletionOfWorkspace(customerId, workspaceId)}
        buttonLabel={'Ok'}
        children={
          <div>
            <p>
              Are you sure you want to delete <strong>{workspaceName}</strong>{' '}
              workspace?
            </p>
          </div>
        }
      />
    );
  };

  const handleWorkspaceCustomizationIconClick = (workspaceId: number) => {
    navigate(`/management/customization/${workspaceId}`);
  };

  const renderWorkspaceTable = () => {
    const tableHeadings = [
      'NO',
      'Name of workspace',
      '# Users',
      'Status',
      'Date Created',
      'Datasources',
      'Charge code',
      '',
    ];

    const filteredAdminWorkspaceConfigurations = removeInvalidWorkspaces(
      availableAdminWorkspaceConfigurations,
    );

    const handleUserListClick = (customerId: number) => {
      dispatch(clearUsersFromState());
      navigate(`/management/${customerId}/user/list`);
    };

    const isAdminWorkspaceListLoading = adminWorkspaceStatus === 'loading';

    return (
      <Table responsive className={styles.tableWrapper}>
        <thead>
          <tr>
            {tableHeadings.map((heading, index) => (
              <th className='fw-medium' key={index}>
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isAdminWorkspaceListLoading &&
          filteredAdminWorkspaceConfigurations?.length === 0 ? (
            <tr>
              <td colSpan={tableHeadings.length}>
                <SkeletonTheme baseColor='#f2f2f2' highlightColor='#ccc'>
                  <Skeleton
                    count={10}
                    style={{ marginBottom: '2rem', width: '100%' }}
                    height={20}
                  />
                </SkeletonTheme>
              </td>
            </tr>
          ) : null}
          {filteredAdminWorkspaceConfigurations
            ?.sort(
              (a, b) =>
                new Date(b.workspaceDateCreated).getTime() -
                new Date(a.workspaceDateCreated).getTime(),
            )
            .map((workspace, index) => {
              const workspaceStatus = workspace?.workspaceStatus?.[1];
              const isDeleted = workspaceStatus === 'Deleted';
              const isActiveWorkspace = !isDeleted;
              const dataSources = workspace?.dataSources?.length
                ? workspace.dataSources
                : [DataSourceType.sharepoint];

              return (
                <tr key={index} className='align-middle'>
                  <td>{`${index + 1}.`}</td>
                  <td>{workspace.customerName}</td>
                  <td>
                    <div className='d-flex align-items-center'>
                      {workspace?.totalUsers || 0} Users
                      {isActiveWorkspace && (
                        <div
                          className={`${styles.cursorPointer} ps-2`}
                          onClick={() =>
                            handleUserListClick(workspace.customerId)
                          }
                          data-testid='user-list-link'
                        >
                          <Icon name='pencil_fill' size={IconSize.sm} />
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div
                      className={classNames(
                        'px-2 py-1 rounded-3',
                        styles[
                          workspaceStatus.replace(/\s/g, '').toLowerCase()
                        ],
                      )}
                    >
                      {isDeleted ? 'Inactive' : workspaceStatus}
                    </div>
                  </td>
                  <td>
                    {workspace?.workspaceDateCreated &&
                      formatLocalDateTimeFromISO(
                        workspace?.workspaceDateCreated,
                      )}
                  </td>
                  <td>
                    <div className='d-flex gap-1'>
                      {getDataSourceIcons(dataSources).map((icon, index) => (
                        <Icon
                          name={icon}
                          key={`${icon}-${index}`}
                          size={IconSize.md}
                        />
                      ))}
                    </div>
                  </td>
                  <td>{workspace?.customerChargeCode}</td>
                  {/* {isUserAllowedWorkspaceCustomisation && (
                    <td>
                      <div>
                        <Icon
                          name='workspace_config_icon'
                          size={IconSize.md}
                          className={`cursorPointer ${styles.workspaceConfigIcon}`}
                          onClick={() =>
                            handleWorkspaceCustomizationIconClick(
                              workspace.customerId,
                            )
                          }
                        />
                      </div>
                    </td>
                  )} */}
                  {/* <td>
                    <div className='d-flex gap-3 justify-content-end'> */}
                  {/* to be used in future
                      {isActiveWorkspace && (
                        <div data-testid='workspace-customization-icon'>
                          <Icon
                            name='workspace_config_icon'
                            size={IconSize.md}
                            className={`cursorPointer ${styles.workspaceConfigIcon}`}
                            onClick={() =>
                              handleWorkspaceCustomizationIconClick(
                                workspace.customerId,
                              )
                            }
                          />
                          <Icon name='add_circle_black' size={IconSize.md} />
                        </div>
                      )} */}
                  {/* {isActiveWorkspace ? (
                        isWorkspaceUpdating &&
                        workspace.workspaceID === disableWorkspaceId ? (
                          <Loading className='max-width-20' />
                        ) : (
                          <Icon
                            className='cursor-pointer'
                            name='delete_outline'
                            size={IconSize.md}
                            onClick={() =>
                              showDeleteWorkspaceConfirmationModal(
                                workspace.customerId,
                                workspace.workspaceID,
                                workspace.customerName,
                              )
                            }
                          />
                        )
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle as={ellipsisDropdownToggle} />
                          <Dropdown.Menu title=''>
                            <Dropdown.Item
                              onClick={() =>
                                handleReEnableWorkspace(
                                  workspace.customerId,
                                  workspace.workspaceID,
                                )
                              }
                            >
                              Re-Enable Workspace
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </td> */}
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-12'>
            <h2>Workspace management</h2>
          </div>
        </div>

        <div className='row mt-5 mb-5'>
          <div className='col-12'>
            <div className='d-flex justify-content-between'>
              <h4>
                <Icon name='briefcase_icon' size={IconSize.md} /> List of
                created workspaces
              </h4>
              {isWorkspaceCreationEnabled && isUserAllowedWorkspaceCreation && (
                <button
                  data-testid='create-new-workspace-button'
                  onClick={() => navigate('/management/create')}
                  type='button'
                  className='text-white btn btn-primary d-flex gap-2'
                >
                  <Icon name='add' size={IconSize.md} />
                  Create new workspace
                </button>
              )}
            </div>
          </div>
        </div>
        {availableAdminWorkspaceConfigurations && (
          <div className='row'>
            <div className='col-12'>{renderWorkspaceTable()}</div>
          </div>
        )}
      </div>
      {showConfirmationModal &&
        renderConfirmationModal(
          disableCustomerId,
          disableWorkspaceId,
          disableWorkspaceName,
        )}
    </>
  );
};

export default WorkspaceListPageContent;
