import Icon, { IconSize } from 'components/Icon';
import React from 'react';
import { Modal } from 'react-bootstrap';

import styles from './index.module.scss';

interface PrivacyAgreementModalProps {
  onAgreed: () => void;
  isLoading?: boolean;
}

const PrivacyAgreementModal: React.FC<PrivacyAgreementModalProps> = ({
  onAgreed,
  isLoading = false,
}) => {
  return (
    <Modal
      show={true}
      dialogClassName='modal-dialog-centered'
      size='lg'
      centered={true}
      animation={false}
    >
      <Modal.Header className='justify-content-center'>
        <Modal.Title>
          <div className='d-flex align-items-center gap-3 centered justify-content-center'>
            <Icon name='privacy' size={IconSize.xl} />
            <h5>OUR PRIVACY AGREEMENT HAS BEEN UPDATED</h5>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`text-center ${styles.customModalBody}`}>
        <p className='mt-3'>
          To continue using Voyager, please read and accept{' '}
          <a
            className={`underline ${styles.privacyAgreementLink}`}
            href='https://pages.navigator.bcg.com/kp/bd6ba52c-a4da-4fc5-a8d1-b70b510f7e56'
            target='_blank'
            rel='noreferrer'
          >
            BCG's Responsible AI Policy
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer className='d-flex align-items-center justify-content-center gap-4'>
        <button
          {...(isLoading && { disabled: true })}
          onClick={() => onAgreed()}
          type='button'
          className={`text-white btn btn-primary rounded ms-2 ${styles.customModalButton}`}
        >
          {isLoading ? 'Loading...' : 'ACCEPT'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyAgreementModal;
