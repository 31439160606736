export const getAccessTokenFromStorage = () => {
  const tokenStorageString = localStorage.getItem('okta-token-storage');

  if (!tokenStorageString) {
    console.error('No token storage found in local storage.');
    return null;
  }

  try {
    const tokenStorage = JSON.parse(tokenStorageString);
    const accessToken = tokenStorage?.accessToken?.accessToken;

    if (accessToken) {
      return accessToken;
    } else {
      console.error('No valid access token found in the token storage.');
    }
  } catch (error) {
    console.error('Error parsing token storage:', error);
  }

  return null;
};

export const getApiConfig = () => ({
  key: process.env.REACT_APP_X_API_KEY,
  token: getAccessTokenFromStorage,
  env: process.env.REACT_APP_ENVIRONMENT,
});
