import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { selectIsSideBarOpen, toggleSidebar } from 'redux/App/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import styles from './index.module.scss';
import SidebarContent from './SidebarContent';

const Sidebar: React.FC = () => {
  const isSideBarOpen = useAppSelector(selectIsSideBarOpen);
  const dispatch = useAppDispatch();
  const sideBarContainerRef = useRef<HTMLDivElement>(null);
  const handleClickMenuIcon = () => {
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sideBarContainerRef.current &&
        !sideBarContainerRef.current.contains(event.target as Node) &&
        isSideBarOpen
      ) {
        handleClickMenuIcon();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSideBarOpen]);

  return (
    <div
      ref={sideBarContainerRef}
      className={classNames(
        'd-none d-md-flex flex-column align-items-center flex-grow-1 px-2 py-4 transition-200 vh-100',
        styles.sidebarContainer,
        {
          [styles.openContainerWidth]: isSideBarOpen,
        },
      )}
    >
      <SidebarContent
        onClickMenuIcon={handleClickMenuIcon}
        isExpanded={isSideBarOpen}
      />
    </div>
  );
};

export default Sidebar;
