import React from 'react';
import Form from 'react-bootstrap/Form';

interface SelectProps {
  options: { value: string; label: string }[];
  value: string;
  onChange: any;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
}

const Select: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  width,
  disabled = false,
}) => {
  const capitalize = (label: string) => {
    return label && label.charAt(0).toUpperCase() + label.slice(1);
  };
  return (
    <Form.Select
      className={`form-control ${width}`}
      required
      as='select'
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      {placeholder && (
        <option value='' disabled hidden>
          {placeholder}
        </option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {capitalize(option.label)}
        </option>
      ))}
    </Form.Select>
  );
};

export default Select;
