import Icon from 'components/Icon';
import React from 'react';

import styles from './index.module.scss';

interface MenuIconButtonProps {
  isExpanded: boolean;
  onClick(): void;
}

const MenuIconButton: React.FC<MenuIconButtonProps> = ({
  isExpanded,
  onClick,
}) => {
  return (
    <div
      className={`d-flex align-items-center rounded-circle gap-3 justify-content-center shadow-sm bg-white ${styles.menuIconButtonWrapper}`}
      onClick={onClick}
      data-testid="menu-icon-button"
    >
      <Icon name={isExpanded ? 'close' : 'menu'} />
    </div>
  );
};

export default MenuIconButton;
