export const testUsersAccountData = [
  {
    firstName: 'Enrich',
    lastName: 'Test1',
    email: 'Enrich-Test1@bcg.com',
    id: '999999980',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test2',
    email: 'Enrich-Test2@bcg.com',
    id: '999999999',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test3',
    email: 'Enrich-Test4@bcg.com',
    id: '999999982',
    hostOfficeRegion: 'Beijing',
    hostOfficeLocation: {
      name: 'Beijing PRC',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test4',
    email: 'Enrich-Test5@bcg.com',
    id: '999999983',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test6',
    email: 'Enrich-Test6@bcg.com',
    id: '999999984',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test7',
    email: 'Enrich-Test7@bcg.com',
    id: '999999985',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test8',
    email: 'Enrich-Test8@bcg.com',
    id: '999999986',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test9',
    email: 'Enrich-Test9@bcg.com',
    id: '999999987',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test10',
    email: 'Enrich-Test10@bcg.com',
    id: '999999988',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test11',
    email: 'Enrich-Test11@bcg.com',
    id: '999999989',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test12',
    email: 'Enrich-Test12@bcg.com',
    id: '999999990',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Test13',
    email: 'Enrich-Test13@bcg.com',
    id: '999999991',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
  {
    firstName: 'Enrich',
    lastName: 'Prod',
    email: 'SRV_Enrich_prod@bcg.com',
    id: '999999981',
    hostOfficeRegion: 'Boston',
    hostOfficeLocation: {
      name: 'Boston, MA, USA',
    },
    picture: '',
  },
];
