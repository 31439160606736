import classNames from 'classnames';
import { GeneralSecureRoute, generalSecureRoutes } from 'components/Routes';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectIsUserBlacklisted } from 'redux/User/slice';
import { selectCurrentWorkspace } from 'redux/Workspace/slice';
import { FeatureFlags, isFeatureEnabled } from 'utils/featureflags';

import MenuIconButton from './MenuIconButton';
import MenuItem from './MenuItem';

interface SidebarContentProps {
  isExpanded: boolean;
  onClickMenuIcon(): void;
}

const SidebarContent: React.FC<SidebarContentProps> = ({
  isExpanded,
  onClickMenuIcon,
}) => {
  const location = useLocation();
  const currentPathname = location.pathname;
  const workspace = useAppSelector(selectCurrentWorkspace);
  const isUserBlacklisted = useAppSelector(selectIsUserBlacklisted);

  const routeFeatureFlagFilter = (route: GeneralSecureRoute) => {
    // route that should be behind a feature flag
    const isWorkspaceManagementRoute = route.path.includes('/management');
    if (!isWorkspaceManagementRoute) return true;
    // when feature flag is enabled and logged in user is allowed to access workspace management page
    return isFeatureEnabled(FeatureFlags.WorkspaceManagement);
  };

  const userIsBlacklistedFilter = (route: GeneralSecureRoute) => {
    return !isUserBlacklisted;
  };

  return (
    <>
      <div
        className={classNames({
          'w-100 d-flex justify-content-end align-items-center': isExpanded,
        })}
      >
        <MenuIconButton isExpanded={isExpanded} onClick={onClickMenuIcon} />
      </div>

      <div
        className={classNames('d-flex flex-column gap-4 mt-4', {
          'w-100': isExpanded,
          'w-auto': !isExpanded,
        })}
      >
        {workspace?.routes.map((route) => (
          <MenuItem
            key={route.id}
            icon={route.label.toLowerCase()}
            label={route.label}
            isExpanded={isExpanded}
            active={currentPathname === route.path}
            route={route.path}
          />
        ))}

        {generalSecureRoutes
          .filter((route) => route.showInSidebar)
          .filter((route) => routeFeatureFlagFilter(route))
          .filter((route) => userIsBlacklistedFilter(route))
          .map((route) => (
            <MenuItem
              key={route.id}
              icon={route.icon}
              label={route.label}
              isExpanded={isExpanded}
              active={
                currentPathname === route.path ||
                currentPathname.split('/')[1] === route.path.split('/')[1]
              }
              route={route.path}
            />
          ))}
      </div>
    </>
  );
};

export default SidebarContent;
