import Docviz from 'components/Docviz';
import React from 'react';
import { AvailableAgentTools } from 'redux/AgentsChat/typings';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

type UrlsType = {
  hqUrl: string;
  lqUrl: string;
};

interface Preview {
  pageNumber: number;
  large: UrlsType;
  html: UrlsType;
  medium: UrlsType;
}

const DocvizDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);
  const { fileName, url, consumerId, documentId, previews, s3Object } = props;
  let docvizs3Urls: string | null = null;
  if (
    retrievalMaterialData.agentToolName &&
    retrievalMaterialData.agentToolName === AvailableAgentTools.KNMaterials
  ) {
    if (!url || !s3Object || !previews) {
      console.warn(`DocvizDynamicWrapper: docviz data is missing.`);
      return null;
    }
    docvizs3Urls = `${url}/${s3Object}`;
  } else {
    if (
      !fileName ||
      !url ||
      !consumerId ||
      !documentId ||
      !s3Object ||
      !previews
    ) {
      console.warn(`DocvizDynamicWrapper: docviz data is missing.`);
      return null;
    }

    const trimmedS3Object = s3Object.split('/').pop();
    docvizs3Urls = `${url}/${consumerId}/${documentId}/${trimmedS3Object}`;
  }

  const parsedPreviews = JSON.parse(previews);

  const slides = parsedPreviews.map((previewItem: Preview) => ({
    slideNumber: previewItem?.pageNumber,
    htmlUrl: `${docvizs3Urls}/${previewItem?.html?.hqUrl}`,
    imageRef: `${docvizs3Urls}/${previewItem?.large?.hqUrl}`,
  }));

  const docvizData = {
    fileName: '',
    previewAvailable: true,
    slides: slides,
  };

  let initialSlideNumber = slides?.[0]?.slideNumber;
  if (retrievalMaterialData?.page) {
    initialSlideNumber = parseInt(retrievalMaterialData.page);
  }

  return (
    <Docviz
      key={fileName}
      docvizData={docvizData}
      initialSlideNumber={initialSlideNumber}
    />
  );
};

export default DocvizDynamicWrapper;
