import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import client from './graphqlClient';
import { PeopleAPIUser } from './typings';

const getQuery = (userIds: string[]) => {
  const query = gql`
  query {
    employees (ids: ${JSON.stringify(userIds)})
    {
      id
      firstName
      lastName
      email
      profilePicture
      picture,
      hostOfficeRegion
      hostOfficeLocation {
        name
      }
    }
  }
`;

  return query;
};

const getBulkQuery = (userEmails: string[]) => {
  let fields = '';

  userEmails.forEach((email) => {
    fields += `{ field:"workEmail=${email}" filterType:OR }`;
  });

  const query = gql`
    query {
      searchFilter(
          filters: [${fields}],
          dataSet: BCG_ALL,
      ) {
          employees {
              id
              email
          }
      }
    }
  `;

  return query;
};

export const getManyUsersByIdsFromPeopleApi = async (userIds: string[]) => {
  const requestId = uuidv4();
  console.debug(
    `Starting user/getAndSetManyUsersByIds for request ID: ${requestId}`,
  );
  const functionStartTime = new Date().getTime();

  try {
    const query = getQuery(userIds);

    const { data, errors } = await client.query({
      query,
      variables: { userIds },
    });

    if (errors) {
      throw new Error(`GraphQL query error: ${errors[0].message}`);
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `user/getAndSetManyUsersByIds complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}`,
    );

    return data.employees as PeopleAPIUser[];
  } catch (error) {
    console.error(
      `Error occurred in user/getAndSetManyUsersByIds for request ID: ${requestId}`,
      error,
    );
    // rethrow error so that it can be caught by the calling function
    throw new Error(
      `Error occurred in user/getAndSetManyUsersByIds for request ID: ${requestId}`,
    );
  }
};

export const getManyUsersByEmailFromPeopleApi = async (
  userEmails: string[],
) => {
  const requestId = uuidv4();
  console.debug(
    `Starting user/getManyUsersByEmailFromPeopleApi for request ID: ${requestId}`,
  );
  const functionStartTime = new Date().getTime();

  try {
    const query = getBulkQuery(userEmails);

    const { data, errors } = await client.query({
      query,
      variables: { userEmails },
    });

    if (errors) {
      throw new Error(`GraphQL query error: ${errors[0].message}`);
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `user/getManyUsersByEmailFromPeopleApi complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}`,
    );

    return data.searchFilter.employees as Partial<PeopleAPIUser>[];
  } catch (error) {
    console.error(
      `Error occurred in user/getManyUsersByEmailFromPeopleApi for request ID: ${requestId}`,
      error,
    );
    // rethrow error so that it can be caught by the calling function
    throw new Error(
      `Error occurred in user/getManyUsersByEmailFromPeopleApi for request ID: ${requestId}`,
    );
  }
};

export const getAndSetCurrentUserData = createAsyncThunk<PeopleAPIUser, string>(
  'user/getAndSetCurrentUserData',
  async (currentUserId: string, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting user/getAndSetCurrentUserData for request ID: ${requestId}`,
    );
    const functionStartTime = new Date().getTime();

    try {
      const query = getQuery([currentUserId]);

      const { data } = await client.query({
        query,
        variables: { userIds: [currentUserId] },
      });

      const functionEndTime = new Date().getTime();
      const elapsedFunctionTime = functionEndTime - functionStartTime;
      console.debug(
        `user/getAndSetCurrentUserData complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}`,
      );

      const response = data.employees as PeopleAPIUser[];

      const currentUser: PeopleAPIUser = response[0];

      return currentUser;
    } catch (error) {
      console.error(
        `Error occured in user/getAndSetCurrentUserData for request ID: ${requestId}`,
        error,
      );
      return rejectWithValue(
        `Error occured in user/getAndSetCurrentUserData for request ID: ${requestId}`,
      );
    }
  },
);
