import Icon, { IconSize } from 'components/Icon';
import useAnalytics from 'hooks/Adobe/useAdobeAnalytics';
import React from 'react';
import { selectFilterData, selectFilterQuery } from 'redux/Filters/slice';
import { useAppSelector } from 'redux/hooks';
import { selectLastReceivedUserQuery } from 'redux/Retrieval/slice';
import { selectCurrentWorkspace } from 'redux/Workspace/slice';

import './styles.scss';

type fieldsObject = {
  text: string;
  url: string;
};

export interface TextLinkProps {
  fieldsArray: fieldsObject[];
}

const TextLink: React.FC<TextLinkProps> = ({ fieldsArray }) => {
  const filterQuery = useAppSelector(selectFilterQuery);
  const filterData = useAppSelector(selectFilterData);
  const selectedWorkspace = useAppSelector(selectCurrentWorkspace);
  const retrievalLastReceivedUserQuery = useAppSelector(
    selectLastReceivedUserQuery,
  );
  const {
    recordSearchResultsDownloadAttachmentEvent,
    recordSearchResultsPreviewAttachmentEvent,
  } = useAnalytics();

  let fields = [...fieldsArray];

  // fieldsObject.text and fieldsObject.url MAY be
  // stringified JSON objects, so we need to parse them
  // before we can use them. Clean this up once the API
  // is updated to return the correct data types.
  // TODO - remove this once the API is updated
  fields = fields.map((field) => {
    // check if string is stringified JSON
    if (typeof field.text === 'string' && field.text.startsWith('{')) {
      const parsedText = JSON.parse(field.text);
      field.text = parsedText['File Name'];
    }

    if (typeof field?.url === 'string' && field.url.startsWith('{')) {
      const parsedUrl = JSON.parse(field.url);
      field.url = parsedUrl['Attachment URL'];
    }

    return {
      text: field.text,
      url: field.url,
    };
  });

  return (
    <div className='d-flex flex-column'>
      {fieldsArray.map((field) => (
        <div
          key={field.text}
          className='TextLink rounded-1 d-flex align-items-center fw-medium mb-2 text-decoration-underline bg-white'
          onClick={() => {
            recordSearchResultsDownloadAttachmentEvent({
              resultName: field.text,
              searchTerm: filterQuery,
              previousTerm: retrievalLastReceivedUserQuery,
              previousFilters: filterData,
              workspaceName: selectedWorkspace?.workspaceName ?? '',
            });

            recordSearchResultsPreviewAttachmentEvent({
              resultName: field.text,
              searchTerm: filterQuery,
              previousTerm: retrievalLastReceivedUserQuery,
              previousFilters: filterData,
              workspaceName: selectedWorkspace?.workspaceName ?? '',
            });
            field.url && window.open(field.url, '_blank');
          }}
        >
          <Icon name='link' size={IconSize.sm} />
          {field.text}
        </div>
      ))}
    </div>
  );
};

export default TextLink;
