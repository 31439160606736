import React from 'react';

import styles from './index.module.scss';

export interface ButtonProps {
  className?: string;
  ariaLabel?: string;
  onClick?: Function;
  label?: string;
}
const Icon: React.FC<ButtonProps> = ({
  className,
  ariaLabel,
  onClick,
  label,
}) => {
  const viewEmailButton = label?.includes('View Email');
  const viewEmailStyles = `${styles.buttonGray}  px-2 py-2 fw-medium d-flex justify-content-center align-items-center`;

  return (
    <button
      type='button'
      className={`${className} ${viewEmailButton ? viewEmailStyles : ''}`}
      aria-label={ariaLabel}
      onClick={() => (onClick ? onClick() : null)}
    >
      {label}
    </button>
  );
};

export default Icon;
