import Header from 'components/Header';
import HeaderSkeletonLoader from 'components/Header/HeaderSkeletonLoader';
import PrivacyAgreementModal from 'components/PrivacyAgreement';
import Sidebar from 'components/Sidebar';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { savePrivacyAgreement } from 'redux/PrivacyAgreement/api';
import {
  selectPrivacyAgreementAcceptedStatus,
  selectPrivacyAgreementUpdateApiStatus,
} from 'redux/PrivacyAgreement/slice';
import { selectAvailableWorkspaceConfigurationsStatus } from 'redux/Workspace/slice';
import globalStyles from 'styles/index.module.scss';

import styles from './index.module.scss';

interface DefaultLayoutProps {
  children?: React.ReactNode;
  hideWorkspaceDropdown?: boolean;
  isLoading?: boolean;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  hideWorkspaceDropdown = false,
  children,
  isLoading = false,
}) => {
  const workspaceConfigurationStatus = useAppSelector(
    selectAvailableWorkspaceConfigurationsStatus,
  );
  const privacyPolicyAcceptedStatus = useAppSelector(
    selectPrivacyAgreementAcceptedStatus,
  );
  const isPrivacyAgreementUpdateApiStatusLoading =
    useAppSelector(selectPrivacyAgreementUpdateApiStatus) === 'loading';
  const dispatch = useAppDispatch();

  const workspaceConfigurationStatusLoading =
    workspaceConfigurationStatus === 'loading';

  const handleAgreement = async () => {
    dispatch(savePrivacyAgreement());
  };

  const workspaceConfigurationStatusSuccess =
    workspaceConfigurationStatus === 'succeeded';

  return (
    <>
      {workspaceConfigurationStatusSuccess &&
        privacyPolicyAcceptedStatus === 'pending' && (
          <PrivacyAgreementModal
            onAgreed={handleAgreement}
            isLoading={isPrivacyAgreementUpdateApiStatusLoading}
          />
        )}
      <div className='d-flex flex-row vh-100'>
        <div className='d-flex'>
          <Sidebar />
        </div>
        <div className='d-flex flex-grow-1 flex-column'>
          {workspaceConfigurationStatusLoading ? (
            <HeaderSkeletonLoader />
          ) : (
            <Header hideWorkspaceDropdown={hideWorkspaceDropdown} />
          )}
          <div
            className={`flex-grow-1 overflow-auto ${styles.contentWrapper} ${globalStyles.customScrollBar}`}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
