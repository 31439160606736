import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import Divider from 'components/Divider';
import {
  DynamicComponentNames,
  Layout,
  ParamName,
  RequiredRenderParam,
  WorkspaceConfiguration,
} from 'components/WorkspaceConfigurations/typings';
import dayjs from 'dayjs';
import ButtonDynamicWrapper from 'dynamic/ButtonDynamicWrapper';
import CenteredImageWithTextDynamicWrapper from 'dynamic/CenteredImageWithTextDynamicWrapper';
import DocvizDynamicWrapper from 'dynamic/DocvizDynamicWrapper';
import EmailDynamicWrapper from 'dynamic/EmailDynamicWrapper';
import FilePathDynamicWrapper from 'dynamic/FilePathDynamicWrapper';
import HtmlContentDynamicWapper from 'dynamic/HtmlContentDynamicWrapper';
import IconDynamicWrapper from 'dynamic/IconDynamicWrapper';
import LabelOnTopDynamicWrapper from 'dynamic/LabelOnTopDynamicWrapper';
import PillDynamicWrapper from 'dynamic/PillDynamicWrapper';
import SectionHeaderDynamicWrapper from 'dynamic/SectionHeaderDynamicWrapper';
import SummaryDynamicWrapper from 'dynamic/SummaryDynamicWrapper';
import TextAsLinkDynamicWrapper from 'dynamic/TextAsLinkDynamicWrapper';
import TitleAsLinkDynamicWrapper from 'dynamic/TitleAsLinkDynamicWrapper';
import TitleDynamicWrapper from 'dynamic/TitleDynamicWrapper';
import { RetrievalMaterial } from 'redux/Retrieval/typings';
import { RootState } from 'redux/store';
import { layoutOnClickAction } from 'redux/Workspace/api';

import './styles.scss';

type FileExtensionToIconNameMap = {
  [key: string]: string;
};

export const fileExtensionToIconNameMap: FileExtensionToIconNameMap = {
  ppt: 'powerpointIcon',
  pptx: 'powerpointIcon',
  xls: 'excelIcon',
  csv: 'excelIcon',
  zip: 'zipIcon',
  pdf: 'adobeIcon',
  doc: 'wordIcon',
  docx: 'wordIcon',
  txt: 'txtIcon',
};

/**
 * This utility function helps determine what should be used as the value for a component property.
 * For example, if the component property is "title", this function will determine what value should be used for the title,
 * whether that is a 'static' value (meaning we simply use the value provided) or a 'dynamic' value (meaning we look up the value
 * in the retrieval material data.
 *
 * @param requiredRenderParams The parameters required to render the component
 * @param paramName The name of the component property to get the value for (e.g. title, url, target)
 * @param valueOrLookupKey Either the static value to use for the component property, or the key to use to look up the value in the retrieval material data
 * @param retrievalMaterialData The retrieval material data used for the value of the component property
 * @returns string value to use for the component property
 */
export const getRenderParamValueFromDataByKey = (
  requiredRenderParams: RequiredRenderParam[],
  paramName: ParamName,
  retrievalMaterialData: RetrievalMaterial,
): string | undefined => {
  const renderParam = requiredRenderParams.find(
    (p: RequiredRenderParam) => p.paramName === paramName,
  );

  if (!renderParam) {
    console.warn(
      `Could not find paramName (${paramName}) in requiredRenderParams ${JSON.stringify(
        requiredRenderParams,
        null,
        2,
      )}`,
    );

    return '';
  }

  if (renderParam.paramType === 'static') {
    return renderParam.paramStaticValue;
  }

  const value =
    retrievalMaterialData[
      renderParam.paramDynamicLookupKey as keyof RetrievalMaterial
    ];

  if (value === undefined) {
    return value;
  }

  return typeof value === 'object' ? JSON.stringify(value) : String(value);
};

export const getFileExtensionAndIconName = (
  fileName: string,
): { iconName: string; extension: string } => {
  let iconName = 'attach_file'; // default to attach_file

  // abort early if no extension
  const fileNameDoesNotHaveExtension = fileName.indexOf('.') <= -1;
  if (fileNameDoesNotHaveExtension) {
    return { iconName: iconName, extension: '' };
  }

  // get the extension
  const fileExtension = fileName
    .substring(fileName.lastIndexOf('.') + 1)
    ?.toLowerCase();
  iconName = fileExtensionToIconNameMap[fileExtension];

  return { iconName: iconName, extension: fileExtension };
};

export const formatDate = (timestamp: string, format: string): string => {
  let formattedDate: string;

  // Check if the timestamp is in ISO 8601 format
  if (timestamp.includes('T') || timestamp.includes(' ')) {
    formattedDate = dayjs(timestamp).format(format);
  } else {
    // Assuming it's a Unix timestamp
    formattedDate = dayjs.unix(Number(timestamp)).format(format);
  }

  return formattedDate;
};

export const getDynamicComponentByName = (
  retrievalMaterialData: RetrievalMaterial,
  requiredRenderParams: RequiredRenderParam[],
  componentName: DynamicComponentNames,
) => {
  switch (componentName) {
    case DynamicComponentNames.TitleAsLink:
      return (
        <TitleAsLinkDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.FilePath:
      return (
        <FilePathDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.LabelOnTop:
      return (
        <LabelOnTopDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Summary:
      return (
        <SummaryDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.HtmlContent:
      return (
        <HtmlContentDynamicWapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Pill:
      return (
        <PillDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.SectionHeader:
      return (
        <SectionHeaderDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.TextAslink:
      return (
        <TextAsLinkDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.CenteredImageWithText:
      return (
        <CenteredImageWithTextDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Button:
      return (
        <ButtonDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Title:
      return (
        <TitleDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Docviz:
      return (
        <DocvizDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );

    case DynamicComponentNames.Email:
      return (
        <EmailDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Divider:
      return <Divider />;

    case DynamicComponentNames.AttachmentIcon:
    case DynamicComponentNames.DocumentIcon:
    case DynamicComponentNames.DownloadIcon:
    case DynamicComponentNames.EyeIcon:
    case DynamicComponentNames.CloseIcon:
    case DynamicComponentNames.IllustrationIcon:
    case DynamicComponentNames.DocumentIconName:
      return (
        <IconDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
          componentName={componentName}
        />
      );

    default:
      return null;
  }
};

export const validateWorkspaceConfig = (
  workspaceConfig: WorkspaceConfiguration,
): void => {
  console.debug(
    `Starting workspace configuration validation for ${workspaceConfig.workspaceName}`,
  );
  // routes validation
  const routes = workspaceConfig.routes;

  if (!routes) {
    console.warn(
      `Workspace Validation: routes missing, this could cause issues with the layout. Check the workspace configuration for ${workspaceConfig.workspaceName}`,
    );
  }

  if (!workspaceConfig.search) {
    console.warn(
      `Workspace Validation: search missing, this could cause issues with the layout. Check the workspace configuration for ${workspaceConfig.workspaceName}`,
    );
  }

  console.debug(
    `Workspace configuration validation for ${workspaceConfig.workspaceName} complete. If issues were found, they were logged to the console as warnings.`,
  );
};

type CallbackProps = {
  [key: string]: Function;
};

export const generateResultLayoutComponent = (
  resultLayout: Layout,
  retrievalMaterial: RetrievalMaterial,
  dispatch?: ThunkDispatch<RootState, any, AnyAction>,
  retreivalDataBeforeFetch?: RetrievalMaterial,
): JSX.Element => {
  return (
    <div className={`container ${resultLayout?.customClassNames || ''}`}>
      {resultLayout?.rows?.map((row, i) => (
        <div
          key={row.id}
          onClick={() => {
            if (resultLayout?.onClickAction && dispatch) {
              dispatch(
                layoutOnClickAction({
                  onClickAction: resultLayout.onClickAction,
                  retreivalData: retrievalMaterial,
                }),
              );
            }
          }}
          className={`row align-items-center custom-card-margin
            ${i === 0 ? 'pt-3' : ''} 
           `}
        >
          {row.columns.map((column, i) => (
            <div
              key={column.id}
              className={`col-${column.size} my-2`}
              onClick={() => {
                if (column?.onClickAction && dispatch) {
                  dispatch(
                    layoutOnClickAction({
                      onClickAction: column.onClickAction,
                      retreivalData:
                        retreivalDataBeforeFetch || retrievalMaterial,
                    }),
                  );
                }
              }}
            >
              {getDynamicComponentByName(
                retrievalMaterial,
                column.requiredComponentRenderParams,
                column.componentName,
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export interface DynamicWrapperProps {
  requiredRenderParams: RequiredRenderParam[];
  retrievalMaterialData: RetrievalMaterial;
  callbackProps?: CallbackProps;
}

export const getRenderProps = (
  requiredRenderParams: RequiredRenderParam[],
  retrievalMaterialData: RetrievalMaterial,
) => {
  const paramNames = requiredRenderParams.map((param) => param.paramName);
  const paramNameToParamValue = paramNames.map((paramName) => [
    paramName,
    getRenderParamValueFromDataByKey(
      requiredRenderParams,
      paramName,
      retrievalMaterialData,
    ),
  ]);

  const paramNameToParamValueObject = Object.fromEntries(paramNameToParamValue);

  return paramNameToParamValueObject;
};

export const componentHasAllRelatedDataFields = (
  requiredRenderParams: RequiredRenderParam[],
  retrievalMaterialData: RetrievalMaterial,
) => {
  const requiredRenderParamsWithRelatedDataFieldKey =
    requiredRenderParams.filter((param) => param?.optionalRelatedDataField);

  const optionalRelatedDataFields =
    requiredRenderParamsWithRelatedDataFieldKey.map(
      (param) => param?.optionalRelatedDataField,
    );

  const optionalRelateDataFieldsWithoutUndefined =
    optionalRelatedDataFields.filter((field) => field);

  // ensure all optionalRelatedDataFields are present in the data and not empty if they are arrays
  return optionalRelateDataFieldsWithoutUndefined.every((field) => {
    const value = retrievalMaterialData[field as keyof RetrievalMaterial];
    return Array.isArray(value) ? value.length > 0 : value !== undefined;
  });
};

export const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const calculateTotalFileSize = (selectedFiles: File[]): string => {
  let totalSize = 0;
  selectedFiles.forEach((file: File) => {
    totalSize += file.size;
  });
  return formatBytes(totalSize);
};

export const formatLocalDateTimeFromISO = (isoDateString: string) => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
    date,
  );
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const getLayoutByInternalName = (
  workspace: WorkspaceConfiguration | null,
  layoutName: string | string[] | undefined,
): Layout[] | null => {
  if (!layoutName) return null;

  const layouts = workspace?.search?.layouts;

  if (!layouts) return null;

  if (!Array.isArray(layoutName)) {
    return layouts.filter(
      (layout: Layout) => layout.internalName === layoutName,
    );
  }

  return layouts.filter((layout: Layout) =>
    layoutName.includes(layout.internalName),
  );
};

export const beginsOrEndsWithKTRMS = (str: string) => {
  const regex = /^kt(_?rms)|kt(_?rms)$/i;
  return regex.test(str);
};

export const isKTRMSWorkspace = (workspaceName: string) =>
  beginsOrEndsWithKTRMS(workspaceName);
