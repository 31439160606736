/**
 * Extracts the parent folder name and URL from a given SharePoint URL.
 * @param sharepointUrl The full URL of the SharePoint folder.
 * @returns An object containing the decoded parent folder name and its corresponding URL.
 */
export const getParentFolderData = (
  sharepointUrl: string,
): { name: string; url: string; filePathBreadcrumb: string } => {
  if (!sharepointUrl) {
    return { name: '', url: '', filePathBreadcrumb: '' };
  }

  try {
    const urlParts = sharepointUrl.split('/');
    const PAIndex = 6;

    // Check if there are enough parts in the URL
    if (urlParts.length < 2) {
      return { name: '', url: '', filePathBreadcrumb: '' }; // Not enough segments
    }

    // Extract and decode the parent folder name
    const parentFolderName = decodeURIComponent(urlParts[urlParts.length - 2]);

    // Construct the parent folder URL by removing the last segment
    const parentFolderUrl = urlParts.slice(0, urlParts.length - 1).join('/');

    const decodedURI = decodeURIComponent(sharepointUrl);
    const pathAfterPAName = decodedURI
      .split('/')
      .slice(PAIndex, urlParts.length - 1);
    const filePathBreadcrumb = pathAfterPAName.join(' > ');

    return { name: parentFolderName, url: parentFolderUrl, filePathBreadcrumb };
  } catch (error) {
    console.error('Error parsing SharePoint URL:', error);
    return { name: '', url: '', filePathBreadcrumb: '' };
  }
};
