import { datadogRum } from '@datadog/browser-rum';
import { getUserClaims } from 'oktaConfig';

interface UsaBillaArguments {
  customerId: number;
  workspace: string;
}

export async function usaBillaSetup(usaBillaArguments: UsaBillaArguments) {
  let userEmail = 'unknown';
  const currentUserClaims = await getUserClaims();
  const { customerId, workspace } = usaBillaArguments;
  userEmail = currentUserClaims.sub;
  if (!userEmail) {
    console.error('Usabilla userEmail is null');
  }
  try {
    const ddSessionId = datadogRum.getInternalContext()?.session_id ?? '';
    if (window.usabilla_live) {
      window.usabilla_live('data', {
        email: userEmail,
        custom: {
          app_version: process.env.REACT_APP_VERSION,
          ddSessionId,
          customerId,
          workspace,
        },
      });
    } else {
      console.error('usabilla_live is not available');
    }
  } catch (err) {
    console.error('Error calling usabilla_live:', err);
  }
}
