import classNames from 'classnames';
import Icon, { IconSize } from 'components/Icon';
import { DEFAULT_PAGE_SIZE_CHAT_HISTORY } from 'constants/index';
import useAnalytics from 'hooks/Adobe/useAdobeAnalytics';
import { useCallback, useEffect } from 'react';
import {
  clearChatMessages,
  selectChatFilters,
  selectChatStatus,
  updateChatStreamData,
} from 'redux/AgentsChat/slice';
import {
  AgentChatMessage,
  AgentChatMessageTypes,
  AvailableAgentTools,
  ChatRoles,
  ExaAISource,
  ExpertsSource,
  KNMaterialsSource,
  VoyagerSource,
} from 'redux/AgentsChat/typings';
import { getChatHistory, getChatHistoryMessages } from 'redux/ChatHistory/api';
import {
  incrementPageNumber,
  selectActiveChatHistoryIds,
  selectChatHistoryMessages,
  selectChatHistoryRecords,
  selectGetChatHistoryStatus,
  selectHasNoNextPage,
  selectPageNumber,
  setActiveChatHistoryId,
  setNewChatObjectToChatHistory,
} from 'redux/ChatHistory/slice';
import {
  resetFilterData,
  resetFilterQuery,
  resetlastAppliedFilterData,
  selectFilterQuery,
  selectFilterQueryHistory,
  selectFilterQueryHistoryIndex,
  selectLastAppliedFilterData,
  setFilterQuery,
} from 'redux/Filters/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getRetrievalSearchFilters } from 'redux/Retrieval/api';
import { clearRetrievalState } from 'redux/Retrieval/slice';
import {
  clearKnowledgeSearchData,
  clearlayoutOnClickAction,
  selectCurrentWorkspace,
} from 'redux/Workspace/slice';
import globalStyles from 'styles/index.module.scss';
import { replaceMessageCitationsWithHtmlTags } from 'utils/chat';
import { groupChatHistoryRecordsByDate } from 'utils/chatHistory';
import {
  clearFilterDataFromUrlParam,
  clearFilterQueryFromUrlParam,
} from 'utils/filters';
import { generateUniqueId } from 'utils/strings';

import ChatSidebarSkeletonLoader from './ChatSideBarSkeletonLoader';
import styles from './index.module.scss';

export interface ChatSidebarProps {
  onClose?(): void;
}

const ChatSidebar: React.FC<ChatSidebarProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { recordPreviousChatInteractionEvent } = useAnalytics();
  const filterQuery = useAppSelector(selectFilterQuery);
  const filterQueryHistory = useAppSelector(selectFilterQueryHistory);
  const currentFilterQueryHistoryIndex = useAppSelector(
    selectFilterQueryHistoryIndex,
  );
  const chatStatus = useAppSelector(selectChatStatus);
  const getChatHistoryStatus = useAppSelector(selectGetChatHistoryStatus);

  const chatHistoryMessages = useAppSelector(selectChatHistoryMessages);
  const chatHistoryRecordsForCurrentWorkspace = useAppSelector(
    selectChatHistoryRecords,
  );
  const activeChatHistoryIds = useAppSelector(selectActiveChatHistoryIds);
  const currentWorkspaceConfig = useAppSelector(selectCurrentWorkspace);
  const lastAppliedFilters = useAppSelector(selectLastAppliedFilterData);
  const filterData = useAppSelector(selectChatFilters);

  const hasNoNextPage = useAppSelector(selectHasNoNextPage);

  const currentPageNumber = useAppSelector(selectPageNumber);

  const activeChatHistoryId = activeChatHistoryIds.find(
    (config: any) => config.customerId === currentWorkspaceConfig?.customerId,
  )?.activeChatHistoryId;

  const sortedGroupedRecords = groupChatHistoryRecordsByDate(
    chatHistoryRecordsForCurrentWorkspace,
  );

  const previousSearchFilterQuery =
    filterQueryHistory[currentFilterQueryHistoryIndex - 1];

  useEffect(() => {
    if (getChatHistoryStatus === 'idle') {
      dispatch(
        getChatHistory([DEFAULT_PAGE_SIZE_CHAT_HISTORY, currentPageNumber]),
      );
    }
  }, [dispatch, getChatHistoryStatus, currentPageNumber]);

  // Use useEffect to observe changes in chatHistoryMessages
  useEffect(() => {
    if (chatHistoryMessages?.length) {
      let combinedToolOutput:
        | KNMaterialsSource
        | ExaAISource
        | ExpertsSource
        | VoyagerSource
        | undefined = {};
      let tool: AvailableAgentTools | undefined = undefined;
      chatHistoryMessages.forEach((message: AgentChatMessage) => {
        const newMessage = JSON.parse(JSON.stringify(message));

        if (newMessage.role === ChatRoles.Status_Message) {
          newMessage.type = AgentChatMessageTypes.Status;
          newMessage.role = ChatRoles.Agent;
        }
        if (newMessage.role === ChatRoles.Tool_Output) {
          combinedToolOutput = message?.rawOutput?.[0];
          tool = message?.tool;
        }
        if (
          newMessage.role === ChatRoles.Assistant &&
          combinedToolOutput &&
          tool
        ) {
          newMessage.type = AgentChatMessageTypes.Output;
          newMessage.role = ChatRoles.Agent;
          newMessage.modifiedContent = replaceMessageCitationsWithHtmlTags(
            newMessage.content,
            tool,
            combinedToolOutput,
          );
        }
        if (message?.type !== AgentChatMessageTypes.Log) {
          dispatch(updateChatStreamData(newMessage));
        }
      });
    }
  }, [chatHistoryMessages, dispatch]);

  const closeChatSourcePreview = () => {
    dispatch(clearlayoutOnClickAction());
  };

  // Handle New Chat Click. Currently only possible when chat is not in a loading state
  const handleNewChatClick = () => {
    closeChatSourcePreview();
    dispatch(clearChatMessages());
    dispatch(clearKnowledgeSearchData());
    dispatch(
      setActiveChatHistoryId({
        id: 0,
        customerId: currentWorkspaceConfig?.customerId,
      }),
    );
    dispatch(setNewChatObjectToChatHistory(currentWorkspaceConfig?.customerId));

    // Clear out the rest of the Filter info. Extremly needed when a fresh page loads from the Search page.
    dispatch(resetFilterData());
    dispatch(resetlastAppliedFilterData());
    dispatch(resetFilterQuery());
    dispatch(clearRetrievalState());
    clearFilterDataFromUrlParam();
    clearFilterQueryFromUrlParam();

    // Trigger a "phatom" search to allow the filters Children to be populated
    // Also, the term MUST have a value, an empty search such as `"  "` will return zero results
    const searchTerm = 'a';
    dispatch(setFilterQuery(searchTerm));
    dispatch(getRetrievalSearchFilters());
    dispatch(setFilterQuery(''));
  };

  // Handle Chat History Item Click
  const handleChatHistoryItemClick = useCallback(
    async (chatHistoryId: number) => {
      // check if clicked chat history item is already active
      if (chatHistoryId === activeChatHistoryId) {
        return;
      }

      if (!currentWorkspaceConfig) {
        console.error(
          'Unable to fetch chat history messages. No workspace selected.',
        );
        return;
      }
      // to close the sidebar on mobile view
      onClose?.();

      //close preview section when selecting another chat history
      closeChatSourcePreview();

      dispatch(clearChatMessages());
      dispatch(resetFilterData());
      dispatch(resetlastAppliedFilterData());

      dispatch(
        setActiveChatHistoryId({
          id: chatHistoryId,
          customerId: currentWorkspaceConfig?.customerId,
        }),
      );

      if (!chatHistoryId) {
        return;
      }
      await dispatch(
        getChatHistoryMessages([currentWorkspaceConfig, String(chatHistoryId)]),
      );

      recordPreviousChatInteractionEvent({
        searchTerm: filterQuery,
        previousTerm: previousSearchFilterQuery,
        previousFilters: lastAppliedFilters,
        workspaceName: currentWorkspaceConfig?.workspaceName ?? '',
        sessionId: String(chatHistoryId),
      });
    },
    [currentWorkspaceConfig, dispatch, onClose, activeChatHistoryId],
  );

  const loadPreviousChatHistoryRecords = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation();
    dispatch(incrementPageNumber());
    dispatch(
      getChatHistory([DEFAULT_PAGE_SIZE_CHAT_HISTORY, currentPageNumber + 1]),
    );
  };

  return (
    <div
      className={classNames(
        'd-flex flex-column overflow-auto border-end border-gray d-sm-flex',
        styles.sidebarContainer,
      )}
    >
      <div className='p-3 border-bottom border-gray'>
        <button
          disabled={chatStatus === 'loading'}
          type='button'
          className='text-white btn btn-primary rounded d-flex align-items-center gap-2 w-100 justify-content-center'
          onClick={handleNewChatClick}
        >
          <Icon name='add_circle_outline' size={IconSize.md} />
          New Chat
        </button>
      </div>

      {getChatHistoryStatus === 'loading' ? (
        <ChatSidebarSkeletonLoader />
      ) : (
        <div
          className={`flex-grow-1 overflow-auto ${globalStyles.customScrollBar}`}
        >
          {Object.keys(sortedGroupedRecords).map((dateGroupKey) => {
            const groupUniqueKey = generateUniqueId();
            return (
              <div className='mb-4' key={groupUniqueKey}>
                <div className='border-gray px-3 py-2 fw-medium text-secondary'>
                  {dateGroupKey}
                </div>
                {sortedGroupedRecords[dateGroupKey].map((item) => {
                  const chatHistoryUniqueKey = generateUniqueId();
                  return (
                    <div
                      key={chatHistoryUniqueKey}
                      className={classNames(
                        'd-flex align-items-center gap-3 px-3 py-2 border-gray',
                        {
                          [styles.bgGray200]:
                            item.chatHistoryId === activeChatHistoryId,
                        },
                        styles.cursorPointer,
                        styles.customListItem,
                      )}
                      onClick={() =>
                        handleChatHistoryItemClick(item.chatHistoryId)
                      }
                    >
                      <span className='d-flex justify-content-center align-items-center rounded-2 px-1 bg-secondary bg-opacity-10'>
                        <Icon name='message_icon' size={IconSize.sm} />
                      </span>
                      <div
                        className={`text-truncate ${styles.chatHistoryTitle}`}
                        title={item.title}
                      >
                        {item.title || `Chat ${item.chatHistoryId}`}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}

          {!hasNoNextPage && (
            <div className='mb-2'>
              <div
                className={classNames(
                  'd-flex align-items-center gap-3 px-3 py-2 border-gray',
                  styles.cursorPointer,
                  styles.loadMore,
                )}
                onClick={(event) => loadPreviousChatHistoryRecords(event)}
              >
                <Icon
                  name='chathistory'
                  size={IconSize.md}
                  className={`${styles.loadMoreIcon}`}
                />
                Load More
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatSidebar;
