import Loading from 'components/Loading';

const LoadingPage = () => {
  return (
    <div className='h-100'>
      <Loading className='w-20' />
    </div>
  );
};

export default LoadingPage;
