import Icon, { IconSize } from 'components/Icon';
// import { WorkspaceConfiguration } from 'components/WorkspaceConfigurations/typings';
import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableWorkspaceConfigurations } from 'redux/Workspace/slice';

import styles from './index.module.scss';

const WorkspaceDetailsPageContent = () => {
  const navigate = useNavigate();
  const { workspaceId } = useParams<{ workspaceId: string }>();
  // const [workspace, setWorkspace] = useState<WorkspaceConfiguration>();
  const availableWorkspaces = useAppSelector(
    selectAvailableWorkspaceConfigurations,
  );

  useEffect(() => {
    // load workspaces based on workspaceId
    if (!workspaceId) {
      handleNoWorkspaceFound();
    }

    if (availableWorkspaces.length) {
      getAndSetWorkspace();
    }
  }, [workspaceId, availableWorkspaces]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAndSetWorkspace = () => {
    const workspace = availableWorkspaces.find(
      (workspace) => workspace.id === workspaceId,
    );

    if (!workspace) {
      handleNoWorkspaceFound();
    }

    //setWorkspace(workspace);
  };

  const handleNoWorkspaceFound = () => {
    // if no workspace found, redirect to workspace list
    navigate('/management/list');
  };

  const dummyCardData = [
    {
      text: 'Monthly Costs',
      amount: '$52.42',
      percentage: '5.0%',
    },
    {
      text: 'Weekly Costs',
      amount: '$12.23',
      percentage: '5.0%',
    },
    {
      text: 'YTD',
      amount: '$430.30',
      percentage: '5.0%',
    },
  ];

  const renderCard = (data: any) => {
    return (
      <div className='col-auto mb-4'>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <div className='d-flex align-items-center gap-2 mb-2'>
              <div
                className={`d-flex justify-content-center align-items-center rounded-circle ${styles.circle}`}
              >
                <Icon name='dollar' size={IconSize.sm} />
              </div>
              <Card.Subtitle>{data.text}</Card.Subtitle>
            </div>
            <h2 className='mx-4'>{data.amount}</h2>
            <div className='d-flex align-items-center mt-2 mx-3'>
              <Icon name='call_made' size={IconSize.sm} />
              <Card.Text>{data.percentage}</Card.Text>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-12'>
            <h2>Workspace management</h2>
          </div>
        </div>

        <div className='row mt-3 mb-5'>
          <div className='col-12'>
            <div className='d-flex justify-content-between'>
              <h4>
                <Icon name='briefcase_icon' size={IconSize.md} /> Workspace name
                here
              </h4>
              <div className='d-flex gap-2'>
                <button
                  type='button'
                  className='text-white btn btn-primary rounded d-flex gap-2'
                  onClick={() => navigate('/management/list')}
                >
                  <Icon name='left_arrow_white' size={IconSize.md} />
                  Back
                </button>
                <button type='button' className='btn text-danger d-flex gap-2'>
                  <Icon name='delete' size={IconSize.md} />
                  Delete Workspace
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='row my-4'>
          {dummyCardData.map((el: any) => (
            <React.Fragment key={el.amount}>{renderCard(el)}</React.Fragment>
          ))}
        </div>

        <div className='row my-4'>
          <div className='col-12 mb-3 mb-md-0 col-md-3'>
            <label htmlFor='workspaceName' className='form-label'>
              Name of your workspace
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Workspace name here'
              aria-label='Workspace Name'
            />
          </div>

          <div className='col-12 mb-3 mb-md-0 col-md-2'>
            <label htmlFor='chargeCode' className='form-label'>
              Charge code
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Charge Code'
              aria-label='Charge Code'
            />
          </div>
          <div className='col-12 mb-3 mb-md-0 col-md-2'>
            <label htmlFor='creationDate' className='form-label'>
              Creation date
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Creation Date'
              aria-label='Creation Date'
            />
          </div>
          <div className='col-12 mb-3 mb-md-0 col-md-2 d-flex align-items-end'>
            <button type='button' className='btn btn-outline-primary p-2'>
              <Icon name='edit' size={IconSize.md} />
            </button>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-auto'>
            <label htmlFor='existingDatasources' className='form-label'>
              Existing datasources
            </label>
            <div
              className='d-flex gap-2 px-3 py-2 rounded-2 justify-content-center'
              style={{ background: 'rgba(115, 95, 250, 0.20)' }}
            >
              <Icon name='sharepoint' size={IconSize.md} />
              Connected to SharePoint
            </div>
          </div>
          <div className='col-auto d-flex align-items-end'>
            <div className='d-flex gap-3 align-items-center'>
              <button type='button' className='btn btn-outline-primary p-2'>
                <Icon name='refresh' size={IconSize.md} />
              </button>
              <button
                type='button'
                className='text-white btn btn-primary d-flex gap-2'
              >
                <Icon name='add_circle_outline' size={IconSize.md} />
                Add datasource
              </button>
              <Icon name='delete_outline' size={IconSize.md} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspaceDetailsPageContent;
