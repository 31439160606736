import DefaultLayout from 'components/Layout/DefaultLayout';
import UserListPageContent from 'components/UserListPageContent';
import React from 'react';

const UserListPage: React.FC = () => {
  return (
    <DefaultLayout hideWorkspaceDropdown>
      <UserListPageContent />
    </DefaultLayout>
  );
};

export default UserListPage;
