import ErrorContent from 'components/ErrorContent';
import DefaultLayout from 'components/Layout/DefaultLayout';
import React from 'react';

const GeneralErrorPage: React.FC = () => {
  return (
    <DefaultLayout>
      <ErrorContent />
    </DefaultLayout>
  );
};

export default GeneralErrorPage;
