import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import {
  generateNewChatRecord,
  hasNewChatRecordFromToday,
} from 'utils/chatHistory';

import { getChatHistory, getChatHistoryMessages } from './api';
import { ChatHistoryRecord, ChatHistoryState } from './typings';

const initialState: ChatHistoryState = {
  chatHistoryMessages: [],
  chatHistoryRecords: [],
  createChatHistoryStatus: 'idle',
  updateChatHistoryStatus: 'idle',
  getChatHistoryStatus: 'idle',
  getChatHistoryMessagesStatus: 'idle',
  error: null,
  workspaceConfig: [],
  hasNoNextPage: false,
  pageNumber: 0,
};

const findWorkspaceConfig = (state: ChatHistoryState, customerId: number) => {
  let workspaceConfig = state.workspaceConfig.find((config) => {
    return config.customerId === customerId;
  });

  if (!workspaceConfig) {
    workspaceConfig = {
      customerId: customerId,
      activeChatHistoryId: 0,
      activeChatTitle: '',
    };

    state.workspaceConfig.push(workspaceConfig);
  }

  return workspaceConfig;
};

const chatHistorySlice = createSlice({
  name: 'chatHistory',
  initialState,
  reducers: {
    setActiveChatHistoryId: (state, action) => {
      const workspaceConfig = findWorkspaceConfig(
        state,
        action.payload.customerId,
      );
      workspaceConfig.activeChatHistoryId = action.payload.id;
    },
    setActiveChatHistoryTitle: (state, action) => {
      const workspaceConfig = findWorkspaceConfig(
        state,
        action.payload.customerId,
      );
      workspaceConfig.activeChatTitle = action.payload.title;
    },
    setNewChatObjectToChatHistory: (state, action) => {
      const currentCustomerId = action.payload;

      const records = state.chatHistoryRecords.filter(
        (record) => record.customerId === currentCustomerId,
      );
      if (!hasNewChatRecordFromToday(records)) {
        const newChatRecord: ChatHistoryRecord =
          generateNewChatRecord(currentCustomerId);
        state.chatHistoryRecords.unshift(newChatRecord);

        findWorkspaceConfig(state, currentCustomerId);
      }
    },
    setNewChatHistoryData: (state, action) => {
      const workspaceConfig = findWorkspaceConfig(
        state,
        action.payload.customerId,
      );
      const newChatRecordIndex = state.chatHistoryRecords.findIndex(
        (record) => {
          return (
            record.customerId === action.payload.customerId &&
            record.title === 'New Chat'
          );
        },
      );

      if (workspaceConfig.activeChatTitle !== '' && newChatRecordIndex !== -1) {
        state.chatHistoryRecords[newChatRecordIndex].title =
          workspaceConfig.activeChatTitle;
        state.chatHistoryRecords[newChatRecordIndex].chatHistoryId =
          action.payload.chatHistoryId;
      }
    },

    setHasNoNextPage: (state) => {
      state.hasNoNextPage = true;
    },

    resetChatHistory: (state) => {
      state.hasNoNextPage = false;
      state.chatHistoryRecords = [];
      state.getChatHistoryStatus = 'idle';
      state.pageNumber = 0;
    },

    incrementPageNumber: (state) => {
      state.pageNumber = state.pageNumber + 1;
    },
  },
  extraReducers: (builder) => {
    // get chat history
    builder.addCase(getChatHistory.pending, (state) => {
      state.getChatHistoryStatus = 'loading';
    });
    builder.addCase(getChatHistory.fulfilled, (state, action) => {
      state.getChatHistoryStatus = 'succeeded';

      const { chatHistoryRecords: currentChatHistoryRecords } = state;

      // keep pushing old and new chathistory records into state
      const chatHistoryRecords: ChatHistoryRecord[] = [
        ...currentChatHistoryRecords,
        ...action.payload.chatHistoryResponse.map((chatHistoryRecord) => {
          const record: ChatHistoryRecord = {
            chatHistoryId: chatHistoryRecord.chat_history_id,
            createdDate: chatHistoryRecord.created_date,
            modifiedDate: chatHistoryRecord.modified_date,
            title: chatHistoryRecord.title,
            customerId: chatHistoryRecord.customer_id,
          };
          return record;
        }),
      ];

      // add empty new chat object to chat history
      if (!hasNewChatRecordFromToday(chatHistoryRecords)) {
        const newChatRecord: ChatHistoryRecord = generateNewChatRecord(
          action.payload.currentCustomerId,
        );
        chatHistoryRecords.unshift(newChatRecord);
      }
      state.chatHistoryRecords = chatHistoryRecords;
    });
    builder.addCase(getChatHistory.rejected, (state, action) => {
      state.getChatHistoryStatus = 'failed';
      state.error = action.payload as Error;
    });

    // get chat history messages
    builder.addCase(getChatHistoryMessages.pending, (state) => {
      state.getChatHistoryMessagesStatus = 'loading';
    });
    builder.addCase(getChatHistoryMessages.fulfilled, (state, action) => {
      state.getChatHistoryMessagesStatus = 'succeeded';
      state.chatHistoryMessages = action.payload;
    });
    builder.addCase(getChatHistoryMessages.rejected, (state, action) => {
      state.getChatHistoryMessagesStatus = 'failed';
      state.error = action.payload as Error;
    });
  },
});

// actions
export const {
  setActiveChatHistoryId,
  setActiveChatHistoryTitle,
  setNewChatObjectToChatHistory,
  setNewChatHistoryData,
  setHasNoNextPage,
  resetChatHistory,
  incrementPageNumber,
} = chatHistorySlice.actions;

// selectors
export const selectChatHistoryMessages = (state: RootState) =>
  state.chatHistory.chatHistoryMessages;

export const selectChatHistoryRecords = (state: RootState) =>
  state.chatHistory.chatHistoryRecords;

export const selectActiveChatHistoryIds = (state: RootState) =>
  state.chatHistory.workspaceConfig;

export const selectGetChatHistoryStatus = (state: RootState) =>
  state.chatHistory.getChatHistoryStatus;

export const selectGetChatHistoryMessagesStatus = (state: RootState) =>
  state.chatHistory.getChatHistoryMessagesStatus;

export const selectHasNoNextPage = (state: RootState) =>
  state.chatHistory.hasNoNextPage;

export const selectPageNumber = (state: RootState) =>
  state.chatHistory.pageNumber;

export default chatHistorySlice.reducer;
