import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import oktaAuthService from 'oktaConfig';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_PEOPLE_API_BASE_URL}/domain/v2/people`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${oktaAuthService.getAccessToken()}`,
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_PEOPLE_X_API_KEY || '',
      psId: '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
});

export default client;
