import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import AppRoutes from 'components/Routes';
import ToastAlerts from 'components/ToastAlerts';
import { DataDog } from 'DataDog';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setFilterData, setFilterQuery } from 'redux/Filters/slice';
import { useAppDispatch } from 'redux/hooks';
import {
  getFilterDataFromUrlParam,
  getFilterQueryFromUrlParam,
} from 'utils/filters';

import oktaAuth from './oktaConfig';

const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const initSearchQueryAndFilterDataFromUrlParams = useCallback(() => {
    const existingSearchQueryFromUrlParam = getFilterQueryFromUrlParam();
    if (existingSearchQueryFromUrlParam) {
      dispatch(setFilterQuery(existingSearchQueryFromUrlParam));
    }

    const existingSearchFilterDataFromUrlParam = getFilterDataFromUrlParam();
    const hasExistingSearchFilterDataFromUrlParam =
      Object.keys(existingSearchFilterDataFromUrlParam).length > 0;
    if (hasExistingSearchFilterDataFromUrlParam) {
      dispatch(setFilterData(existingSearchFilterDataFromUrlParam));
    }
  }, [dispatch]);

  // handle app level initializations
  useEffect(() => {
    DataDog.init();

    initSearchQueryAndFilterDataFromUrlParams();
  }, [initSearchQueryAndFilterDataFromUrlParams]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ToastAlerts />
      <AppRoutes />
    </Security>
  );
};

export default App;
