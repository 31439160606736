import React from 'react';

import styles from './index.module.scss';

interface SkeletonShapeProps {
  variant: 'rect' | 'circle';
  width?: number | string;
  height?: number | string;
  className?: string;
  style?: any;
  count?: number;
}

type SkeletonProps = SkeletonShapeProps;
export default function SkeletonLoader(props: SkeletonProps) {
  let style: any = {};
  const isRect = props.variant === 'rect';
  const classProp = props?.className;
  style.width = props.width;
  style.height = props.height;
  style = {
    ...style,
    ...props?.style,
  };

  const countProp = props.count ?? 1;

  return (
    <>
      {Array.from({ length: countProp }, (_, idx) => (
        <div
          key={`skele-loader-${idx}`}
          className={`${classProp} ${styles.skeleton} ${isRect ? styles.skeletonRect : styles.skeletonCircle}`}
          style={style}
        />
      ))}
    </>
  );
}
