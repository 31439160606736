import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { sendAgentChatMessage } from './api';
import {
  AgentChatMessage,
  AgentsChatState,
  AvailableAgentTools,
  UserChatMessage,
} from './typings';

const initialState: AgentsChatState = {
  messages: [],
  filters: [],
  status: 'idle',
  showLoader: false,
  error: null,
  mostRecentChatRequestId: '',
  mostRecentUserMessage: '',
  secondMostRecentUserMessage: '',
  selectedAgentsTool: AvailableAgentTools.Voyager,
};

const agentsChatSlice = createSlice({
  name: 'agentsChat',
  initialState,
  reducers: {
    setMostRecentChatRequestId: (state, action) => {
      state.mostRecentChatRequestId = action.payload;
    },
    updateChatStreamData: (
      state,
      action: PayloadAction<AgentChatMessage | UserChatMessage>,
    ) => {
      const chatMessage = action.payload;
      state.messages.push(chatMessage);
    },
    setChatLoadingState: (state, action) => {
      state.showLoader = action.payload;
    },
    setMostRecentUserMessage: (state, action) => {
      // weird, but for analytics we keep track of the 2 most recent
      // messages a user sent.
      state.secondMostRecentUserMessage = state.mostRecentUserMessage;
      state.mostRecentUserMessage = action.payload;
    },
    setChatFilters: (state, action) => {
      state.filters = action.payload;
    },
    clearChatMessages: (state) => {
      state.messages = [];
      state.filters = [];
    },
    setSelectedAgentsTools: (
      state,
      action: PayloadAction<AvailableAgentTools>,
    ) => {
      state.selectedAgentsTool = action.payload;
    },
    popLastChatMessage: (state) => {
      state.messages.pop();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendAgentChatMessage.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(sendAgentChatMessage.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(sendAgentChatMessage.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error as Error;
    });
  },
});

export const {
  setMostRecentChatRequestId,
  updateChatStreamData,
  setChatLoadingState,
  setMostRecentUserMessage,
  setChatFilters,
  clearChatMessages,
  setSelectedAgentsTools,
  popLastChatMessage,
} = agentsChatSlice.actions;

// selectors
export const selectAgentChatMessages = (state: RootState) =>
  state.agentChat.messages;

export const selectChatMessages = (state: AgentsChatState) => state.messages;

export const selectChatStatus = (state: RootState) => state.agentChat.status;

export const selectChatFilters = (state: RootState) => state.agentChat.filters;

export const selectMostRecentUserMessage = (state: RootState) =>
  state.agentChat.mostRecentUserMessage;

export const selectMostRecentChatRequestId = (state: RootState) =>
  state.agentChat.mostRecentChatRequestId;

export const selectSecondMostRecentUserMessage = (state: RootState) =>
  state.agentChat.secondMostRecentUserMessage;

export const selectChatError = (state: RootState) => state.agentChat.error;

export const selectSelectedAgentsTool = (state: RootState) =>
  state.agentChat.selectedAgentsTool;

export default agentsChatSlice.reducer;
