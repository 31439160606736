import Email from 'components/Email';
import React from 'react';
import { getEmailTextWithAnchorLinks } from 'utils/email';
import { DynamicWrapperProps } from 'utils/workspace';

interface SqlRetrievalMaterialData {
  rank: number;
  SYS_ID: string;
  INSTANCE: string;
  U_ORIGINAL_REQUEST: string;
  u_original_request: string;
  ORIGINAL_BODY_TEXT: string;
  original_body_text: string;
  BODY_TEXT: string;
  body_text: string;
  RECIPIENTS: string;
  SUBJECT: string;
  PRACTICE_AREA: string;
  SECTOR: string;
  ASSIGNEE_HR_ID: string;
  ASSIGNEE_EMAIL: string;
  REQUESTOR_HR_ID: string;
  REQUESTOR_EMAIL: string;
  LATEST_EMAIL_DATE: string;
  REQUEST_ID: string;
  CASE_CODE: string;
  NUM_HOURS: string;
  DATE_OPENED: string;
  DATE_CLOSED: string;
  datasource_type: 'sql';
  s3_file_path: string;
  page_chunk: number;
  page: number;
  word_count: number;
  content: string;
  document_Id: string;
  doc_id: string;
  relevance: number;
  rrf_score: number;
}

interface EmailRetreivalMaterialData {
  query: string;
  exec_time: number;
  results: SqlRetrievalMaterialData[];
  filters: any[];
}

const EmailDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const EmailRetreivalMaterialData =
    retrievalMaterialData as EmailRetreivalMaterialData;
  const retrievalResults = EmailRetreivalMaterialData?.results;

  const sqlFilteredResults = retrievalResults?.filter(
    (result) => result.datasource_type === 'sql',
  );

  // sort emails by page
  const sortedEmailContentArray = sqlFilteredResults?.sort((a, b) => {
    return a.page - b.page;
  });

  const emailContentArray = Array.from(
    new Set(
      sortedEmailContentArray.map((item) => {
        if (item?.BODY_TEXT || item?.body_text) {
          return item.BODY_TEXT || item?.body_text;
        }

        if (item?.ORIGINAL_BODY_TEXT || item?.original_body_text) {
          return item.ORIGINAL_BODY_TEXT || item.original_body_text;
        }

        return item.U_ORIGINAL_REQUEST || item.u_original_request;
      }),
    ),
  );

  const emailContent = emailContentArray.join('\n\n\n');

  const emailTextWithAnchorTags: string =
    getEmailTextWithAnchorLinks(emailContent);

  if (!emailContent) {
    console.warn(`EmailDynamicWrapper: emailContent data is missing.`);
    return null;
  }

  return <Email emailData={emailTextWithAnchorTags} />;
};

export default EmailDynamicWrapper;
