import Button from 'components/Button';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const ButtonDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  return (
    <Button
      ariaLabel={props.ariaLabel}
      label={props.label}
      className={props.className}
    />
  );
};

export default ButtonDynamicWrapper;
