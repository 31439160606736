import { FilterTypes } from 'components/WorkspaceConfigurations/typings';
import { SearchParamFilters } from 'redux/Filters/typings';
import { RetrievalFilter } from 'redux/Retrieval/typings';

export interface ChatFilter {
  name: string;
  type: FilterTypes;
  predicate?: '=' | '<=' | '>=' | '<' | '>';
  filter_values: (string | boolean)[];
}
export interface AgentsChatState {
  messages: Array<AgentChatMessage | UserChatMessage>;
  filters: RetrievalFilter[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: Error | null;
  showLoader: boolean;
  mostRecentChatRequestId: string;
  mostRecentUserMessage: string;
  secondMostRecentUserMessage: string;
  selectedAgentsTool: AvailableAgentTools;
}

export interface AgentChatMessage {
  content: string;
  role: ChatRoles.Agent;
  type: AgentChatMessageTypes;
  tool: AvailableAgentTools;
  filters?: SearchParamFilters;
  modifiedContent?: string;
  rawOutput?: RawOutput;
}

export interface UserChatMessage {
  content: string;
  filters: RetrievalFilter[];
  role: ChatRoles.User;
}

export enum ChatRoles {
  User = 'user',
  Agent = 'agent',
  Status_Message = 'status_message',
  Assistant = 'assistant',
  Tool_Output = 'tool_output',
}

export enum AgentChatMessageTypes {
  Status = 'status',
  Output = 'output',
  Log = 'log',
}

export const VOYAGER_AGENT_ID = '2';

export interface AgentsFrameworkRequestBody {
  agent_id: string;
  input_query: string;
  request_id: string;
  consumer_id: string;
  selected_tools: AvailableAgentTools[];
  chat_history_id?: number;
  execution_payload?: VoyagerAgentExecutionPayload;
}

export interface VoyagerAgentExecutionPayload {
  voyager?: {
    Voyager: {
      workspace_id: string;
      filters: ChatFilter[];
    };
    prompt_input_variables: {
      date: string;
    };
  };
  chat_history_metadata: {
    customer_id: string;
  };
}

export enum AvailableAgentTools {
  KNMaterials = 'KN Materials',
  Experts = 'Experts',
  ExaAI = 'Exa AI',
  Voyager = 'Voyager',
}

type RawOutput =
  | KNMaterialsSource[]
  | ExaAISource[]
  | ExpertsSource[]
  | VoyagerSource[];

export interface AgentMessageData {
  message_type: AgentChatMessageTypes;
  request_id: string;
  consumer_id: string;
  tool: string;
  message_text: string;
  tool_data: {
    text_output: [
      {
        text: string;
        chunk_instance: number;
        tags: string[];
      },
    ];
    raw_output: RawOutput;
  };
  chat_history_id: number;
}

export type ChatHistoryId = number;

export interface AvailableAgentChatOptions {
  icon: string;
  title: string;
  tool: AvailableAgentTools;
  show: boolean;
  description: string;
  filtersEnabled: boolean;
}

export interface KNMaterialsSource {
  [key: string]: {
    title: string;
    kp_cms_id: string;
    page: number;
    filename: string;
    document_id: string;
    authors: string[];
    date: string;
  };
}

export interface KNMaterialsExtractedData {
  citationnumber: string; // lower case because the html attributes are in lower case
  filename: string; // lower case because the html attributes are in lower case
  page: string;
  link: string;
  kp_cms_id: string;
}

export interface ExaAISource {
  [key: string]: {
    url: string;
    author: string;
    published_date: string;
    title: string;
  };
}

export interface ExaAIExtractedData {
  href: string;
  title: string;
}

export interface ExpertsSource {
  [key: string]: {
    hrid: number;
    name: string;
    isSeniorAdvisor: boolean;
    isECT: boolean;
    inferredQuery: string;
    businessTitle: string;
    location: string;
    organizationName: string;
    cases: string;
    recentCases: string;
    materials: string;
    recentMaterials: string;
    isAssignedTopicsMatched: boolean;
    isProposalBiosMatched: boolean;
  };
}

export interface ExpertsExtractedData {
  href: string;
}

export interface VoyagerSource {
  [key: string]: Record<string, unknown>; // Voyager data is dynamic per customer/workspace
}

export interface VoyagerExtractedData {
  citationnumber: string; // lower case because the html attributes are in lower case
  filename: string; // lower case because the html attributes are in lower case
  page: string;
  datasource_type: string;
  doc_id: string;
}

export type SourceData =
  | KNMaterialsSource
  | ExaAISource
  | ExpertsSource
  | VoyagerSource;
