import { createAsyncThunk } from '@reduxjs/toolkit';
import { WorkspaceConfiguration } from 'components/WorkspaceConfigurations/typings';
import { AgentChatMessage } from 'redux/AgentsChat/typings';
import { RootState } from 'redux/store';
import { fetchData } from 'utils/apiRequest';
import { API_ENDPOINTS } from 'utils/constants';
import { v4 as uuidv4 } from 'uuid';

import { setHasNoNextPage } from './slice';
import {
  GetChatHistoryMessagesRequestData,
  GetChatHistoryResponseData,
} from './typings';

export const getChatHistory = createAsyncThunk<
  {
    currentCustomerId: number;
    chatHistoryResponse: GetChatHistoryResponseData[];
  },
  [number, number]
>(
  'chatHistory/getChatHistory',

  async ([pageSize, pageNumber], { rejectWithValue, getState, dispatch }) => {
    const requestId = uuidv4();
    const currentState = getState() as RootState;

    if (!currentState.workspace.currentSelectedWorkspace?.customerId) {
      return rejectWithValue(
        `Unable to get chat history. Workspace customer ID is undefined for request ID: ${requestId}`,
      );
    }

    const currentCustomerId =
      currentState.workspace.currentSelectedWorkspace.customerId;

    console.debug(`Starting get chat history for request ID: ${requestId}`);
    const functionStartTime = new Date().getTime();

    const getChatHistoryApiUrl = `${API_ENDPOINTS.chatHistory}?page_size=${pageSize}&page_num=${pageNumber}&customer_id=${currentCustomerId}`;

    const response = await fetchData(getChatHistoryApiUrl, 'GET');
    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling get chat history API for request ID ${requestId}. Data: ${data}`,
      );
      return rejectWithValue(data);
    }

    let data: GetChatHistoryResponseData[] = [];
    // await response.json() fails for statusCode === 204, as no response content is present from API
    if (response.status === 200) {
      data = (await response.json()) as GetChatHistoryResponseData[];
    } else if (response.status === 204) {
      dispatch(setHasNoNextPage());
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Get Chat History complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}`,
    );

    return {
      currentCustomerId,
      chatHistoryResponse: data,
    };
  },
);

export const getChatHistoryMessages = createAsyncThunk<
  AgentChatMessage[],
  [WorkspaceConfiguration, string]
>(
  'chatHistory/getChatHistoryMessages',

  async ([currentWorkspaceConfig, chatHistoryId], { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting get chat history messages for request ID: ${requestId}`,
    );
    const functionStartTime = new Date().getTime();

    const workspaceChatCustomerId = currentWorkspaceConfig.customerId;
    const workspaceChatConsumerId = currentWorkspaceConfig.workspaceName;
    if (!workspaceChatCustomerId) {
      return rejectWithValue(
        `Unable to get chat history. Workspace customer ID is undefined for request ID: ${requestId}`,
      );
    }

    const getChatHistoryMessagesApiUrl = `${API_ENDPOINTS.chatHistory}${chatHistoryId}`;

    const requestBody: GetChatHistoryMessagesRequestData = {
      requestId,
      customer_id: Number(workspaceChatCustomerId),
      consumer_id: workspaceChatConsumerId,
    };

    const response = await fetchData(
      getChatHistoryMessagesApiUrl,
      'POST',
      requestBody,
    );

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling create chat history API for request ID ${requestId}. Data: ${data}`,
      );
      return rejectWithValue(data);
    }

    const data = (await response.json()) as AgentChatMessage[];

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Get Chat History Messages complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}`,
    );

    return data;
  },
);
