import { ChatHistoryRecord } from 'redux/ChatHistory/typings';

export const groupChatHistoryRecordsByDate = (
  chatHistoryRecords: ChatHistoryRecord[],
): Record<string, ChatHistoryRecord[]> => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const previous7Days = new Date(today);
  previous7Days.setDate(previous7Days.getDate() - 7);
  const previous30Days = new Date(today);
  previous30Days.setDate(previous30Days.getDate() - 30);

  const groupedData: Record<string, ChatHistoryRecord[]> = {
    Today: [],
    Yesterday: [],
    'Previous 7 Days': [],
    'Previous 30 Days': [],
  };

  chatHistoryRecords.forEach((record) => {
    const recordDate = new Date(record.modifiedDate || record.createdDate);
    if (recordDate >= today) {
      groupedData['Today'].push(record);
    } else if (recordDate >= yesterday) {
      groupedData['Yesterday'].push(record);
    } else if (recordDate >= previous7Days) {
      groupedData['Previous 7 Days'].push(record);
    } else if (recordDate >= previous30Days) {
      groupedData['Previous 30 Days'].push(record);
    } else {
      const monthYear = recordDate.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      });
      if (!groupedData[monthYear]) {
        groupedData[monthYear] = [];
      }
      groupedData[monthYear].push(record);
    }
  });

  Object.keys(groupedData).forEach((key) => {
    groupedData[key].sort((a, b) => {
      if (a.chatHistoryId === 0) {
        return -1; // a comes first
      } else if (b.chatHistoryId === 0) {
        return 1; // b comes first
      } else {
        return b.chatHistoryId - a.chatHistoryId; // sort by chatHistoryId in descending order
      }
    });
  });
  return groupedData;
};

export const generateNewChatRecord = (
  customerId: number,
): ChatHistoryRecord => {
  const today = new Date();
  const todayInISO = today.toISOString();

  const newChatObject: ChatHistoryRecord = {
    chatHistoryId: 0,
    createdDate: todayInISO,
    modifiedDate: todayInISO,
    title: 'New Chat',
    customerId,
  };

  return newChatObject;
};

export const hasNewChatRecordFromToday = (
  chatHistoryRecords: ChatHistoryRecord[],
): boolean => {
  const today = new Date();
  const todayInISO = today.toISOString().split('T')[0];
  const todayChatHistory = chatHistoryRecords.find(
    (chat) => chat.modifiedDate.split('T')[0] === todayInISO,
  );
  return (
    todayChatHistory !== undefined && todayChatHistory?.title === 'New Chat'
  );
};
