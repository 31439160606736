import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as WorkspaceManagementIcon } from 'assets/icons/workspace_management_icon.svg';
import classNames from 'classnames';
import useAdobeAnalytics from 'hooks/Adobe/useAdobeAnalytics';
import { getPageNameMapping } from 'hooks/Adobe/utils';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentWorkspace } from 'redux/Workspace/slice';

import styles from './index.module.scss';

interface MenuItemProps {
  isExpanded: boolean;
  icon: string;
  label: string | React.ReactNode;
  active: boolean;
  route: string;
}

interface SvgIconProps {
  height: number;
  width: number;
  className: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  isExpanded,
  icon,
  label,
  active,
  route,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { recordLinkInteractionEvent } = useAdobeAnalytics();
  const selectedWorkspace = useAppSelector(selectCurrentWorkspace);

  const navPressed = () => {
    if (location.pathname === route) {
      return;
    }

    if (selectedWorkspace?.routes) {
      const routeToPageNameMap = getPageNameMapping(selectedWorkspace?.routes);
      const previousPageName: string =
        routeToPageNameMap[
          location.pathname as keyof typeof routeToPageNameMap
        ] || location.pathname;

      const currentPageName: string =
        routeToPageNameMap[route as keyof typeof routeToPageNameMap] || route;

      recordLinkInteractionEvent({
        linkName: label?.toString() ?? '',
        sectionName: 'Sidebar',
        workspaceName: selectedWorkspace?.workspaceName ?? '',
        previousPage: previousPageName,
        pageName: currentPageName,
      });
    }

    if (route) {
      navigate(route);
    }
  };

  const SvgIcon = ({ height, width, className }: SvgIconProps) => {
    let iconToUse: JSX.Element | null = null;

    switch (icon) {
      case 'workspace_management_icon':
        iconToUse = (
          <WorkspaceManagementIcon
            height={height}
            width={width}
            className={className}
          />
        );
        break;
      case 'chat':
        iconToUse = (
          <ChatIcon height={height} width={width} className={className} />
        );
        break;
      case 'search':
        iconToUse = (
          <SearchIcon height={height} width={width} className={className} />
        );
        break;

      default:
        break;
    }

    return iconToUse;
  };

  return (
    <div
      data-testid={`${icon}-sidebar-button`}
      onClick={navPressed}
      className={classNames(
        'd-flex align-items-center gap-3',
        styles.menuItemWrapper,
        {
          [styles.expanded]: isExpanded,
          'w-100 justify-content-start': isExpanded,
          'justify-content-center': !isExpanded,
          [styles.active]: active,
        },
      )}
    >
      {icon && (
        <SvgIcon
          height={16}
          width={16}
          className={classNames({
            'text-white': active,
            'text-primary': !active,
          })}
        />
      )}

      {isExpanded && label && (
        <div
          className={classNames({
            'text-white': active,
          })}
        >
          {label}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
