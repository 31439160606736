import Icon, { IconSize } from 'components/Icon';
import {
  CustomIconNames,
  DynamicComponentNames,
} from 'components/WorkspaceConfigurations/typings';
import {
  DynamicWrapperProps,
  fileExtensionToIconNameMap,
  getFileExtensionAndIconName,
  getRenderParamValueFromDataByKey,
} from 'utils/workspace';

export interface IconDynamicWrapperProps extends DynamicWrapperProps {
  componentName: DynamicComponentNames;
}

const IconDynamicWrapper: React.FC<IconDynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
  componentName,
}) => {
  const getIcon = () => {
    let iconName: CustomIconNames | string = '';
    let iconSize: number = 0;

    switch (componentName) {
      case DynamicComponentNames.DocumentIcon: {
        const field = getRenderParamValueFromDataByKey(
          requiredRenderParams,
          'field',
          retrievalMaterialData,
        );
        iconName = field
          ? getFileExtensionAndIconName(field).iconName
          : iconName;
        iconSize = IconSize.md;
        break;
      }
      case DynamicComponentNames.DocumentIconName: {
        const field = getRenderParamValueFromDataByKey(
          requiredRenderParams,
          'field',
          retrievalMaterialData,
        );
        iconName = fileExtensionToIconNameMap[field || iconName];
        iconSize = IconSize.md;
        break;
      }
      case DynamicComponentNames.AttachmentIcon: {
        const field = getRenderParamValueFromDataByKey(
          requiredRenderParams,
          'field',
          retrievalMaterialData,
        );

        const hasNoAttachment =
          retrievalMaterialData?.attachment_url?.length === 0;

        if (!field || hasNoAttachment) {
          iconName = '';
          iconSize = 0;
          break;
        }

        iconName = CustomIconNames.AttachFile;
        iconSize = IconSize.md;
        break;
      }
      case DynamicComponentNames.DownloadIcon: {
        iconName = CustomIconNames.Download;
        iconSize = IconSize.md;
        break;
      }
      case DynamicComponentNames.EyeIcon: {
        iconName = CustomIconNames.Eye;
        iconSize = IconSize.lg;
        break;
      }
      case DynamicComponentNames.CloseIcon: {
        iconName = CustomIconNames.Close;
        iconSize = IconSize.lg;
        break;
      }
      case DynamicComponentNames.IllustrationIcon: {
        iconName = CustomIconNames.Illustartion;
        iconSize = 186;
        break;
      }
      default: {
        iconName = '';
        iconSize = 0;
      }
    }

    return { name: iconName, size: iconSize };
  };

  if (!getIcon().name || !getIcon().size) {
    return null;
  }

  return <Icon name={getIcon().name} size={getIcon().size} />;
};

export default IconDynamicWrapper;
