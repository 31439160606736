import { Modal } from 'react-bootstrap';

interface DynamicFormModalProps {
  title: string;
  show: boolean;
  onHide: () => void;
  onSave: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonLabel: string;
  children: React.ReactNode;
  disableSaveButton?: boolean;
  hideFooterButtons?: boolean;
}

const DynamicFormModal: React.FC<DynamicFormModalProps> = ({
  title,
  show,
  onHide,
  onSave,
  buttonLabel,
  children,
  disableSaveButton,
  hideFooterButtons,
}) => {
  const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onSave(event);
  };

  return (
    <Modal show={show} onHide={onHide} dialogClassName='modal-dialog-centered'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className='d-flex'>
        <button
          className='text-white btn btn-primary rounded flex-grow-1 me-2'
          onClick={onHide}
          data-testid='modal-cancel-button'
          hidden={hideFooterButtons}
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          disabled={disableSaveButton}
          hidden={hideFooterButtons}
          type='button'
          className='text-white btn btn-primary rounded flex-grow-1 ms-2'
          data-testid={`modal-${buttonLabel.replace(/\s+/g, '-')}-button`}
        >
          {buttonLabel}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DynamicFormModal;
