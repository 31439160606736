import Icon, { IconSize } from 'components/Icon';
import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { selectChatError } from 'redux/AgentsChat/slice';
import { useAppSelector } from 'redux/hooks';
import { selectBulkAddUsersFailedUserIds } from 'redux/User/slice';

interface ToastError {
  message: string;
  createdAt: Date;
  timeSinceCreation: string;
  show: boolean;
}

const ToastAlerts = () => {
  const [errors, setErrors] = useState<ToastError[]>([]);
  const chatError = useAppSelector(selectChatError);
  const bulkAddUsersFailedUserIds = useAppSelector(
    selectBulkAddUsersFailedUserIds,
  );

  // listen to errors as needed
  useEffect(() => {
    if (chatError) {
      const error: ToastError = {
        message:
          'An error occurred while sending your message, please try again shortly. The dev team has been alerted of this incident.',
        createdAt: new Date(),
        timeSinceCreation: '0 seconds ago',
        show: true,
      };

      setErrors((prevErrors) => [...prevErrors, error]);
    }

    if (bulkAddUsersFailedUserIds.length > 0) {
      const error: ToastError = {
        message:
          'An error occurred while adding users to the workspace, please see logs for more detail.',
        createdAt: new Date(),
        timeSinceCreation: '0 seconds ago',
        show: true,
      };

      setErrors((prevErrors) => [...prevErrors, error]);
    }

    // add more error listeners here as needed
  }, [chatError, bulkAddUsersFailedUserIds]);

  // update time since creation every second
  useEffect(() => {
    const interval = setInterval(() => {
      setErrors((prevErrors) =>
        prevErrors.map((error) => ({
          ...error,
          timeSinceCreation: calculateTimeSinceAlertCreation(error.createdAt),
        })),
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const calculateTimeSinceAlertCreation = (createdAt: Date) => {
    const now = new Date();
    const timeSinceAlertCreation = now.getTime() - createdAt.getTime();

    const seconds = Math.floor(timeSinceAlertCreation / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
      return `${hours} hours ago`;
    }

    if (minutes > 0) {
      return `${minutes} minutes ago`;
    }

    return `${seconds} seconds ago`;
  };

  const handleCloseToast = (index: number) => {
    setErrors((prevErrors) =>
      prevErrors.map((error, i) => ({
        ...error,
        show: i !== index ? error.show : false,
      })),
    );
  };

  return (
    <ToastContainer position='bottom-end' className='p-3' style={{ zIndex: 1 }}>
      {errors.map((error, index) => (
        <Toast
          key={index}
          onClose={() => handleCloseToast(index)}
          show={error.show}
          delay={15000}
          autohide
        >
          <Toast.Header>
            <Icon
              name='synap_logo'
              size={IconSize.md}
              className='rounded me-2'
            />
            <strong className='me-auto'>Voyager</strong>
            <small className='text-muted'>{error.timeSinceCreation}</small>
          </Toast.Header>
          <Toast.Body>{error.message}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};

export default ToastAlerts;
