import DefaultLayout from 'components/Layout/DefaultLayout';
import WorkspaceCreatePageContent from 'components/WorkspaceCreatePageContent';
import React from 'react';

const WorkspaceCreatePage: React.FC = () => {
  return (
    <DefaultLayout hideWorkspaceDropdown>
      <WorkspaceCreatePageContent />
    </DefaultLayout>
  );
};

export default WorkspaceCreatePage;
