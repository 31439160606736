export interface UserRequestPayload {
  role: UserRole;
  userId: number;
}

export interface BulkUserRequestPayload {
  role: UserRole;
  userEmails: string[];
}

export interface WorkspaceAPIUserResponse {
  is_cust_admin: boolean;
  user_id: number;
  customer_id: number;
}

export interface WorkspaceAPIUser {
  isCustomerAdmin: boolean;
  userId: number;
  customerId: number;
  role: UserRole;
}

export type UserRole = 'user' | 'admin';

export interface PeopleAPIUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
  hostOfficeRegion: string;
  hostOfficeLocation: {
    name: string;
  };
}

export type MergedWorkspaceAPIAndPeopleAPIUser = WorkspaceAPIUser &
  PeopleAPIUser;

export interface SelectedRoles {
  user_id: number;
  is_cust_admin: boolean;
  isRoleUpdated: boolean;
}
export interface UserState {
  roles: ['user', 'admin'];

  // addUserToWorkspace function
  addUserToWorkspaceStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  addUserToWorkspaceError: Error | null;

  // bulkAddUsersToWorkspace function
  bulkAddUsersToWorkspaceStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  bulkAddUsersToWorkspaceError: Error | null;
  bulkAddUsersSuccessfulUserIds: string[];
  bulkAddUsersFailedUserIds: string[];
  bulkUserAddedCompletionPercentage: number;

  // removeUserFromWorkspace function
  removeUserFromWorkspaceStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  removeUserFromWorkspaceError: Error | null;

  // bulkUpdateUserRoles function
  bulkUpdateUserRolesStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  bulkUpdateUserRolesError: Error | null;

  // fetchWorkspaceApiAndPeopleApiUsers function
  fetchWorkspaceApiAndPeopleApiUsersStatus:
    | 'idle'
    | 'loading'
    | 'succeeded'
    | 'failed';
  fetchWorkspaceApiAndPeopleApiUsersError: Error | null;
  workspaceAPIUsers: WorkspaceAPIUser[];
  peopleAPIUsers: PeopleAPIUser[];

  // getAndSetCurrentUserData function
  currentUser: PeopleAPIUser | null;
  getAndSetCurrentUserDataStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  getAndSetCurrentUserDataError: Error | null;

  isUserBlacklisted: boolean;
}
export interface AddUserToWorkspaceObjRequestBody
  extends RemoveUserFromWorkspaceObjRequestBody {
  is_cust_admin: boolean;
}

export interface RemoveUserFromWorkspaceObjRequestBody {
  request_id: string;
  consumer_id: string;
  user_id: number;
  customer_id: number;
}

export interface updateBulkUsers {
  customer_id: string;
  updatedRoles: { user_id: number; is_cust_admin: boolean }[];
}

export const NEERS_HRID = '383548';
