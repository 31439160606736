import DefaultLayout from 'components/Layout/DefaultLayout';
import WorkspaceCustomizationContent from 'components/WorkspaceCustomizationContent';
import React from 'react';

const WorkspaceCustomizationPage: React.FC = () => {
  return (
    <DefaultLayout hideWorkspaceDropdown>
      <WorkspaceCustomizationContent />
    </DefaultLayout>
  );
};

export default WorkspaceCustomizationPage;
