import {
  AnyAction,
  createAsyncThunk,
  Dispatch,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { WorkspaceConfiguration } from 'components/WorkspaceConfigurations/typings';
import { encode } from 'gpt-tokenizer';
import { RetrievalMaterial } from 'redux/Retrieval/typings';
import { RootState } from 'redux/store';
import { fetchData } from 'utils/apiRequest';
import { API_ENDPOINTS } from 'utils/constants';
import {
  getAssistantTextFromCompletionData,
  isGenerativeCompletion,
  isUserMessage,
} from 'utils/generative';
import { v4 as uuidv4 } from 'uuid';

import {
  popLastGenerativeMessage,
  updateGenerativeRequestId,
  updateGenerativeStreamData,
} from './slice';
import {
  GenerativeCompletionData,
  GenerativeMessage,
  GenerativeMessageRequestData,
  GenerativeMessageResponseData,
  GenerativeUserRoles,
  UserMessageData,
} from './typings';

const DEFAULT_MAX_TOKENS_TO_SEND = 28000;

export interface SearchSummaryContext {
  contextHeader: string;
  contextData: any;
}

export const removeElementsUntilTokenLimitReached = (
  searchSummaryContext: string,
  contextArray: SearchSummaryContext[],
  maxTokens: number,
  prompt: string,
  requestId: string,
  includeUserQueryInRequest: boolean,
  userQuery: string,
) => {
  let tempSearchSummaryContext = searchSummaryContext;
  let tempContextArray = [...contextArray];

  let totalTokens = encode(tempSearchSummaryContext).length;
  if (includeUserQueryInRequest) {
    totalTokens += encode(userQuery).length;
  }

  while (totalTokens > maxTokens && tempContextArray.length > 0) {
    console.log(
      `Total tokens for context is ${totalTokens}. Max tokens allowed are ${maxTokens}. Removing last element from context array to reduce context size for request ID ${requestId}.`,
    );

    tempContextArray.pop(); // remove the last element from the array

    tempSearchSummaryContext = generateSearchSummaryContextString(
      prompt,
      tempContextArray,
    );

    totalTokens = encode(tempSearchSummaryContext).length;
    if (includeUserQueryInRequest) {
      totalTokens += encode(userQuery).length;
    }
  }

  return {
    searchSummaryContext: tempSearchSummaryContext,
    contextArray: tempContextArray,
  };
};

const handleAssistantResponse = (
  lastMessage: GenerativeMessage,
  assistantResponseText: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  try {
    if (lastMessage?.role === GenerativeUserRoles.Assistant) {
      const concatenatedMessage = lastMessage.content + assistantResponseText;
      dispatch(popLastGenerativeMessage());
      dispatch(
        updateGenerativeStreamData({
          content: concatenatedMessage,
          role: GenerativeUserRoles.Assistant,
        }),
      );
    } else {
      dispatch(
        updateGenerativeStreamData({
          content: assistantResponseText,
          role: GenerativeUserRoles.Assistant,
        }),
      );
    }
  } catch (error) {
    console.error(
      `Error handling assistant response in handleAssistantResponse: ${error}`,
    );
  }
};

export const generateContextHeadersAndDataArray = (
  retrievalMessages: RetrievalMaterial[],
  currentSelectedWorkspace: WorkspaceConfiguration,
  requestId: string,
): SearchSummaryContext[] => {
  const contextArray: SearchSummaryContext[] = [];

  const contextFormattingOptions =
    currentSelectedWorkspace.search.searchSummary.contextFormattingOptions;

  if (contextFormattingOptions.length === 0) {
    console.error(
      `Context formatting options are missing for request ID ${requestId} in sendGenerativeMessage`,
    );
    return contextArray;
  }

  // if context formatting options has more than one "all" data source type, the customer has misconfigured their workspace
  const allDataSourceTypeCount = contextFormattingOptions.filter(
    (option) => option.dataSourceTypeToApplyTo === 'all',
  ).length;
  if (allDataSourceTypeCount > 1) {
    console.error(
      `Context formatting options has more than one "all" data source type for request ID ${requestId} in sendGenerativeMessage`,
    );
    return contextArray;
  }

  retrievalMessages.forEach((material: RetrievalMaterial) => {
    const contextFormattingOptionToUse = contextFormattingOptions.find(
      (option) =>
        option.dataSourceTypeToApplyTo === material?.datasource_type ||
        option.dataSourceTypeToApplyTo === 'all',
    );

    if (!contextFormattingOptionToUse) {
      console.error(
        `Context formatting options are missing for request ID ${requestId} in sendGenerativeMessage. Skipping for material: ${JSON.stringify(
          material,
          null,
          2,
        )}`,
      );
      return;
    }

    const dataFieldsToInclude =
      contextFormattingOptionToUse.dataFieldsToInclude;
    const regexesToApply = contextFormattingOptionToUse.regexesToApply;

    const header: string[] = [];
    const data: any[] = [];

    // customer wants to include all fields into their context
    if (dataFieldsToInclude === 'all') {
      Object.keys(material).forEach((key) => {
        let value = material[key];

        // NOTE - we are skipping fields that are not strings or numbers
        if (typeof value !== 'string' && typeof value !== 'number') {
          return;
        }
        // NOTE - we convert numbers to strings
        if (typeof value === 'number') {
          value = value.toString();
        }

        const regexToApply = regexesToApply?.find(
          (regex) =>
            regex.fieldToApplyTo === key || regex.fieldToApplyTo === 'all',
        );

        // apply regex if provided
        if (regexToApply) {
          value.replace(
            new RegExp(regexToApply.pattern, 'g'),
            regexToApply.replaceWith,
          );
        }

        header.push(key);
        data.push(value);
      });

      contextArray.push({
        contextHeader: header.join('\t'),
        contextData: data.join('\t'),
      });
    } else {
      // customer wants to include specific fields into their context
      dataFieldsToInclude.forEach((key) => {
        let value = material[key];

        if (!value) {
          console.error(
            `One of the fields ${key} was missing when creating the context data for request ID ${requestId} in sendGenerativeMessage. Material: ${JSON.stringify(
              material,
              null,
              2,
            )}`,
          );
        }

        // NOTE - we are skipping fields that are not strings or numbers
        if (typeof value !== 'string' && typeof value !== 'number') {
          return;
        }
        // NOTE - we convert numbers to strings
        if (typeof value === 'number') {
          value = value.toString();
        }

        const regexToApply = regexesToApply?.find(
          (regex) =>
            regex.fieldToApplyTo === key || regex.fieldToApplyTo === 'all',
        );

        // apply regex if provided
        if (regexToApply) {
          value.replace(
            new RegExp(regexToApply.pattern, 'g'),
            regexToApply.replaceWith,
          );
        }

        header.push(key);
        data.push(value);
      });

      contextArray.push({
        contextHeader: header.join('\t'),
        contextData: data.join('\t'),
      });
    }
  });

  return contextArray;
};

export const generateSearchSummaryContextString = (
  prompt: string,
  contextArray: SearchSummaryContext[],
): string => {
  let context = `${prompt}CONTEXT:\n\n`;

  contextArray.forEach((contextData) => {
    const header = contextData.contextHeader;
    const data = contextData.contextData;
    context += `${header}\n${data}\n\n`;
  });

  return context;
};

export const sendGenerativeMessage = createAsyncThunk<
  string,
  [RetrievalMaterial[], string],
  {
    rejectValue: any;
    dispatch: Dispatch<AnyAction>;
    state: RootState;
  }
>(
  'generative/sendGenerativeMessage',
  async (
    [retrievalMessages, userQuery],
    { rejectWithValue, dispatch, getState },
  ) => {
    const requestId = uuidv4();
    console.debug(
      `Starting send generative message for request ID: ${requestId}`,
    );
    const functionStartTime = new Date().getTime();
    const currentState = getState() as RootState;
    const currentSelectedWorkspace: WorkspaceConfiguration | null =
      currentState.workspace.currentSelectedWorkspace;

    if (!currentSelectedWorkspace) {
      return rejectWithValue(
        `Unable to call Generative api. Workspace is undefined for request ID: ${requestId}`,
      );
    }

    const workspaceSearchSummaryConsumerId =
      currentSelectedWorkspace?.workspaceName;
    if (!workspaceSearchSummaryConsumerId) {
      return rejectWithValue(
        `Unable to call Generative api. Workspace consumer ID is undefined for request ID: ${requestId}`,
      );
    }

    const generativeApiUrl = API_ENDPOINTS.generative;

    const tokenLimit =
      currentSelectedWorkspace.search.searchSummary.maxTokensToSend ||
      DEFAULT_MAX_TOKENS_TO_SEND;
    const includeUserQueryInRequest =
      currentSelectedWorkspace.search.searchSummary.includeUserQueryInRequest;
    const prompt = currentSelectedWorkspace.search.searchSummary.prompt;

    const contextArray: SearchSummaryContext[] =
      generateContextHeadersAndDataArray(
        retrievalMessages,
        currentSelectedWorkspace,
        requestId,
      );

    const searchSummaryContext = generateSearchSummaryContextString(
      prompt,
      contextArray,
    );

    const {
      searchSummaryContext: searchSummaryContextWithMaxTokenLimitApplied,
    } = removeElementsUntilTokenLimitReached(
      searchSummaryContext,
      contextArray,
      tokenLimit,
      prompt,
      requestId,
      includeUserQueryInRequest,
      userQuery,
    );

    const generativeMessages: GenerativeMessage[] = [
      {
        content: searchSummaryContextWithMaxTokenLimitApplied,
        role: GenerativeUserRoles.System,
      },
    ];

    if (includeUserQueryInRequest) {
      generativeMessages.push({
        content: userQuery,
        role: GenerativeUserRoles.User,
      });
    }

    const requestBody: GenerativeMessageRequestData = {
      messages: generativeMessages,
      gen_options: {
        stop: '',
        temperature: 0.7,
        max_tokens: 800,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        stream: true,
      },
      openai_api_options: {
        openai_api_secretid:
          'openai-key-knsearch-loadbalanced_eur20231113170449100500000001',
        openai_api_type: 'azure',
        openai_api_version: '2024-02-15-preview',
        openai_api_base: 'https://bcg-kd-apim-knsearch-euro.azure-api.net',
      },
      request_id: requestId,
      consumer_id: workspaceSearchSummaryConsumerId,
      engine: 'gpt-4o',
      context: '',
    };

    dispatch(updateGenerativeRequestId(requestId));
    const response = await fetchData(generativeApiUrl, 'POST', requestBody);

    if (!response.ok) {
      const data = await response.json();
      console.error(
        `Error calling generative API for request ID ${requestId}. Data: ${data}`,
      );
      return rejectWithValue(data);
    }

    const streamReader = response.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader();

    if (!streamReader) {
      return rejectWithValue(
        `Unable to get reader from response body, request ID: ${requestId}`,
      );
    }

    let chunkBuffer = '';
    let lastProcessedChunkInstance: number = -1;

    return new Promise<string>((resolve, reject) => {
      streamReader
        .read()
        .then(function processChunk({ done, value: currentChunk }) {
          if (done) {
            console.debug(
              `Generative stream completed for request ID: ${requestId}.`,
            );
            resolve(userQuery); // Resolve the promise with userQuery when streaming is complete
            return;
          }

          const chunks = chunkBuffer + currentChunk;

          try {
            const mergedObject = chunks
              .split('\n')
              .filter(Boolean)
              .map((line) => line.replace(/^data: /, '').trim())
              .reduce<GenerativeMessageResponseData>((acc, line) => {
                const parsedLine = JSON.parse(line);
                if (
                  chunkBuffer.length &&
                  parsedLine.chunk_instance <= lastProcessedChunkInstance
                ) {
                  console.debug(
                    `Skipping line with chunk_instance ${parsedLine.chunk_instance}`,
                  );
                } else {
                  const message = { data: parsedLine };
                  if (isGenerativeCompletion(message)) {
                    acc =
                      Object.keys(acc).length === 0
                        ? message
                        : {
                            ...acc,
                            data: {
                              ...(acc.data as GenerativeCompletionData),
                              choices: [
                                ...(acc.data as GenerativeCompletionData)
                                  .choices,
                                ...message.data.choices,
                              ],
                            },
                          };
                  }
                  lastProcessedChunkInstance = parsedLine.chunk_instance;
                }

                return acc;
              }, {} as GenerativeMessageResponseData);

            chunkBuffer = ''; // reset chunk buffer
            let assistantText = '';

            if (isUserMessage(mergedObject)) {
              assistantText =
                (mergedObject.data as UserMessageData).user_message + '\n\n';
            } else if (isGenerativeCompletion(mergedObject)) {
              assistantText = getAssistantTextFromCompletionData(
                mergedObject.data as GenerativeCompletionData,
              );
            }

            const currentGenerativeMessages = currentState.generative.messages;
            const lastMessage = currentGenerativeMessages.at(
              -1,
            ) as GenerativeMessage;

            handleAssistantResponse(lastMessage, assistantText, dispatch);
          } catch (error) {
            console.error(`Error processing chunk: ${error}`);
            console.debug(
              'Encountered invalid JSON, adding current chunk to buffer and reading next chunk',
            );
            chunkBuffer += currentChunk;
          }

          console.debug('reading next chunk...');
          streamReader
            .read()
            .then(processChunk)
            .catch((error) => {
              console.log('error in reading next chunk', error);
              reject(error); // Reject the promise if there's an error reading the next chunk
            });
        })
        .catch((error) => {
          console.error('Error reading first chunk:', error);
          reject(error); // Reject the promise if there's an error reading the first chunk
        });
      const functionEndTime = new Date().getTime();
      const elapsedFunctionTime = functionEndTime - functionStartTime;
      console.debug(
        `Send Generative Message complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}`,
      );
    });
  },
);
