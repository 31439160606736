import { CardView, CardViewSubject } from '@kdshared/docviz';
import React, { useEffect, useMemo, useState } from 'react';
import { getApiConfig } from 'utils/docviz/docvizConfig';

import './styles.scss';

type DocvizDataObj = {
  fileName: string;
  previewAvailable: boolean;
  slides: { slideNumber: number; htmlUrl: string; imageRef: string };
};

export interface DocvizProps {
  docvizData: DocvizDataObj;
  initialSlideNumber: number;
}

const Docviz: React.FC<DocvizProps> = ({ docvizData, initialSlideNumber }) => {
  const docVizActions = {
    showPreview: false,
    showEyePreview: false,
    showDownload: false,
    showShare: false,
    showEmail: false,
    showDownloadDropDown: false,
  };
  const [slideNumber, setSlideNumber] = useState(initialSlideNumber);

  const memoDocvizData = useMemo(() => docvizData, []);

  useEffect(() => {
    const subscription = CardViewSubject.subscribe({
      next: (props: any) => {
        setSlideNumber(props?.slide?.slideNumber);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const bar: HTMLElement | null = document.querySelector(
        '.progressContainer .ui.progress .bar',
      );
      if (bar) {
        const width = parseFloat(bar.style.width);
        const newMargin = `${width}%`;
        bar.style.marginLeft = newMargin;
      }
    }, 10);

    return () => clearTimeout(timeoutId);
  }, [slideNumber, memoDocvizData]);

  return (
    <div className='customDocvizContainer'>
      <CardView
        doc={memoDocvizData}
        initialSlideNumber={initialSlideNumber}
        apiConfig={getApiConfig()}
        entitled
        isSmallDevice={false}
        truncatedBody={true}
        colorTheme='light'
        thumbnailSize='small'
        isDetailCardView={true}
        actions={docVizActions}
        showActions={false}
      />
    </div>
  );
};

export default Docviz;
