import React from 'react';

import './styles.scss';

export interface CenteredImageWithTextProps {
  icon: string;
  text1: string;
  text2: string;
}

const CenteredImageWithText: React.FC<CenteredImageWithTextProps> = ({
  icon,
  text1,
  text2,
}) => {
  return (
    <div className='d-flex justify-content-center align-items-center mt-5 flex-column ImageWithText'>
      <img
        src={require(`assets/icons/${icon}.svg`)}
        alt={icon}
        width='186px'
        height='174px'
      />
      <div className='d-flex align-items-center flex-column mt-4'>
        <div className='fw-medium'>{text1}</div>
        <div className='mt-1 fw-normal'>{text2}</div>
      </div>
    </div>
  );
};

export default CenteredImageWithText;
