import { PeopleAPIUser, WorkspaceAPIUser } from 'redux/User/typings';

import { testUsersAccountData } from './testUserAccounts';

export const extractUserIds = (users: WorkspaceAPIUser[]): string[] => {
  return users.map((user) => `${user.userId}`);
};

export const createWorkspaceUserDataLookup = (
  workspaceAPIUsers: WorkspaceAPIUser[],
) => {
  const userDataLookup: Record<string, WorkspaceAPIUser> = {};

  for (const user of workspaceAPIUsers) {
    if (user.userId) {
      userDataLookup[user.userId.toString()] = user;
    }
  }

  return userDataLookup;
};

export const mergeWorkspaceUsersWithUserAPIData = (
  peopleAPIUsersData: PeopleAPIUser[],
  userDataLookup: Record<string, WorkspaceAPIUser>,
) => {
  const mergedUsers: (PeopleAPIUser & WorkspaceAPIUser)[] = [];

  // loop through user data lookup
  for (const userId in userDataLookup) {
    const workspaceUser = userDataLookup[userId];
    const peopleAPIUser = peopleAPIUsersData.find((user) => user.id === userId);
    const testDataUser = testUsersAccountData.find(
      (user) => user.id === userId,
    );

    if (peopleAPIUser) {
      mergedUsers.push({ ...peopleAPIUser, ...workspaceUser });
    } else if (testDataUser) {
      mergedUsers.push({ ...testDataUser, ...workspaceUser });
    }
  }

  return mergedUsers;
};
