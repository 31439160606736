import TextAsLink from 'components/TextAsLink';
import { decode } from 'html-entities';
import React from 'react';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const TextAsLinkDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.fieldsArray) {
    console.warn(
      `TextAsLinkDynamicWrapper: fieldsArray is missing. fieldsArray: ${props.fieldsArray}`,
    );
    return null;
  }

  const decodeFileUrl = (url: string): string => {
    try {
      let tempDecodedURI: string = decodeURIComponent(url).trim();
      // Need to decode twice
      // for ex: string "70" is parsed and sent from API as &amp;quot;70&amp;quot;
      // After applying first decode output =  &quot;70&quot;
      // After applying second decode output = "70"
      tempDecodedURI = decode(tempDecodedURI, { level: 'html5' });
      tempDecodedURI = decode(tempDecodedURI, { level: 'html5' });
      return tempDecodedURI;
    } catch (err) {
      console.error('Something went wrong while decoding URI', err);
      return url;
    }
  };

  const data = JSON.parse(props.fieldsArray).map(
    (item: string | { doc_url: string; filename: string }) => {
      if (typeof item === 'string') {
        return {
          text: item,
          url: item,
        };
      }

      // item.filename maybe encoded from API
      // so we need to decode it
      return {
        text: decodeFileUrl(item.filename),
        url: item.doc_url,
      };
    },
  );

  return <TextAsLink fieldsArray={data} />;
};

export default TextAsLinkDynamicWrapper;
