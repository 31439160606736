import Icon, { IconSize } from 'components/Icon';

import './styles.scss';

export interface LabelOnTopProps {
  label: string;
  field?: string;
  icon?: string;
}

const LabelOnTop: React.FC<LabelOnTopProps> = ({ label, field, icon }) => {
  return (
    <>
      {icon && <Icon name={icon} size={IconSize.sm} />}
      <div className='labelOnTop fw-normal '>{label}</div>
      <div className='labelOnTop__field fw-medium'>{field || '-'}</div>
    </>
  );
};

export default LabelOnTop;
