import { ArrayFieldTemplateProps } from '@rjsf/utils';
import React from 'react';
import { Accordion } from 'react-bootstrap';

const ArrayFieldTemplate: React.FC<ArrayFieldTemplateProps> = React.memo(
  (props) => {
    const { title, items, onAddClick } = props;

    return (
      <Accordion>
        <Accordion.Item eventKey='1'>
          <Accordion.Button>
            <h6>{title}</h6>
          </Accordion.Button>
          <Accordion.Body>
            {items.map((element, index) => {
              const { hasRemove, onDropIndexClick } = element;
              return (
                <div key={index} className='border p-3 mb-4'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-11'>{element.children}</div>
                      <div className='col-1'>
                        {hasRemove && (
                          <button onClick={onDropIndexClick(index)}>
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <button onClick={onAddClick}>Add</button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  },
);

export default ArrayFieldTemplate;
