import DefaultLayout from 'components/Layout/DefaultLayout';
import SkeletonLoader from 'components/SkeletonLoader';
import { SkeletonTheme } from 'react-loading-skeleton';

import styles from './index.module.scss';

interface LoadingProps {
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ className }) => {
  return (
    <DefaultLayout isLoading={true}>
      <SkeletonTheme
        baseColor='#f2f2f2'
        highlightColor='#ccc'
        enableAnimation={false}
      >
        <SkeletonLoader
          variant='rect'
          width={100}
          height={40}
          style={{ marginTop: '4rem' }}
          className={styles.customHomeLoadingSkeleton}
        />

        <SkeletonLoader
          variant='rect'
          width={200}
          height={30}
          style={{ marginTop: '4rem' }}
          className={styles.customHomeLoadingSkeleton}
        />

        <SkeletonLoader
          count={3}
          variant='rect'
          width={400}
          height={30}
          style={{ marginTop: '2rem' }}
          className={styles.customHomeLoadingSkeleton}
        />
      </SkeletonTheme>
    </DefaultLayout>
  );
};

export default Loading;
