import parse from 'html-react-parser';
import React from 'react';
import {
  AvailableAgentTools,
  ExaAIExtractedData,
  ExaAISource,
  ExpertsExtractedData,
  ExpertsSource,
  KNMaterialsExtractedData,
  KNMaterialsSource,
  SourceData,
  VoyagerExtractedData,
  VoyagerSource,
} from 'redux/AgentsChat/typings';
import { DataSourceType } from 'redux/Workspace/typings';

const KN_MATERIALS_REGEX = /\[(\d+)-KN Materials\]/g;
const EXPERTS_REGEX = /\[(\d+)-Experts-([^:]+): ([^\]]+)\]/g;
const EXA_REGEX = /\[(\d+)-Exa AI\]/g;
const AGENTS_REGEX = /\[(\d+)-Voyager\]/g;

export const convertHTMLToPlaintextWithLinks = (html: string) => {
  const anchorTagRegEx = /<a href="(.*?)">(.*?)<\/a>/g;

  const textMessageWithoutAnchorTags = html.replace(
    anchorTagRegEx,
    ' [$2] ($1)',
  );

  let plainText = textMessageWithoutAnchorTags.replace(/<[^>]*>/g, '');
  plainText = plainText.replace(/" target="_blank/g, '');

  return plainText;
};

/**
 * This function takes in a message and replaces the citations with
 * HTML tags that contain the citation data. For example [1-KN Materials]
 * will be replaced with <sup filename="filename" page="page" citationnumber="1">1</sup>
 */
export const replaceMessageCitationsWithHtmlTags = (
  message: string,
  selectedChatAgent: AvailableAgentTools,
  sourceData: SourceData,
): string => {
  switch (selectedChatAgent) {
    case AvailableAgentTools.KNMaterials: {
      const knMaterialsSourceData = sourceData as KNMaterialsSource;
      // Use regex to match citation keys like [1-KN Materials] or [23-KN Materials]
      // Replace each citation key with a <sup> tag with data from sourceData
      return message.replace(KN_MATERIALS_REGEX, (match, p1) => {
        const citationKey = `${p1}-KN Materials`;
        const citationData = knMaterialsSourceData[citationKey];

        if (citationData) {
          const { filename, page, kp_cms_id } = citationData;
          const url = `https://pages.navigator.bcg.com/kp/${kp_cms_id}?opendocviz=true&slideno=${page}`;
          // matches the KNMaterialsExtractedData interface
          return `<sup link="${url}" filename="${filename}" page="${page}" kp_cms_id="${kp_cms_id}" citationnumber="${p1}"><a href="${url}" target="_blank" rel="noopener noreferrer">${p1}</a></sup>`;
        }

        // If no citation data is found, return the match unchanged
        return match;
      });
    }

    case AvailableAgentTools.Experts: {
      const expertsSourceData = sourceData as ExpertsSource;
      return message.replace(EXPERTS_REGEX, (match, id, type, content) => {
        const expert = expertsSourceData[`${id}-Experts`];
        if (!expert) return match;

        const typeFormatted =
          type.trim().charAt(0).toUpperCase() +
          type.trim().slice(1).toLowerCase();
        const url = generateExpertsUrl(
          typeFormatted,
          expert.hrid,
          expert.inferredQuery,
        );
        const formattedContent = `<u>${content}</u>`;
        // matches the ExpertsExtractedData interface
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${formattedContent}</a>`;
      });
    }

    case AvailableAgentTools.Voyager: {
      const voyagerSourceData = sourceData as VoyagerSource;
      return message.replace(AGENTS_REGEX, (match, p1) => {
        const citationKey = `${p1}-Voyager`;
        const citationData = voyagerSourceData[citationKey];
        if (citationData) {
          const { datasource_type } = citationData;
          if (datasource_type === DataSourceType.sharepoint_sql) {
            const { sharepoint_url, page, FileLeafRef, doc_id } = citationData;

            return `<sup filename="${FileLeafRef}" page="${page}" doc_id="${doc_id}" citationnumber="${p1}" datasource_type=${datasource_type}><a href="${sharepoint_url}?web=1&page=${page}&doc_id=${doc_id}&datasource_type=${datasource_type}&file_name=${FileLeafRef}" target="_blank" rel="noopener noreferrer">${p1}</a></sup>`;
          }
          if (datasource_type === DataSourceType.sql) {
            let { SYS_ID, sys_id, page, generated_title, doc_id } =
              citationData;
            if (SYS_ID !== undefined) {
              sys_id = SYS_ID;
            }
            return `<sup filename="${generated_title}" page="${page}" doc_id="${doc_id}" citationnumber="${p1}" datasource_type=${datasource_type}><a href="https://bcg.service-now.com/nav_to.do?uri=%2Fu_request_gko.do%3Fsys_id%3D${sys_id}" target="_blank" rel="noopener noreferrer">${p1}</a></sup>`;
          }
          if (datasource_type === DataSourceType.sharepoint) {
            const { sharepoint_url, page, FileLeafRef, doc_id } = citationData;

            return `<sup filename="${FileLeafRef}" page="${page}" doc_id="${doc_id}" citationnumber="${p1}" datasource_type=${datasource_type}><a href="${sharepoint_url}?web=1page=${page}&doc_id=${doc_id}&datasource_type=${datasource_type}&file_name=${FileLeafRef}" target="_blank" rel="noopener noreferrer">${p1}</a></sup>`;
          }
        }
        // If no citation data is found, return the match unchanged
        return match;
      });
    }

    case AvailableAgentTools.ExaAI: {
      const exaAISourceData = sourceData as ExaAISource;
      return message.replace(EXA_REGEX, (match, p1) => {
        const citationKey = `${p1}-Exa AI`;
        const citationData = exaAISourceData[citationKey];

        if (citationData) {
          const { url, title } = citationData;
          return `<a href="${url}" target="_blank" rel="noopener noreferrer">[<b>${title}</b>]</a> `;
        }

        // If no citation data is found, return the match unchanged
        return match;
      });
    }

    default:
      break;
  }

  // If no case is handled, return the original message
  return message;
};

export const extractSourceDataFromMessageHtmlTags = (
  message: string,
  selectedChatAgentTool: AvailableAgentTools,
):
  | KNMaterialsExtractedData[]
  | ExpertsExtractedData[]
  | VoyagerExtractedData[]
  | ExaAIExtractedData[] => {
  const parsedElements = parse(message);

  if (!Array.isArray(parsedElements)) return [];

  const elementsThatAreReactComponentSymbols = parsedElements.filter(
    (element) => React.isValidElement(element),
  );

  switch (selectedChatAgentTool) {
    case AvailableAgentTools.KNMaterials: {
      const matches: KNMaterialsExtractedData[] = [];
      elementsThatAreReactComponentSymbols.forEach((element) => {
        const { filename, page, citationnumber, link, kp_cms_id } =
          element.props as KNMaterialsExtractedData;
        matches.push({
          citationnumber,
          filename,
          page,
          link,
          kp_cms_id,
        });
      });
      return removeDuplicates(matches, (match) => match.citationnumber);
    }

    case AvailableAgentTools.Experts: {
      const matches: ExpertsExtractedData[] = [];
      elementsThatAreReactComponentSymbols.forEach((element) => {
        const { href } = element.props as ExpertsExtractedData;
        matches.push({
          href,
        });
      });
      return removeDuplicates(matches, (match) => match.href);
    }

    case AvailableAgentTools.Voyager: {
      const matches: VoyagerExtractedData[] = [];
      elementsThatAreReactComponentSymbols.forEach((element) => {
        const { citationnumber, filename, page, datasource_type, doc_id } =
          element.props as VoyagerExtractedData;
        matches.push({
          citationnumber,
          filename,
          page,
          datasource_type,
          doc_id,
        });
      });
      return removeDuplicates(matches, (match) => match.citationnumber);
    }

    case AvailableAgentTools.ExaAI: {
      const matches: ExaAIExtractedData[] = [];
      elementsThatAreReactComponentSymbols.forEach((element) => {
        const { href, title } = element.props as ExaAIExtractedData;
        matches.push({
          href,
          title,
        });
      });
      return removeDuplicates(matches, (match) => match.href);
    }

    default:
      return [];
  }
};

export const incomingMessageTextContainsCitations = (
  selectedAgentTool: AvailableAgentTools,
  incomingMessageText: string,
): boolean => {
  if (selectedAgentTool === AvailableAgentTools.KNMaterials) {
    // i.e. [1-KN Materials], [12-KN Materials] ...
    return KN_MATERIALS_REGEX.test(incomingMessageText);
  }

  if (selectedAgentTool === AvailableAgentTools.ExaAI) {
    // i.e. [1-ExaAI], [12-ExaAI] ...
    return EXA_REGEX.test(incomingMessageText);
  }

  if (selectedAgentTool === AvailableAgentTools.Experts) {
    // i.e. [1-Experts], [12-Experts] ...
    return EXPERTS_REGEX.test(incomingMessageText);
  }

  if (selectedAgentTool === AvailableAgentTools.Voyager) {
    // i.e. [1-Voyager], [12-Voyager] ...
    return AGENTS_REGEX.test(incomingMessageText);
  }

  return false;
};

const generateExpertsUrl = (
  type: string,
  hrid: number,
  inferredQuery: string,
) => {
  const baseUrl = 'https://profiles.navigator.bcg.com/';
  switch (type) {
    case 'Cases':
      return `${baseUrl}cases?hrid=${hrid}&query=${encodeURIComponent(inferredQuery)}&viewState=list&enableAutoCorrect=True&resultsPerPage=20&resultsFromPage=1&sortingOrder=dateOpened.desc`;
    case 'Materials':
      return `${baseUrl}materials?hrid=${hrid}&query=${encodeURIComponent(inferredQuery)}&viewState=list&enableAutoCorrect=True&resultsPerPage=20&resultsFromPage=1&sortingOrder=dateRevised.desc`;
    default:
      return `${baseUrl}overview?hrid=${hrid}`;
  }
};

// Helper function to remove duplicates from an array of objects
const removeDuplicates = <T,>(matches: T[], getKey: (match: T) => string) => {
  const seen = new Set();
  return matches.filter((match) => {
    const key = getKey(match);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};
