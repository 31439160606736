import {
  AdminWorkspaceConfiguration,
  DocData,
  KnowledgeSearchResponseData,
  OnClickAction,
  WorkspaceConfiguration,
} from 'components/WorkspaceConfigurations/typings';
import { RetrievalMaterial } from 'redux/Retrieval/typings';

export interface WorkspaceState {
  availableWorkspaceConfigurations: WorkspaceConfiguration[];
  availableWorkspaceConfigurationsStatus:
    | 'idle'
    | 'loading'
    | 'succeeded'
    | 'failed';
  availableWorkspaceConfigurationsError: Error | null;

  createdWorkspaceData: CreatedWorkspaceData | null;
  createWorkspaceStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  createWorkspaceError: CreateWorkspaceErrorData | null;

  adminAvailableWorkspaceConfigurations: AdminWorkspaceConfiguration[];
  adminWorkspaceStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  adminWorkspaceError: Error | null;

  updateWorkspaceConfigurationStatus:
    | 'idle'
    | 'loading'
    | 'succeeded'
    | 'failed';
  updateWorkspaceConfigurationError: Error | null;

  currentSelectedWorkspace: WorkspaceConfiguration | null;
  hasUserAccessToAnyWorkspace: boolean;
  shouldForceRefreshAvailableWorkspaceConfigurations: boolean;

  updateWorkspaceStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateWorkspaceError: Error | null;

  layoutOnClickActionStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  layoutOnClickActionError: Error | null;

  layoutOnClickActionStateHistory: {
    onClickAction: OnClickAction;
    data: RetrievalMaterial;
    retreivalDataBeforeFetch: RetrievalMaterial | null;
  }[];

  layoutOnClickActionStateHistoryIndex: number;

  prefetchSuccessData: RetrievalMaterial[];
  preFetchError: Error | null;
  prefetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed';

  knowledgeSearchValidationSuccessData: DocData[];
  knowledgeSearchValidationStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  knowledgeSearchValidationError: Error | null;
}

export type CreatedWorkspaceData = {
  requestId: string;
  workspace_id: number;
  customer_id: number;
};

export interface WorkspaceResponse {
  customer_id: number;
  customer_abbr: string;
  workspace: WorkspaceConfiguration;
  updated_at: string;
  status: string;
  charge_code: string;
  created_at: string;
  workspace_id: number;
  access_type: string;
}

export interface sharePointForm {
  sharePointUrl: string;
  dbUsername: string;
  dbTableName: string;
  dbConnection: string;
  dbPassword: string;
  spExternalMetadata: boolean;
  enableVision: boolean;
}

export interface CreateWorkspaceObj {
  dataSources: DataSource[];
  workspaceName: string;
  chargeCode: string;
}

export interface CreatedWorkspaceDataSource {
  consumer_name: string;
  workspace_id: number;
  datasource_id: number;
  config_json: {
    source_url: string;
    external_metadata_required: false;
    metadata_source: string;
  };
  index_name: string;
}
export interface CreatedWorkspaceObj {
  customer_id: number;
  consumer_id: string;
  workspace_id: number;
  requestId: string;
  data_sources: CreatedWorkspaceDataSource[];
}

export interface CreateWorkspaceObjRequestBody {
  request_id: string;
  consumer_id: string;
  workspace_name: string;
  generate_semantic: boolean;
  charge_code: string;
  data_sources: [
    {
      source: string;
      config_json: {
        source_url: string;
        external_metadata_required: boolean;
        // metadata_source: {
        //   username?: string;
        //   table_name?: string;
        //   connection_string?: string;
        //   password?: string;
        // };
        metadata_source: string;
      };
    },
  ];
}

export interface WorkspaceApiErrorData extends Error {
  error: errorObj;
  statusCode: number;
}

export interface CreateWorkspaceErrorData extends Error {
  statusCode: number;
}

export type errorObj = {
  detail: string;
};

export enum DataSourceType {
  snowflake = 'snowflake',
  sql = 'sql',
  sharepoint = 'sharepoint',
  sharepoint_sql = 'sharepoint_sql',
  sharepoint_service = 'sharepoint_service',
  upload = 'upload',
}

export interface AdminWorkspaceResponse {
  customer_id: number;
  customer_name: string;
  customer_charge_code: string;
  workspace_status: [number, string];
  workspace_date_created: string;
  customer_default_datasource: string;
  total_users: number;
  workspace_id: number;
  data_sources: [DataSourceType];
}

export interface DataSource {
  id: number;
  sourceData: SourceData | {};
  showDataSources: boolean;
}

export interface SharePointForm {
  sharePointUrl: string;
  dbUsername: string;
  dbTableName: string;
  dbConnection: string;
  dbPassword: string;
  externalMetadata: boolean;
  enableVision: boolean;
}

export interface SourceData {
  sharePointForm: SharePointForm;
  addedSourceName: string;
  data?: any;
}

export interface SQLDataSource {
  dbConnectionString: string;
  dbUsername: string;
  dbPassword: string;
  dbTableName: string;
  enableVision: boolean;
}

export interface WorkspaceOption {
  option_name: string;
  option_value: boolean;
  datasource_id: number;
}

interface Preview {
  html: {
    hqUrl: string;
    lqUrl: string;
  };
  large: {
    hqUrl: string;
    lqUrl: string;
  };
  medium: {
    hqUrl: string;
    lqUrl: string;
  };
  pageNumber: number;
}

interface Attachment {
  guid: string;
  restrictedFlag: boolean;
  previewAvailable: boolean;
  attachmentSlideCount: number;
  relevantSlides: unknown[];
  url: string;
  s3Object: string;
  previews: Preview[];
}

export interface SlideLevelSearchResult {
  resultCount: number;
  resultsPerPage: number;
  hitPhrases: string[];
  attachments: Attachment[];
}

export interface slideLevelSearchRequestBody {
  query: string;
  guid: [string];
  resultsPerPage: Number;
  matchMode: string;
}
