import OktaAuth, {
  AccessToken,
  TokenManagerInterface,
} from '@okta/okta-auth-js';

const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.REACT_APP_OKTA_TESTING_DISABLE_HTTPS_CHECK === 'true';
const REDIRECT_URI = `${window.location.origin}/login/callback`;

const oktaConfigurationSettings = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['profile', 'email', 'groups', 'openid'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};

const oktaAuth = new OktaAuth(oktaConfigurationSettings.oidc);

export default oktaAuth;

export const getUserHrid = async () => {
  const tokenManager: TokenManagerInterface = oktaAuth.tokenManager;
  const accessToken: AccessToken = (await tokenManager.get(
    'accessToken',
  )) as AccessToken;
  const userClaims = accessToken.claims;
  const userHrid = userClaims.hrid;

  return userHrid;
};

export const getUserClaims = async () => {
  const tokenManager: TokenManagerInterface = oktaAuth.tokenManager;
  const accessToken: AccessToken = (await tokenManager.get(
    'accessToken',
  )) as AccessToken;
  const userClaims = accessToken.claims;

  return userClaims;
};
