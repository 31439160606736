import DefaultLayout from 'components/Layout/DefaultLayout';
import WorkspaceListPageContent from 'components/WorkspaceListPageContent';
import React from 'react';

const WorkspaceListPage: React.FC = () => {
  return (
    <DefaultLayout hideWorkspaceDropdown>
      <WorkspaceListPageContent />
    </DefaultLayout>
  );
};

export default WorkspaceListPage;
