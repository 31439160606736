import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { FilterState, SearchParamFilters } from './typings';

const initialState: FilterState = {
  filterQuery: '',
  filterQueryHistory: [],
  filterQueryHistoryIndex: -1,

  filters: {},
  workspaceFilters: [],
  filtersHistory: [],
  filtersHistoryIndex: -1,
  lastAppliedFilters: {},

  status: 'idle',
  error: null,

  lastFilterQuery: '',
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    // query
    setFilterQuery: (state, action: PayloadAction<string>) => {
      state.lastFilterQuery = state.filterQuery;
      state.filterQuery = action.payload;
      state.filterQueryHistory.push(action.payload);
      state.filterQueryHistoryIndex = state.filterQueryHistory.length - 1;
    },
    resetFilterQuery: (state) => {
      state.filterQuery = '';
      state.filterQueryHistory = [];
      state.filterQueryHistoryIndex = -1;
    },

    // filter data
    setFilterData: (state, action: PayloadAction<SearchParamFilters>) => {
      // if list type, append filter values to existing filter values
      // if not list type, replace existing filter values with new filter values
      const newFilterData = action.payload;
      const existingFilterData = state.filters;

      for (const groupName in newFilterData) {
        const newFilterGroup = newFilterData[groupName];
        const existingFilterGroup = existingFilterData[groupName];

        if (existingFilterGroup) {
          existingFilterGroup.forEach((existingFilter, index) => {
            // list type
            if (existingFilter.type === 'list') {
              const existingFilterValues = existingFilter.filter_values;
              const newFilterValues = newFilterGroup[index].filter_values;

              const mergedFilterValues = [
                ...existingFilterValues,
                ...newFilterValues,
              ];

              existingFilter.filter_values = mergedFilterValues;
            }
            // not list type
            else {
              existingFilter.filter_values =
                newFilterGroup[index].filter_values;
            }
          });
        } else {
          existingFilterData[groupName] = newFilterGroup;
        }
      }

      state.filters = existingFilterData;
      state.filtersHistory.push(action.payload);
      state.filtersHistoryIndex = state.filtersHistory.length - 1;
    },
    setWorksapceFilterData: (state, action) => {
      state.workspaceFilters = action.payload;
    },
    setLastAppliedFilterData: (
      state,
      action: PayloadAction<SearchParamFilters>,
    ) => {
      state.lastAppliedFilters = action.payload;
    },
    resetFilterData: (state) => {
      state.filters = {};
      state.filtersHistory = [];
      state.filtersHistoryIndex = -1;
    },
    resetlastAppliedFilterData: (state) => {
      state.lastAppliedFilters = {};
    },
    removeFilterDataByGroupName: (state, action: PayloadAction<string>) => {
      delete state.filters[action.payload];

      state.filtersHistory.push(state.filters);
      state.filtersHistoryIndex = state.filtersHistory.length - 1;
    },
    removeFilterDataByGroupNameAndValueName: (
      state,
      action: PayloadAction<{ groupName: string; valueName: string | boolean }>,
    ) => {
      const { groupName, valueName } = action.payload;
      const filterData = state.filters[groupName];

      const newFilterData = filterData.map((filter) => {
        filter.filter_values = filter.filter_values.filter(
          (value) => value !== valueName,
        );

        return filter;
      });

      state.filters[groupName] = newFilterData;

      // if filter values is empty, remove the filter group
      for (const filter of newFilterData) {
        if (filter.filter_values.length === 0) {
          delete state.filters[groupName];
          break;
        }
      }

      state.filtersHistory.push(state.filters);
      state.filtersHistoryIndex = state.filtersHistory.length - 1;
    },
  },
});

export const {
  setFilterQuery,
  setLastAppliedFilterData,
  resetFilterQuery,
  setFilterData,
  setWorksapceFilterData,
  resetFilterData,
  resetlastAppliedFilterData,
  removeFilterDataByGroupName,
  removeFilterDataByGroupNameAndValueName,
} = filterSlice.actions;

// selectors
export const selectFilterQuery = (state: RootState) =>
  state.filters.filterQuery;

export const selectFilterQueryHistory = (state: RootState) =>
  state.filters.filterQueryHistory;

export const selectFilterQueryHistoryIndex = (state: RootState) =>
  state.filters.filterQueryHistoryIndex;

export const selectFilterDataByFilterGroupName = (
  state: RootState,
  filterGroupName: string,
) => state.filters.filters[filterGroupName];

export const selectFilterData = (state: RootState) => state.filters.filters;

export const selectWorkspaceFilterData = (state: RootState) =>
  state.filters.workspaceFilters;

export const selectLastAppliedFilterData = (state: RootState) =>
  state.filters.lastAppliedFilters;

export const selectLastFilterQuery = (state: RootState) =>
  state.filters.lastFilterQuery;

export default filterSlice.reducer;
