import { parseDateTime } from '@internationalized/date';
import { RetrievalFilter } from 'redux/Retrieval/typings';

import { SearchParamFilters } from '../../redux/Filters/typings';

export const encodeSearchParamFilters = (
  searchParamFilters: SearchParamFilters,
): string => {
  const filterKeys = Object.keys(searchParamFilters);

  /*
      This allows us to encode multiple filters for the same key.
      For example:
      "material_type": [
          {
              "name": "material_type",
              "type": "list",
              "filter_values": [
                  "Research Materials",
                  "Agile Methodology"
              ]
          },
          {
              "name": "filename",
              "type": "regex",
              "filter_values": [
                  ".pptx"
              ]
          }
      ]
 
      These should be encoded in such a way that we can decode them
      and know that they are both for the "material_type" key. This
      is done by using the $ symbol to separate the filters for the
      same key. So the above example would be encoded as:
      "material_type:list:Research Materials,Agile Methodology$filename:regex:.pptx"
  */
  const encodedFilters = filterKeys.map((key) => {
    const currentFilterGroup = searchParamFilters[key];
    const encodedFilters = currentFilterGroup.map((filter) => {
      const encodedKey = encodeURIComponent(key);
      const encodedName = encodeURIComponent(filter.name);
      const encodedType = encodeURIComponent(filter.type);
      const encodedFilterValues = filter.filter_values
        .map((value) => encodeURIComponent(value))
        .join(',');

      let encodedFilterString = `${encodedKey}:${encodedName}:${encodedType}:${encodedFilterValues}`;

      if (filter?.predicate) {
        encodedFilterString = `${encodedFilterString}:${filter.predicate}`;
      }

      return encodedFilterString;
    });

    const joinedFilterGroupString = encodedFilters.join('$');

    return joinedFilterGroupString;
  });

  /*
      This joins all of the encoded filters together with the | symbol.
      The | in the string indicates that the filters are for different
      keys. For example:
      "material_type:list:Research Materials,Agile Methodology$filename:regex:.pptx|enddate:date:2013-03-01:<="
      
      We know that the first filter is for the "material_type" key and
      the second filter is for the "enddate" key by looking at the
      string before the pipe.       
  */
  let joinedFilters = encodedFilters.join('|');

  // remove pipe from first and last character of string if it exists
  // this is to prevent a url that looks like this:
  // https://foo.bar/?q=*&filters=|enddate:date:2013-03-01:<=
  joinedFilters = joinedFilters.replace(/^\|/, '');
  joinedFilters = joinedFilters.replace(/\|$/, '');

  return joinedFilters;
};

export const decodeSearchParamFilters = (
  encodedString: string,
): SearchParamFilters => {
  if (!encodedString) {
    return {};
  }

  const encodedFilters = encodedString.split('|');
  const decodedFilters: SearchParamFilters = {};

  encodedFilters.forEach((encodedFilterGroup) => {
    const filterGroupArray = encodedFilterGroup.split('$');

    filterGroupArray.forEach((encodedFilterString) => {
      const [
        encodedKey,
        encodedName,
        encodedType,
        encodedValues,
        encodedPredicate,
      ] = encodedFilterString?.split(':');

      const filterValues: any[] = encodedValues
        ?.split(',')
        .map((value) => decodeURIComponent(value));

      // if encodedType is boolean, convert the
      // string values to boolean values i.e
      // 'true' -> true, 'false' -> false
      if (encodedType === 'boolean') {
        filterValues.forEach((value, index) => {
          if (value === 'true') {
            filterValues[index] = true;
          } else if (value === 'false') {
            filterValues[index] = false;
          }
        });
      }

      const key = decodeURIComponent(encodedKey);
      const name = decodeURIComponent(encodedName);
      const type = decodeURIComponent(encodedType) as RetrievalFilter['type'];

      if (!decodedFilters[key]) {
        decodedFilters[key] = [];
      }

      const filter: RetrievalFilter = {
        name,
        type,
        filter_values: filterValues,
      };

      if (encodedPredicate) {
        filter.predicate = decodeURIComponent(
          encodedPredicate,
        ) as RetrievalFilter['predicate'];
      }

      decodedFilters[key].push(filter);
    });
  });

  return decodedFilters;
};

export const setFilterQueryToUrlParam = (q: string) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.set('q', q);
  window.history.replaceState({}, '', `${url.pathname}?${params}`);
};

export const getFilterQueryFromUrlParam = (): string => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const q = params.get('q');

  return q || '';
};

export const setFilterDataToUrlParam = (filters: SearchParamFilters) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.set('filters', encodeSearchParamFilters(filters));
  window.history.replaceState({}, '', `${url.pathname}?${params}`);
};

export const getFilterDataFromUrlParam = (): SearchParamFilters => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const filters = params.get('filters');

  if (!filters) {
    return {};
  }

  return decodeSearchParamFilters(filters);
};

export const clearFilterDataFromUrlParam = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.delete('filters');
  window.history.replaceState({}, '', `${url.pathname}?${params}`);
};

export const clearFilterQueryFromUrlParam = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.delete('q');
  window.history.replaceState({}, '', `${url.pathname}?${params}`);
};

/**
 * @description Convert date string to UTC date.
 * @param dateString - Date string to convert to UTC. In the format of YYYY-MM-DD (2024-05-22)
 * @returns Date - Date object in UTC timezone.
 */
export const convertDateStringToUTC = (dateString: string) =>
  parseDateTime(dateString).toDate('UTC');
