import FilePath from 'components/FilePath';
import {
  DynamicWrapperProps,
  formatDate,
  getRenderProps,
} from 'utils/workspace';

const FilePathDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  const { paramFormat, paramPostfix } =
    requiredRenderParams.find(
      (param) =>
        param.paramName === 'field' &&
        (param.paramFormat || param.paramPostfix),
    ) || {};

  if (paramFormat && props.field) {
    props.field = formatDate(props.field, paramFormat);
  }

  if (paramPostfix) {
    props.field = `${props.field} ${paramPostfix}`;
  }

  if (!props.field) {
    console.warn(`FilePathDynamicWrapper: path data is missing.`);
    return null;
  }

  return <FilePath field={props.field} icon={props.icon} />;
};

export default FilePathDynamicWrapper;
