import parse from 'html-react-parser';
import React from 'react';

export interface HtmlContentProps {
  content: string;
}

const HtmlContent: React.FC<HtmlContentProps> = ({ content }) => {
  const parsedHtml = parse(content);
  return <div style={{ fontSize: '14px' }}>{parsedHtml}</div>;
};

export default HtmlContent;
