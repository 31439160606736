import {
  GenerativeCompletionData,
  GenerativeMessageResponseData,
  SystemMessageData,
  UserMessageData,
} from 'redux/Generative/typings';

export const getAssistantTextFromCompletionData = (
  data: GenerativeCompletionData,
): string => data.choices.map((choice) => choice.delta?.content || '').join('');

export const isGenerativeCompletionData = (
  data: any,
): data is GenerativeCompletionData => {
  return (data as GenerativeCompletionData).choices !== undefined;
};

export const isGenerativeCompletion = (
  message: GenerativeMessageResponseData,
) => (message?.data as GenerativeCompletionData)?.choices !== undefined;

export const isSystemMessage = (message: GenerativeMessageResponseData) =>
  (message?.data as SystemMessageData)?.system_message !== undefined;

export const processSystemMessage = (
  message: GenerativeMessageResponseData,
  requestId: string,
) => {
  const data = message.data as SystemMessageData;
  console.log(`System message received for request ID: ${requestId}: `, data);
  switch (data.system_message) {
    case 'START GENERATIVE':
      console.log('START GENERATIVE');
      break;
    case 'usage':
      console.log(
        `Request usage info for request ID ${requestId}: ${JSON.stringify(
          data.usage || {},
        )}`,
      );
  }
};

export const isFunctionCall = (message: GenerativeMessageResponseData) =>
  (message?.data as any)?.delta?.function_call !== undefined;

// user message is simply the action the service
// is currently taking, NOT the end users message
// i.e "Searching KN Materials for xyz"
export const isUserMessage = (message: GenerativeMessageResponseData) =>
  (message?.data as UserMessageData)?.user_message !== undefined;
