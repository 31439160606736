import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { DataDog } from 'DataDog';
import LoadingPage from 'pages/LoadingPage';
import { useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS, LocalStorageUtil } from 'utils/localStorage';

const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const setDatadogUser = useCallback(async () => {
    const user = await oktaAuth.getUser();
    if (!user) {
      return;
    }

    DataDog.setUser({
      id: user.sub,
      name: user.name,
      email: user.email,
    });
  }, [oktaAuth]);

  const clearSelectedWorkspaceFromLocalStorage = () => {
    LocalStorageUtil.removeItem(LOCAL_STORAGE_KEYS.SELECTED_WORKSPACE);
  };

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      clearSelectedWorkspaceFromLocalStorage();
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }

    if (authState?.isAuthenticated) {
      setDatadogUser();
    }
  }, [oktaAuth, authState, authState?.isAuthenticated, setDatadogUser]);

  if (!authState || !authState?.isAuthenticated) {
    return <LoadingPage />;
  }

  return <Outlet />;
};

export default RequiredAuth;
