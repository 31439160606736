import { createSlice } from '@reduxjs/toolkit';
import { WorkspaceConfiguration } from 'components/WorkspaceConfigurations/typings';
import { RootState } from 'redux/store';
import { LOCAL_STORAGE_KEYS, LocalStorageUtil } from 'utils/localStorage';

import {
  conductKnowledgeSearch,
  createWorkspace,
  fetchAdminWorkspaceConfigurations,
  fetchAndSetUsersWorkspaceConfigurations,
  layoutOnClickAction,
  prefetchVoyagerSourceData,
  updateWorkspaceConfiguration,
  updateWorkspaceStatus,
} from './api';
import { CreateWorkspaceErrorData, WorkspaceState } from './typings';

const initialState: WorkspaceState = {
  availableWorkspaceConfigurations: [],
  availableWorkspaceConfigurationsStatus: 'idle',
  availableWorkspaceConfigurationsError: null,

  createdWorkspaceData: null,
  createWorkspaceStatus: 'idle',
  createWorkspaceError: null,

  adminAvailableWorkspaceConfigurations: [],
  adminWorkspaceStatus: 'idle',
  adminWorkspaceError: null,

  updateWorkspaceConfigurationStatus: 'idle',
  updateWorkspaceConfigurationError: null,

  hasUserAccessToAnyWorkspace: true,
  currentSelectedWorkspace: null,
  shouldForceRefreshAvailableWorkspaceConfigurations: false,

  updateWorkspaceStatus: 'idle',
  updateWorkspaceError: null,

  layoutOnClickActionStatus: 'idle',
  layoutOnClickActionError: null,

  layoutOnClickActionStateHistory: [],
  layoutOnClickActionStateHistoryIndex: -1,

  prefetchSuccessData: [],
  preFetchError: null,
  prefetchStatus: 'idle',

  knowledgeSearchValidationSuccessData: [],
  knowledgeSearchValidationStatus: 'idle',
  knowledgeSearchValidationError: null,
};

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setCurrentSelectedWorkspace: (state, action) => {
      state.currentSelectedWorkspace = action.payload;
    },
    clearCreateWorkspaceState: (state) => {
      state.createWorkspaceStatus = 'idle';
      state.createWorkspaceError = null;
      state.createdWorkspaceData = null;
    },
    clearAvailableWorkspaceConfigurations: (state) => {
      state.availableWorkspaceConfigurations = [];
    },
    setShouldForceRefreshAvailableWorkspaceConfigurations: (state, action) => {
      state.shouldForceRefreshAvailableWorkspaceConfigurations = action.payload;
    },
    clearlayoutOnClickAction: (state) => {
      state.layoutOnClickActionStateHistory = [];
      state.layoutOnClickActionStateHistoryIndex = -1;
    },
    clearKnowledgeSearchData: (state) => {
      state.knowledgeSearchValidationSuccessData = [];
      state.knowledgeSearchValidationStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAndSetUsersWorkspaceConfigurations.pending,
      (state) => {
        state.availableWorkspaceConfigurationsStatus = 'loading';
      },
    );
    builder.addCase(
      fetchAndSetUsersWorkspaceConfigurations.fulfilled,
      (state, action) => {
        state.availableWorkspaceConfigurationsStatus = 'succeeded';
        state.availableWorkspaceConfigurations = action.payload;
        const availableWorkspaceConfigurationsWithCreatedStatus =
          action.payload.filter(
            (wrkspc) =>
              wrkspc.status === 'Created' && wrkspc.accessType === 'VOYAGER_FE',
          );

        // set selected workspace on successful fetch
        const selectedWorkspaceFromLocalStorage: WorkspaceConfiguration | null =
          LocalStorageUtil.getItem(LOCAL_STORAGE_KEYS.SELECTED_WORKSPACE);

        // if there is no available workspace then set hasUserAccessToAnyWorkspace to false
        if (availableWorkspaceConfigurationsWithCreatedStatus.length === 0) {
          state.hasUserAccessToAnyWorkspace = false;
          state.availableWorkspaceConfigurationsStatus = 'failed';
        }
        // if there is a selected workspace in local storage, set it as the default
        if (selectedWorkspaceFromLocalStorage?.workspaceName) {
          const selected =
            availableWorkspaceConfigurationsWithCreatedStatus.find(
              (payload) =>
                payload.workspaceName ===
                selectedWorkspaceFromLocalStorage.workspaceName,
            );

          if (selected) {
            state.currentSelectedWorkspace = selected;
          }
        }

        // if there is no selected workspace in local storage and there are workspaceConfigurations with created status, set the first one as the default
        if (
          !state.currentSelectedWorkspace &&
          availableWorkspaceConfigurationsWithCreatedStatus.length > 0
        ) {
          state.currentSelectedWorkspace =
            availableWorkspaceConfigurationsWithCreatedStatus[0];
        }
      },
    );
    builder.addCase(
      fetchAndSetUsersWorkspaceConfigurations.rejected,
      (state, action) => {
        const data = action.payload;
        if (data?.statusCode === 204) {
          state.hasUserAccessToAnyWorkspace = false;
        }
        state.availableWorkspaceConfigurationsStatus = 'failed';
        state.availableWorkspaceConfigurationsError = action.payload as Error;
      },
    );
    builder.addCase(createWorkspace.pending, (state) => {
      state.createWorkspaceStatus = 'loading';
    });
    builder.addCase(createWorkspace.fulfilled, (state, action) => {
      state.createWorkspaceStatus = 'succeeded';
      state.createdWorkspaceData = action.payload;
    });
    builder.addCase(createWorkspace.rejected, (state, action) => {
      state.createWorkspaceStatus = 'failed';
      state.createWorkspaceError = action.payload as CreateWorkspaceErrorData;
    });
    builder.addCase(fetchAdminWorkspaceConfigurations.pending, (state) => {
      state.adminWorkspaceStatus = 'loading';
    });
    builder.addCase(
      fetchAdminWorkspaceConfigurations.fulfilled,
      (state, action) => {
        state.adminWorkspaceStatus = 'succeeded';
        state.adminAvailableWorkspaceConfigurations = action.payload;
      },
    );
    builder.addCase(
      fetchAdminWorkspaceConfigurations.rejected,
      (state, action) => {
        state.adminWorkspaceStatus = 'failed';
        state.availableWorkspaceConfigurationsError = action.payload as Error;
      },
    );
    builder.addCase(updateWorkspaceStatus.pending, (state) => {
      state.updateWorkspaceStatus = 'loading';
    });
    builder.addCase(updateWorkspaceStatus.fulfilled, (state) => {
      state.updateWorkspaceStatus = 'succeeded';
    });
    builder.addCase(updateWorkspaceStatus.rejected, (state, action) => {
      state.updateWorkspaceStatus = 'failed';
      state.updateWorkspaceError = action.payload as Error;
    });
    builder.addCase(layoutOnClickAction.pending, (state) => {
      state.layoutOnClickActionStatus = 'loading';
    });
    builder.addCase(layoutOnClickAction.fulfilled, (state, action) => {
      if (!action.payload?.onClickAction || !action.payload?.retreivalData) {
        console.error('No onClickAction or data specified, skipping fetch');
        state.layoutOnClickActionStatus = 'failed';
        return;
      }
      state.layoutOnClickActionStatus = 'succeeded';
      state.layoutOnClickActionStateHistory.push({
        onClickAction: action.payload?.onClickAction,
        data: action.payload?.retreivalData,
        retreivalDataBeforeFetch:
          action.payload?.retreivalDataBeforeFetch || null,
      });
      if (action.payload?.updatedLayoutOnClickActionStateHistoryIndex === -1) {
        state.layoutOnClickActionStateHistoryIndex = -1;
        state.layoutOnClickActionStateHistory = [];
      } else {
        state.layoutOnClickActionStateHistoryIndex += 1;
      }
    });
    builder.addCase(layoutOnClickAction.rejected, (state, action) => {
      state.layoutOnClickActionStatus = 'failed';
      state.layoutOnClickActionError = action.payload as Error;
    });
    builder.addCase(updateWorkspaceConfiguration.pending, (state) => {
      state.updateWorkspaceConfigurationStatus = 'loading';
    });
    builder.addCase(updateWorkspaceConfiguration.fulfilled, (state) => {
      state.updateWorkspaceConfigurationStatus = 'succeeded';
    });
    builder.addCase(updateWorkspaceConfiguration.rejected, (state, action) => {
      state.updateWorkspaceConfigurationStatus = 'failed';
      state.updateWorkspaceConfigurationError = action.payload as Error;
    });
    builder.addCase(prefetchVoyagerSourceData.pending, (state) => {
      state.prefetchStatus = 'loading';
    });
    builder.addCase(prefetchVoyagerSourceData.fulfilled, (state, action) => {
      state.prefetchStatus = 'succeeded';
      // const data = action.payload;
      // const uniqueDocIds = new Set(
      //   state.prefetchSuccessData.map((item: any) => item?.doc_id),
      // );

      // if (data?.results) {
      //   data.results.forEach((result) => {
      //     const docId = result?.doc_id;
      //     if (docId && !uniqueDocIds.has(docId)) {
      //       state.prefetchSuccessData.push(result);
      //       uniqueDocIds.add(docId);
      //     }
      //   });
      // }

      const results = action.payload?.results;
      if (!results) {
        console.error('No results found in prefetchVoyagerSourceData');
        return;
      }
      const sourceData = results[0]; // we should only be getting back one source data if we look it up by doc_id

      // check if the source data is already in the state
      const sourceDataAlreadyExists = state.prefetchSuccessData.some(
        (item) => item?.doc_id === sourceData?.doc_id,
      );

      if (sourceDataAlreadyExists) {
        console.warn('Source data already exists in state, skipping...');
        return;
      }

      state.prefetchSuccessData.push(sourceData);
    });
    builder.addCase(prefetchVoyagerSourceData.rejected, (state, action) => {
      state.prefetchStatus = 'failed';
      state.preFetchError = action.payload as unknown as Error;
    });
    builder.addCase(conductKnowledgeSearch.pending, (state) => {
      state.knowledgeSearchValidationStatus = 'loading';
    });
    builder.addCase(conductKnowledgeSearch.fulfilled, (state, action) => {
      state.knowledgeSearchValidationStatus = 'succeeded';
      state.knowledgeSearchValidationSuccessData =
        state.knowledgeSearchValidationSuccessData.length > 0
          ? [...state.knowledgeSearchValidationSuccessData, ...action.payload]
          : action.payload;
    });
    builder.addCase(conductKnowledgeSearch.rejected, (state, action) => {
      state.knowledgeSearchValidationStatus = 'failed';
      state.knowledgeSearchValidationError = action.payload as unknown as Error;
    });
  },
});

export const {
  setCurrentSelectedWorkspace,
  clearCreateWorkspaceState,
  clearAvailableWorkspaceConfigurations,
  setShouldForceRefreshAvailableWorkspaceConfigurations,
  clearlayoutOnClickAction,
  clearKnowledgeSearchData,
} = workspaceSlice.actions;

// selectors
export const selectCurrentWorkspace = (state: RootState) => {
  return state.workspace.currentSelectedWorkspace;
};

export const selectCurrentWorkspaceName = (state: RootState) =>
  state.workspace.currentSelectedWorkspace?.workspaceName;

export const selectAvailableWorkspaceConfigurationsStatus = (
  state: RootState,
) => state.workspace.availableWorkspaceConfigurationsStatus;

export const selectAvailableWorkspaceConfigurations = (state: RootState) =>
  state.workspace.availableWorkspaceConfigurations;

export const selectAvailableWorkspaceConfigurationsError = (state: RootState) =>
  state.workspace.availableWorkspaceConfigurationsError;

export const selectCreateWorkspaceStatus = (state: RootState) =>
  state.workspace.createWorkspaceStatus;

export const selectCreateWorkspaceError = (state: RootState) =>
  state.workspace.createWorkspaceError;

export const selectCreatedWorkspaceData = (state: RootState) =>
  state.workspace.createdWorkspaceData;

export const selectHasUserAccessToAnyWorkspace = (state: RootState) =>
  state.workspace.hasUserAccessToAnyWorkspace;

export const selectShouldForceRefreshAvailableWorkspaceConfigurations = (
  state: RootState,
) => state.workspace.shouldForceRefreshAvailableWorkspaceConfigurations;

export const selectAdminAvailableWorkspaceConfigurations = (state: RootState) =>
  state.workspace.adminAvailableWorkspaceConfigurations;

export const getUpdateWorkspaceStatus = (state: RootState) =>
  state.workspace.updateWorkspaceStatus;

export const selectAdminWorkspaceStatus = (state: RootState) =>
  state.workspace.adminWorkspaceStatus;

export const selectCurrentLayoutOnClickActionState = (state: RootState) => {
  const currentIndex = state.workspace.layoutOnClickActionStateHistoryIndex;
  return state.workspace?.layoutOnClickActionStateHistory?.[currentIndex];
};

export const selectLayoutOnClickActionStatus = (state: RootState) => {
  return state.workspace.layoutOnClickActionStatus;
};

export const selectCurrentLayoutOnClickActionStateIsEmpty = (
  state: RootState,
) => {
  return state.workspace.layoutOnClickActionStateHistoryIndex === -1;
};

export const selectUpdateWorkspaceConfiguration = (state: RootState) => {
  return state.workspace.updateWorkspaceConfigurationStatus;
};

export const selectPrefetchedVoyagerSourceData = (state: RootState) => {
  return state.workspace.prefetchSuccessData;
};

export const selectKnowledegeSearchData = (state: RootState) => {
  return state.workspace.knowledgeSearchValidationSuccessData;
};

export default workspaceSlice.reducer;
