import Icon, { IconSize } from 'components/Icon';
import SkeletonLoader from 'components/SkeletonLoader';
import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

import HeaderTitle from './HeaderTitle';
import styles from './index.module.scss';

const HeaderSkeletonLoader = () => (
  <div className={`${styles.skeletonHeader}`}>
    <div className='d-flex gap-3 align-items-center'>
      <SkeletonTheme baseColor='#f2f2f2' highlightColor='#ccc'>
        <SkeletonLoader variant='rect' width={40} height={30} />
        <HeaderTitle />
      </SkeletonTheme>
    </div>
    <div className='d-flex gap-3 align-items-center'>
      <SkeletonTheme baseColor='#f2f2f2' highlightColor='#ccc'>
        <SkeletonLoader variant='rect' width={150} height={50} />
        <SkeletonLoader
          variant='circle'
          width={35}
          height={35}
          style={{ borderRadius: '50%' }}
        />
      </SkeletonTheme>
    </div>
  </div>
);

export default HeaderSkeletonLoader;
