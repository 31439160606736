import DataSources from 'components/DataSources';
import Icon, { IconSize } from 'components/Icon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { FieldError } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  createWorkspace,
  fetchAndSetUsersWorkspaceConfigurations,
} from 'redux/Workspace/api';
import {
  clearCreateWorkspaceState,
  selectCreatedWorkspaceData,
  selectCreateWorkspaceError,
  selectCreateWorkspaceStatus,
} from 'redux/Workspace/slice';
import {
  DataSource,
  SharePointForm,
  SourceData,
  SQLDataSource,
} from 'redux/Workspace/typings';

import styles from './index.module.scss';

const WorkspaceCreatePageContent = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const createWorkspaceError = useAppSelector(selectCreateWorkspaceError);
  const createdWorkspaceStatus = useAppSelector(selectCreateWorkspaceStatus);
  const createdWorkspaceData = useAppSelector(selectCreatedWorkspaceData);
  // // might be used once file upload is implemented
  // const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [workspaceName, setWorkspaceName] = useState('');
  const [chargeCode, setChargeCode] = useState('');
  const [modalBody, setModalBody] = useState<JSX.Element | null>(null);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const dataSourcesScrollRef = useRef<HTMLDivElement>(null);
  const [dataSources, setDataSources] = useState<DataSource[]>([
    {
      id: Date.now(),
      sourceData: {},
      showDataSources: false,
    },
  ]);
  const {
    register,
    formState: { errors },
    trigger,
  } = useForm();
  const visibleDataSourcesRowCount = dataSources.filter(
    (record) => record.showDataSources,
  )?.length;

  useEffect(() => {
    if (createdWorkspaceStatus === 'succeeded') {
      setIsLoading(false);
      navigate('/management/list', { state: { createdWorkspaceData } });
      dispatch(fetchAndSetUsersWorkspaceConfigurations());
      dispatch(clearCreateWorkspaceState());
      // TODO handle success scenario
    }

    if (createdWorkspaceStatus === 'failed') {
      showErrorMessagePopup();
    }
  }, [createdWorkspaceStatus, createdWorkspaceData]);

  const showErrorMessagePopup = useCallback(() => {
    let modalBodyData = null;
    let modalTitleData =
      'An unexpected error has occurred. The error has been logged and the development team has been notified. Please try again later.';
    if (createWorkspaceError?.statusCode === 404) {
      modalTitleData =
        'The SharePoint URL is invalid. Please ensure the URL is correctly formatted.';
    }
    if (createWorkspaceError?.statusCode === 422) {
      modalTitleData =
        'A workspace with this name already exists. Please request access from the workspace admin.';
    }
    if (createWorkspaceError?.statusCode === 401) {
      modalTitleData =
        'In order to create this workspace, Voyager will need access to your SharePoint site. By following the steps below, you agree to allowing Voyager to have read-only access to your SharePoint site.';
      modalBodyData = (
        <>
          <h5 className={styles.popupModalBodyHeader}>
            Estimated Time: 5 minutes
          </h5>
          {/* <ol data-testid='error401PopupBody'>
            <li>
              <p>
                Navigate to your SharePoint site: &nbsp;
                <a
                  href={sharePointUrl}
                  target='_blank'
                  rel='noreferrer'
                  className='text-body'
                >
                  {sharePointUrl}
                </a>
              </p>
            </li>
            <li>In the top right, click on "Site Access".</li>
            <img
              src={siteAccess}
              style={{ width: '20rem' }}
              className='my-2'
              alt='siteAccess'
            />
            <li>
              In the text field, type "Srv_Voyager_Crawler" and click the first
              option that appears.
            </li>
            <img
              src={addSiteAccessUser}
              style={{ width: '20rem' }}
              className='mt-2 mb-3'
              alt='addSiteAccessUser'
            />
            <li>
              Ensure the "Send email" checkbox is checked, and click the "Share
              button".
            </li>
            <img
              src={siteAccessShare}
              style={{ width: '20rem' }}
              className='my-2'
              alt='siteAccessShare'
            />
            <li>
              Once steps 1-4 are complete, close this popup and click "Create
              Workspace" once again.
            </li>
          </ol> */}
        </>
      );
    }
    if (modalBodyData) {
      setModalBody(modalBodyData);
    }
    if (modalTitleData) {
      setModalTitle(modalTitleData);
    }
    setShowPopup(true);
    setIsLoading(false);
  }, [createWorkspaceError]);

  const createWorkSpace = async () => {
    setIsLoading(true);
    const createWorkspaceObj = {
      dataSources: dataSources.filter((record) => record.showDataSources),
      workspaceName,
      chargeCode,
    };
    dispatch(createWorkspace(createWorkspaceObj));
  };

  const onClosePopupModal = () => {
    dispatch(clearCreateWorkspaceState());
    setShowPopup(false);
    setModalBody(null);
    setModalTitle(null);
  };

  const validateSharePointForm = (form: SharePointForm) => {
    let isValid = true;

    if (form.sharePointUrl === '') {
      isValid = false;
    } else if (form.externalMetadata) {
      if (
        form.dbUsername === '' ||
        form.dbTableName === '' ||
        form.dbConnection === '' ||
        form.dbPassword === ''
      ) {
        isValid = false;
      }
    }
    return isValid;
  };

  const renderPopupModal = () => {
    return (
      <div>
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          data-testid='createWorkspaceErrorPopupModal'
          show={showPopup}
          onHide={() => onClosePopupModal()}
        >
          <Modal.Header>
            <Modal.Title data-testid='createWorkspaceErrorPopupModalTitle'>
              <h5>{modalTitle}</h5>
            </Modal.Title>
          </Modal.Header>
          {modalBody && (
            <Modal.Body
              className={`overflow-auto text-break ${styles.popupModalBody} `}
              data-testid='createWorkspaceErrorPopupModalBody'
            >
              {modalBody}
            </Modal.Body>
          )}
          <Modal.Footer className={modalBody ? '' : 'border-0'}>
            <Button variant='secondary' onClick={() => onClosePopupModal()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const addNewDataSource = () => {
    const newDataSource: DataSource = {
      id: Date.now(),
      sourceData: {},
      showDataSources: false,
    };
    setDataSources((prevDataSources) => [...prevDataSources, newDataSource]);
  };

  // triggers when clicked on data source / update data source selection
  const onClickDataSource = (
    addedSourceName: string,
    addedSourceData: string | SQLDataSource | SharePointForm,
    id: number,
  ) => {
    setDataSources((prevDataSources) => {
      const index = prevDataSources.findIndex((record) => record.id === id);

      if (index === -1) return prevDataSources;

      const updatedDataSources = [...prevDataSources];
      switch (addedSourceName) {
        case 'sharepoint':
          updatedDataSources[index] = {
            ...updatedDataSources[index],
            sourceData: { addedSourceName, sharePointForm: addedSourceData },
          };
          break;
        case 'fileupload':
          updatedDataSources[index] = {
            ...updatedDataSources[index],
            sourceData: { addedSourceName, data: addedSourceData },
          };
          break;
        case 'sql':
          updatedDataSources[index] = {
            ...updatedDataSources[index],
            sourceData: { addedSourceName, data: addedSourceData },
          };
          break;
        default:
          break;
      }
      return updatedDataSources;
    });
  };

  const triggerCreateWorkspaceValidations = async () => {
    await trigger();
  };

  const deleteSourceDetails = (idToDelete: number) => {
    setDataSources((prevDataSources) =>
      prevDataSources.filter((record) => record.id !== idToDelete),
    );
    triggerCreateWorkspaceValidations();
  };

  useEffect(() => {
    triggerCreateWorkspaceValidations();
  }, [workspaceName, dataSources]);

  // check if added data sources are valid
  const validDataSources = (): boolean => {
    return dataSources.some(({ sourceData }: DataSource) => {
      if ('addedSourceName' in sourceData) {
        const { sharePointForm, data, addedSourceName } =
          sourceData as SourceData;
        switch (addedSourceName) {
          case 'sharepoint':
            return (
              !validateSharePointForm(sharePointForm) ||
              sharePointUrlsValidation()?.length > 0
            );
          case 'fileupload':
            return !data;
          case 'sql':
            const isSQLFormInValid =
              data &&
              Object.values(data).some(
                (value) => value == null || value === '',
              );
            return isSQLFormInValid;
          default:
            return false; // If addedSourceName is not 'sharepoint' or 'fileupload', consider it as invalid
        }
      }
      return false; // If addedSourceName doesn't exist, consider it as invalid
    });
  };

  const scrollToBottom = () => {
    if (dataSourcesScrollRef.current) {
      dataSourcesScrollRef.current.scrollTop =
        dataSourcesScrollRef.current.scrollHeight;
    }
  };

  // Scroll to bottom when new dataSources is selected or add another data source button is clicked
  useEffect(() => {
    scrollToBottom();
  }, [dataSources.length, visibleDataSourcesRowCount]);

  const showDataSource = (uniqueID: number) => {
    setDataSources((prevDataSources) =>
      prevDataSources.map((record) => {
        if (uniqueID === record.id) {
          record.showDataSources = true;
        }
        return record;
      }),
    );
  };

  const validateSpUrlStructure = (url: string) => {
    try {
      const parsedUrl: URL = new URL(url);
      let siteName = '';
      let folderName = '';
      const segments = decodeURIComponent(parsedUrl.pathname).split('/');
      const sitesIndex = segments.findIndex(
        (segment) => segment.toLowerCase() === 'sites',
      );

      if (sitesIndex !== -1) {
        siteName = segments[sitesIndex + 1] || '';
        folderName = (segments[sitesIndex + 2] || '').replace('Shared ', '');
      }

      if (parsedUrl.searchParams.has('id')) {
        const idSegments = decodeURIComponent(
          parsedUrl.searchParams.get('id') as string,
        ).split('/');
        const idSitesIndex = idSegments.findIndex(
          (segment) => segment.toLowerCase() === 'sites',
        );
        if (idSitesIndex !== -1) {
          //siteName = idSegments[idSitesIndex + 1] || '';
          folderName = idSegments
            .slice(idSitesIndex + 2)
            .join('/')
            .replace('Shared ', '');
        }
      }
      return { siteName: siteName, folderName: folderName };
    } catch (error) {
      return { siteName: '', folderName: '' };
    }
  };

  const sharePointUrlsValidation = () => {
    const validationResult: {
      sourceId: number;
      errorType: 'duplicate' | 'invalidUrl';
    }[] = [];
    const urlMap: Record<string, boolean> = {};
    for (const dataSource of dataSources) {
      const sourceData = dataSource.sourceData as SourceData;
      if (sourceData?.sharePointForm?.sharePointUrl) {
        const url = sourceData.sharePointForm.sharePointUrl;
        const { siteName, folderName } = validateSpUrlStructure(url);
        if (!siteName || !folderName) {
          validationResult.push({
            sourceId: dataSource.id,
            errorType: 'invalidUrl',
          });
        } else {
          if (urlMap[url]) {
            validationResult.push({
              sourceId: dataSource.id,
              errorType: 'duplicate',
            });
          } else {
            urlMap[url] = true;
          }
        }
      }
    }
    return validationResult;
  };

  return (
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-12'>
          <h2>Workspace management</h2>
        </div>
      </div>

      <div className='row mt-3 mb-5'>
        <div className='col-12'>
          <div className='d-flex justify-content-between'>
            <h4>
              <Icon name='briefcase_icon' size={IconSize.md} />
              Create new workspace
            </h4>
            <button
              // disabled={chatStatus === 'loading'}
              type='button'
              className='text-white btn btn-primary rounded'
              onClick={() => navigate('/management/list')}
            >
              <Icon name='left_arrow_white' size={IconSize.md} />
              Back
            </button>
          </div>
        </div>
      </div>

      <div className='row mb-4'>
        <div className='col-12 mb-3 mb-md-0 col-md-3 d-flex flex-column'>
          <label htmlFor='workspaceName' className='form-label'>
            Name of your new workspace
          </label>
          <input
            type='text'
            id='workspaceName'
            className={`form-control ${
              errors['workspaceName'] ? 'is-invalid' : ''
            }`}
            placeholder='Workspace Name'
            aria-label='Workspace Name'
            value={workspaceName}
            {...register('workspaceName', {
              validate: () => {
                // Custom validation to check for invalid workspace name
                const regex = /^[a-zA-Z0-9_-]+$/;
                if (!regex.test(workspaceName) && workspaceName.length) {
                  return 'Workspace name must only have alphanumeric characters (a-z, A-Z, 0-9), dashes ("-") and underscores ("_").';
                }
              },
            })}
            onChange={(e) => setWorkspaceName(e.target.value)}
          />
          {errors['workspaceName'] && (
            <span className='text-danger my-1'>
              {(errors['workspaceName'] as FieldError)?.message}
            </span>
          )}
        </div>

        <div className='col-12 mb-3 mb-md-0 col-md-2 d-flex flex-column'>
          <label htmlFor='chargeCode' className='form-label'>
            Charge code
          </label>
          <input
            type='text'
            id='chargeCode'
            className='form-control'
            placeholder='Charge Code'
            aria-label='Charge Code'
            value={chargeCode}
            onChange={(e) => {
              setChargeCode(e.target.value);
            }}
          />
        </div>

        <div
          className={`col-12 mb-3 mb-md-0 col-md d-flex flex-column align-items-start ${styles.customMargin}`}
        >
          <button
            type='button'
            className='text-white btn btn-primary rounded d-flex gap-2 align-items-center'
            onClick={() => showDataSource(dataSources[0].id)}
            disabled={dataSources[0]?.showDataSources}
          >
            <Icon name='add_circle_outline' size={IconSize.md} />
            Add Data Source
          </button>
        </div>

        <div
          className={`col-12 mb-3 mb-md-0 col-md d-flex flex-column align-items-end ${styles.customMargin}`}
        >
          <button
            type='button'
            className='text-white btn btn-primary rounded'
            disabled={
              !workspaceName ||
              !chargeCode ||
              isLoading ||
              validDataSources() ||
              Object.keys(errors).length !== 0 ||
              dataSources.length === 1
            }
            onClick={() => createWorkSpace()}
          >
            {isLoading ? (
              <>
                <Spinner
                  animation='border'
                  size='sm'
                  as='span'
                  role='status'
                  aria-hidden='true'
                  className='mx-1'
                />
                Creating workspace
              </>
            ) : (
              <>Create workspace</>
            )}
          </button>
        </div>
      </div>

      {/* might be used once file upload is implemented
        {
        workspaceName && chargeCode && selectedFiles.length > 0 && (
          <div className='row'>
            <div className='col-12 d-flex justify-content-end'>
              <div className='d-flex justify-content-center'>
                <button
                  type='button'
                  className='me-3 text-white btn btn-primary rounded d-flex align-items-center'
                  onClick={() => clearFileUploadInputs()}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='text-white btn btn-primary rounded d-flex align-items-center'
                  onClick={() => createWorkspaceViaFileUpload()}
                >
                  Create workspace
                </button>
              </div>
            </div>
          </div>
        )
      } */}

      {/* for handling multiple addition of data sources */}
      <div
        className={`row overflow-auto ${styles.scrollableDataSources}`}
        ref={dataSourcesScrollRef}
      >
        <form>
          {dataSources.map((dataSource, index) => (
            <div key={dataSource.id}>
              <DataSources
                addNewDataSource={addNewDataSource}
                sourceDetails={dataSource}
                onClickDataSource={onClickDataSource}
                deleteSourceDetails={deleteSourceDetails}
                index={index}
                register={register}
                errors={errors}
                allDataSources={dataSources}
                showDataSource={showDataSource}
                sharePointUrlsValidationData={sharePointUrlsValidation()}
              />
            </div>
          ))}
        </form>
      </div>
      {showPopup && renderPopupModal()}
    </div>
  );
};

export default WorkspaceCreatePageContent;
