import Icon, { IconSize } from 'components/Icon';
import React from 'react';
import { getParentFolderData } from 'utils/filePath';

import './styles.scss';

export interface FilePathProps {
  field?: string; // Optional URL for the SharePoint folder path
  icon?: string; // Optional icon to display alongside the folder name
}

const FilePath: React.FC<FilePathProps> = ({ field, icon }) => {
  // Extract folder name and URL from the provided field
  const {
    name: folderName,
    url: folderUrl,
    filePathBreadcrumb: breadcrumb,
  } = getParentFolderData(field || '');

  return (
    <div className='filePath__container'>
      {icon && <Icon name={icon} size={IconSize.sm} />}
      <div className='filePath__details'>
        {breadcrumb && (
          <a
            href={folderUrl}
            target='_blank'
            rel='noreferrer'
            className='d-inline-flex flex-nowrap'
          >
            <span className='text-decoration-underline FilePathLink fw-medium clickableLink text-wrap'>
              {breadcrumb}
            </span>
          </a>
        )}
      </div>
    </div>
  );
};

export default FilePath;
