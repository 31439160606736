import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import {
  createWorkspaceUserDataLookup,
  mergeWorkspaceUsersWithUserAPIData,
} from 'utils/users';

import {
  addUserToWorkspace,
  bulkAddUsersToWorkspace,
  bulkUpdateUserRoles,
  fetchWorkspaceApiAndPeopleApiUsers,
  removeUserFromWorkspace,
} from './api';
import { getAndSetCurrentUserData } from './graphqlQuery';
import { PeopleAPIUser, UserState, WorkspaceAPIUser } from './typings';

const initialState: UserState = {
  roles: ['user', 'admin'],

  addUserToWorkspaceStatus: 'idle',
  addUserToWorkspaceError: null,

  bulkAddUsersToWorkspaceStatus: 'idle',
  bulkAddUsersToWorkspaceError: null,
  bulkAddUsersSuccessfulUserIds: [],
  bulkAddUsersFailedUserIds: [],
  bulkUserAddedCompletionPercentage: 0,

  removeUserFromWorkspaceStatus: 'idle',
  removeUserFromWorkspaceError: null,

  bulkUpdateUserRolesStatus: 'idle',
  bulkUpdateUserRolesError: null,

  fetchWorkspaceApiAndPeopleApiUsersStatus: 'idle',
  fetchWorkspaceApiAndPeopleApiUsersError: null,
  workspaceAPIUsers: [],
  peopleAPIUsers: [],

  currentUser: null,
  getAndSetCurrentUserDataStatus: 'idle',
  getAndSetCurrentUserDataError: null,

  isUserBlacklisted: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUsersFromState: (state) => {
      state.peopleAPIUsers = [];
      state.workspaceAPIUsers = [];
    },
    addOrUpdateWorkspaceApiUserInState: (state, action) => {
      const existingUserIndex = state.workspaceAPIUsers.findIndex(
        (user) => user.userId === action.payload.userId,
      );
      if (existingUserIndex !== -1) {
        state.workspaceAPIUsers[existingUserIndex] = action.payload;
      } else {
        state.workspaceAPIUsers.push(action.payload);
      }
    },
    removeWorkspaceUserFromState: (state, action) => {
      state.workspaceAPIUsers = state.workspaceAPIUsers.filter(
        (user) => user.userId !== action.payload,
      );
    },
    removePeopleAPIUserFromState: (state, action) => {
      state.peopleAPIUsers = state.peopleAPIUsers.filter(
        (user) => user.id !== action.payload,
      );
    },
    setUserIsBlacklisted: (state, action) => {
      state.isUserBlacklisted = action.payload;
    },
    updateBulkUserAddedCompletionPercentage: (state, action) => {
      state.bulkUserAddedCompletionPercentage = action.payload;
    },
    resetBulkAddUsersStates: (state) => {
      state.bulkAddUsersSuccessfulUserIds = [];
      state.bulkAddUsersFailedUserIds = [];
      state.bulkUserAddedCompletionPercentage = 0;
    },
  },
  extraReducers: (builder) => {
    // Add user to workspace
    builder.addCase(addUserToWorkspace.pending, (state) => {
      state.addUserToWorkspaceStatus = 'loading';
    });
    builder.addCase(addUserToWorkspace.fulfilled, (state) => {
      // API returns a string, but we don't need it
      // so ignore the action.payload
      state.addUserToWorkspaceStatus = 'succeeded';
    });
    builder.addCase(addUserToWorkspace.rejected, (state, action) => {
      state.addUserToWorkspaceStatus = 'failed';
      state.addUserToWorkspaceError = action.payload as Error;
    });
    // Bulk add users to workspace
    builder.addCase(bulkAddUsersToWorkspace.pending, (state) => {
      state.bulkAddUsersToWorkspaceStatus = 'loading';
    });
    builder.addCase(bulkAddUsersToWorkspace.fulfilled, (state, action) => {
      // This API returns both successful and failed users, so
      // we need to handle the rejected case in the action.payload
      state.bulkAddUsersToWorkspaceStatus = 'succeeded';
      state.bulkAddUsersSuccessfulUserIds =
        action.payload.successfullyAddedUserIds;
      state.bulkAddUsersFailedUserIds = action.payload.failedUserIds;
    });
    builder.addCase(bulkAddUsersToWorkspace.rejected, (state, action) => {
      state.bulkAddUsersToWorkspaceStatus = 'failed';
      state.bulkAddUsersToWorkspaceError = action.payload as Error;
    });
    // Remove from workspace
    builder.addCase(removeUserFromWorkspace.pending, (state) => {
      state.removeUserFromWorkspaceStatus = 'loading';
    });
    builder.addCase(removeUserFromWorkspace.fulfilled, (state) => {
      // API returns a string, but we don't need it
      // so ignore the action.payload
      state.removeUserFromWorkspaceStatus = 'succeeded';
    });
    builder.addCase(removeUserFromWorkspace.rejected, (state, action) => {
      state.removeUserFromWorkspaceStatus = 'failed';
      state.removeUserFromWorkspaceError = action.payload as Error;
    });

    // Fetch users from workspace API and people API
    builder.addCase(fetchWorkspaceApiAndPeopleApiUsers.pending, (state) => {
      state.fetchWorkspaceApiAndPeopleApiUsersStatus = 'loading';
    });
    builder.addCase(
      fetchWorkspaceApiAndPeopleApiUsers.fulfilled,
      (state, action) => {
        const workspaceApiUsers = action.payload
          .workspaceApiUsers as WorkspaceAPIUser[];
        const peopleApiUsers = action.payload.peopleApiUsers as PeopleAPIUser[];

        state.workspaceAPIUsers = workspaceApiUsers;
        state.peopleAPIUsers = peopleApiUsers;
        state.fetchWorkspaceApiAndPeopleApiUsersStatus = 'succeeded';
      },
    );
    builder.addCase(
      fetchWorkspaceApiAndPeopleApiUsers.rejected,
      (state, action) => {
        state.fetchWorkspaceApiAndPeopleApiUsersStatus = 'failed';
        state.fetchWorkspaceApiAndPeopleApiUsersError = action.payload as Error;
      },
    );

    // Bulk update user roles
    builder.addCase(bulkUpdateUserRoles.pending, (state) => {
      state.bulkUpdateUserRolesStatus = 'loading';
    });
    builder.addCase(bulkUpdateUserRoles.fulfilled, (state) => {
      // API returns a string, but we don't need it
      // so ignore the action.payload
      state.bulkUpdateUserRolesStatus = 'succeeded';
    });
    builder.addCase(bulkUpdateUserRoles.rejected, (state, action) => {
      state.bulkUpdateUserRolesStatus = 'failed';
      state.bulkUpdateUserRolesError = action.payload as Error;
    });

    // Get and set current user data
    // from the people API
    builder.addCase(getAndSetCurrentUserData.fulfilled, (state, action) => {
      state.currentUser = action.payload as PeopleAPIUser;
      state.getAndSetCurrentUserDataStatus = 'succeeded';
    });
    builder.addCase(getAndSetCurrentUserData.pending, (state) => {
      state.getAndSetCurrentUserDataStatus = 'loading';
    });
    builder.addCase(getAndSetCurrentUserData.rejected, (state, action) => {
      state.getAndSetCurrentUserDataStatus = 'failed';
      state.getAndSetCurrentUserDataError = action.payload as Error;
    });
  },
});

// actions
export const {
  clearUsersFromState,
  addOrUpdateWorkspaceApiUserInState,
  removeWorkspaceUserFromState,
  removePeopleAPIUserFromState,
  setUserIsBlacklisted,
  updateBulkUserAddedCompletionPercentage,
  resetBulkAddUsersStates,
} = userSlice.actions;

// selectors

export const selectRoles = (state: RootState) => {
  return state.user.roles;
};

export const selectCurrentUserPicture = (state: RootState) =>
  state.user.currentUser?.picture;

export const selectAddUserToWorkspaceStatus = (state: RootState) =>
  state.user.addUserToWorkspaceStatus;

export const selectRemoveUserFromWorkspaceStatus = (state: RootState) =>
  state.user.removeUserFromWorkspaceStatus;

export const selectFetchWorkspaceApiAndPeopleApiUsersStatus = (
  state: RootState,
) => state.user.fetchWorkspaceApiAndPeopleApiUsersStatus;

export const selectBulkUpdateUserRolesStatus = (state: RootState) =>
  state.user.bulkUpdateUserRolesStatus;

export const selectGetAndSetCurrentUserDataStatus = (state: RootState) =>
  state.user.getAndSetCurrentUserDataStatus;

export const selectCurrentUser = (state: RootState) => state.user.currentUser;

export const selectIsUserBlacklisted = (state: RootState) =>
  state.user.isUserBlacklisted;

// merges people API data with the existing workspace API users
export const selectMergedWorkspaceAPIAndPeopleAPIUsers = (state: RootState) => {
  const { workspaceAPIUsers, peopleAPIUsers } = state.user;

  if (!workspaceAPIUsers || !peopleAPIUsers) {
    return [];
  }

  const workspaceUsersDataLookup =
    createWorkspaceUserDataLookup(workspaceAPIUsers);

  const mergedUsers = mergeWorkspaceUsersWithUserAPIData(
    peopleAPIUsers,
    workspaceUsersDataLookup,
  );

  return mergedUsers;
};

export const selectBulkUserAddedCompletionPercentage = (state: RootState) =>
  state.user.bulkUserAddedCompletionPercentage;

export const selectBulkAddUsersFailedUserIds = (state: RootState) => {
  return state.user.bulkAddUsersFailedUserIds;
};

export default userSlice.reducer;
