import { ErrorRouteState } from 'components/Routes';
import React from 'react';
import { useLocation } from 'react-router-dom';
import AccessMessage from 'utils/messages/AccessMessage';

interface ErrorContentProps {
  errorProp?: Error;
}

const ErrorContent: React.FC<ErrorContentProps> = ({ errorProp }) => {
  const location = useLocation();

  const NoWorkspaceAccessComponent = <AccessMessage />;

  const renderErrorMessage = (): JSX.Element => {
    const routeState: ErrorRouteState = location.state;

    const errorComponentToUse = routeState?.errorComponentToUse;
    if (errorComponentToUse === 'NO_WORKSPACE_ACCESS') {
      return NoWorkspaceAccessComponent;
    }

    const errorMessageFromRouteState = routeState?.message;
    if (errorMessageFromRouteState) {
      return <>{errorMessageFromRouteState}</>;
    }

    if (errorProp?.message) {
      return <>{errorProp.message}</>;
    }

    return (
      <>
        <span>
          Oops! Something went wrong on our end. Please try again later.
        </span>
      </>
    );
  };

  return (
    <div className='d-flex flex-row vh-100'>
      <div className='d-flex flex-grow-1 flex-column'>
        <div className='d-flex justify-content-center align-items-center vh-100'>
          <div className='text-center font-size-lg fw-bold p-4 shadow-sm rounded'>
            {renderErrorMessage()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorContent;
