import Summary from 'components/Summary';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const isHTMLString = (str: string) => {
  return /<[a-z][\s\S]*>/i.test(str);
};

const SummaryDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.description) {
    console.warn('SummaryDynamicWrapper: description is missing.');
    return null;
  }

  let description = props.description;

  // if description is html, extract the first bullet
  // i.e <strong>Summary:</strong><br/><ul><li>Research requested lorem ipsum</li></ul>
  // to "Research requested lorem ipsum"
  if (isHTMLString(description)) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
    const firstBullet = doc.querySelector('ul > li');

    description = firstBullet?.textContent || '';
  }

  return <Summary description={description} className={props.className} />;
};

export default SummaryDynamicWrapper;
