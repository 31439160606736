import Form from '@rjsf/semantic-ui';
import validator from '@rjsf/validator-ajv8';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import Icon, { IconSize } from 'components/Icon';
import SkeletonLoader from 'components/SkeletonLoader';
import { WorkspaceConfiguration } from 'components/WorkspaceConfigurations/typings';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { SkeletonTheme } from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { updateWorkspaceConfiguration } from 'redux/Workspace/api';
import {
  selectAvailableWorkspaceConfigurations,
  selectUpdateWorkspaceConfiguration,
} from 'redux/Workspace/slice';

import ArrayFieldTemplate from './ArrayFieldTemplate';
import './index.scss';
import { schema, uiSchema } from './jsonFormSchemas';
import ObjectFieldTemplate from './ObjectFieldTemplate';

const WorkspaceCustomizationContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [workspaceSelectedToCopy, setWorkspaceSelectedToCopy] =
    useState<WorkspaceConfiguration>();
  const [workspaceBeforeCopy, setWorkspaceBeforeCopy] = useState<any>();
  const [copyButtonClicked, setCopyButtonClicked] = useState<boolean>(false);
  const [undoButtonClicked, setUndoButtonClicked] = useState<boolean>(false);
  const availableWorkspaces = useAppSelector(
    selectAvailableWorkspaceConfigurations,
  );

  const workspaceUpdatingStatus = useAppSelector(
    selectUpdateWorkspaceConfiguration,
  );

  const { workspaceId } = useParams<{ workspaceId: string }>();

  const selectedWorkspaceConfig = availableWorkspaces?.find(
    (wrkspc) => wrkspc.customerId === Number(workspaceId),
  );

  const workspaceName = selectedWorkspaceConfig?.workspaceName;

  const handleChange = ({ formData }: any) => {
    console.log('Updated JSON: ', formData);
  };

  const handleSubmit = (data: any) => {
    dispatch(updateWorkspaceConfiguration(data.formData) as any);
  };

  const updateWorkspaceViaCopy = () => {
    setCopyButtonClicked(true);
    const workspaceToBeUpdated = JSON.parse(
      JSON.stringify(selectedWorkspaceConfig),
    );
    setWorkspaceBeforeCopy(workspaceToBeUpdated);
    const newWorkspace = {
      ...workspaceToBeUpdated,
      method: workspaceSelectedToCopy?.method,
      dataRepository: workspaceSelectedToCopy?.dataRepository,
      version: workspaceSelectedToCopy?.version,
      routes: workspaceSelectedToCopy?.routes,
      features: workspaceSelectedToCopy?.features,
      search: {
        ...workspaceSelectedToCopy?.search,
        consumerId: workspaceToBeUpdated?.search?.consumerId,
      },
      chat: {
        ...workspaceSelectedToCopy?.chat,
        consumerId: workspaceToBeUpdated?.chat?.consumerId,
      },
    };
    dispatch(updateWorkspaceConfiguration(newWorkspace) as any);
  };

  const undoCopyChanges = () => {
    setCopyButtonClicked(false);
    setUndoButtonClicked(true);
    dispatch(updateWorkspaceConfiguration(workspaceBeforeCopy) as any);
  };

  const handleWorkspaceConfigToBeCopied = (
    workspace: WorkspaceConfiguration,
  ) => {
    setWorkspaceSelectedToCopy(workspace);
  };

  useEffect(() => {
    if (workspaceUpdatingStatus === 'succeeded') {
      if (!copyButtonClicked && !undoButtonClicked) {
        navigate('/management/list');
      }
      if (undoButtonClicked) {
        setUndoButtonClicked(false);
      }
    }
  }, [workspaceUpdatingStatus]);

  return (
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-12'>
          <h2>Workspace customization</h2>
        </div>
      </div>
      <div className='row mt-2 justify-content-end me-1 me-md-0'>
        <div className='col-2 text-end'>
          <button
            onClick={() => navigate(-1)}
            type='button'
            className='text-white btn btn-primary rounded'
          >
            <Icon name='left_arrow_white' size={IconSize.md} />
            Back
          </button>
        </div>
      </div>
      <div className='row mb-4'>
        <h5>{workspaceName}</h5>
      </div>
      <div className='row mb-1'>
        <div className='mb-2'>Select Workspace to copy Configuration</div>
        <div className='col-auto mb-2'>
          <Dropdown className='border rounded' align='end'>
            <Dropdown.Toggle
              variant='bcg-light'
              id='workspace-dropdown'
              className='text-truncate customDropdownButton'
            >
              {workspaceSelectedToCopy?.workspaceName || 'Select Workspace'}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className='customDropdown'
              {...(availableWorkspaces.length > 10 && {
                as: CustomDropdownMenu,
              })}
            >
              {availableWorkspaces
                .filter(
                  (workspace) => workspace.workspaceName !== workspaceName,
                )
                .map((wrkspc) => (
                  <Dropdown.Item
                    key={wrkspc.customerId}
                    id={wrkspc.id}
                    onClick={(e) => handleWorkspaceConfigToBeCopied(wrkspc)}
                    active={
                      workspaceSelectedToCopy?.customerId === wrkspc.customerId
                    }
                  >
                    {wrkspc.workspaceName}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col-auto'>
          <button
            className='btn btn-primary'
            disabled={
              !workspaceSelectedToCopy ||
              (workspaceUpdatingStatus === 'loading' && copyButtonClicked) ||
              undoButtonClicked
            }
            onClick={updateWorkspaceViaCopy}
          >
            {workspaceUpdatingStatus === 'loading' && copyButtonClicked
              ? 'Copying...'
              : 'Copy Configuration'}
          </button>
        </div>
        {workspaceUpdatingStatus !== 'failed' &&
          workspaceUpdatingStatus !== 'idle' &&
          workspaceBeforeCopy && (
            <div className='col-auto'>
              <button
                className='btn btn-secondary'
                onClick={undoCopyChanges}
                disabled={
                  !copyButtonClicked ||
                  (workspaceUpdatingStatus === 'loading' && undoButtonClicked)
                }
              >
                {workspaceUpdatingStatus === 'loading' && undoButtonClicked
                  ? 'Undo In Progress...'
                  : 'Undo'}
              </button>
            </div>
          )}
      </div>
      <div className='row mt-3 mb-2'>
        <WorkspaceForm
          selectedWorkspaceConfig={selectedWorkspaceConfig}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          showLoading={
            workspaceUpdatingStatus === 'loading' &&
            !copyButtonClicked &&
            !undoButtonClicked
          }
        />
      </div>
    </div>
  );
};

export default WorkspaceCustomizationContent;

interface WorkspaceFormProps {
  selectedWorkspaceConfig: WorkspaceConfiguration | undefined;
  handleChange: (data: any) => void;
  handleSubmit: (data: any) => void;
  showLoading: boolean;
}

const WorkspaceForm: React.FC<WorkspaceFormProps> = ({
  selectedWorkspaceConfig,
  handleChange,
  handleSubmit,
  showLoading,
}) => {
  if (showLoading) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <SkeletonTheme
          baseColor='#f2f2f2'
          highlightColor='#ccc'
          enableAnimation={false}
        >
          <SkeletonLoader
            count={5}
            variant='rect'
            width={1300}
            height={40}
            style={{ marginTop: '2rem' }}
          />
        </SkeletonTheme>
        <h4 className='mt-3'>Updating Workspace Config...</h4>
      </div>
    );
  }

  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      validator={validator}
      templates={{ ArrayFieldTemplate, ObjectFieldTemplate }}
      formData={selectedWorkspaceConfig}
      onChange={handleChange}
      onSubmit={handleSubmit}
    >
      <div>
        <button type='submit' className='btn btn-primary'>
          Submit
        </button>
      </div>
    </Form>
  );
};
