import { shareIconSvg } from 'constants/shareIconsvg';

export const getEmailTextWithAnchorLinks = (emailText: string) => {
  // match pattern 1 [filename.extension icon] here <https://>
  const linkUrlRegexPattern1 = /\[([^\]]+)\s+icon\]\s+here<*(.+)>*/g;
  const emailTextWithAnchorTagsAfterPattern1Matching = emailText.replace(
    linkUrlRegexPattern1,
    (match, fileName, link) => {
      const strippedLink = link.replace(/[<>]/g, '');
      const strippedFileName = fileName.replace(/[<>]/g, '');
      return ` here <a href="${strippedLink}" target="_blank" rel="noopener noreferrer">${strippedFileName}</a> `;
    },
  );

  // match pattern 1 [filename.extension icon] filename.extension <https://>
  const linkUrlRegexPattern2 = /\[(.+)?](.*)<(.+)>/g;
  const emailTextWithAnchorTagsAfterPattern2Matching =
    emailTextWithAnchorTagsAfterPattern1Matching.replace(
      linkUrlRegexPattern2,
      (match, $1, fileName, link) => {
        return ` <a href="${link}" target="_blank" rel="noopener noreferrer">${fileName}</a> `;
      },
    );

  // replaces all remaining https links with anchor tag
  const httpURLRegex = /<*(https?:\/\/[^ \n]+)(?![^<]*<\/a>)/g;
  const emailTextWithAnchorTags =
    emailTextWithAnchorTagsAfterPattern2Matching.replace(
      httpURLRegex,
      (match, link) => {
        let trimmedLink = match.substring(0, match.lastIndexOf('>'));
        trimmedLink = trimmedLink.replace(/[<>]/g, '');
        return ` <a href="${trimmedLink === '' ? match : trimmedLink}" target="_blank" rel="noopener noreferrer">${shareIconSvg}</a>`;
      },
    );

  return emailTextWithAnchorTags;
};
