import './styles.scss';

export interface SummaryProps {
  description: string;
  className: string;
}

const Summary: React.FC<SummaryProps> = ({ description, className }) => {
  return (
    <div className='Summary row fw-normal'>
      <div className={`${className} text-ellipsis`}>{description}</div>
    </div>
  );
};

export default Summary;
