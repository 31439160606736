import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchData } from 'utils/apiRequest';
import { API_ENDPOINTS } from 'utils/constants';
import { v4 as uuidv4 } from 'uuid';

const PRIVACY_AGREEMENT_STORAGE_ID = 'voyager_privacy_agreement';

export const savePrivacyAgreement = createAsyncThunk<string>(
  'app/savePrivacyAgreement',
  async (_, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting app/savePrivacyAgreement, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    const savePrivacyAgreementResponse = await fetchData(
      `${API_ENDPOINTS.storage}/${PRIVACY_AGREEMENT_STORAGE_ID}`,
      'PUT',
      { payload: 'true' },
    );

    if (
      savePrivacyAgreementResponse.status < 200 ||
      savePrivacyAgreementResponse.status >= 300
    ) {
      const data = await savePrivacyAgreementResponse.json();
      console.error(
        `Error in app/savePrivacyAgreement for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue({
        error: JSON.parse(data?.detail),
        statusCode: savePrivacyAgreementResponse.status,
      });
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `app/savePrivacyAgreement complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    if (savePrivacyAgreementResponse.status === 201) {
      return 'success';
    }

    return 'failed';
  },
);

export const getPrivacyAgreement = createAsyncThunk<string>(
  'app/getPrivacyAgreement',
  async (_, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting app/getPrivacyAgreement, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    const getPrivacyAgreementResponse = await fetchData(
      `${API_ENDPOINTS.storage}/${PRIVACY_AGREEMENT_STORAGE_ID}`,
      'GET',
    );

    if (
      getPrivacyAgreementResponse.status < 200 ||
      getPrivacyAgreementResponse.status >= 300
    ) {
      const data = await getPrivacyAgreementResponse.json();
      console.error(
        `Error in app/getPrivacyAgreement for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue({
        error: JSON.parse(data?.detail),
        statusCode: getPrivacyAgreementResponse.status,
      });
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `app/getPrivacyAgreement complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    const data = await getPrivacyAgreementResponse.json();
    return data?.payload === 'true' ? 'accepted' : 'pending';
  },
);
