import { User } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export class DataDog {
  static init() {
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATA_DOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'voyager',
        env: process.env.REACT_APP_ENVIRONMENT,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
        allowedTracingUrls: [
          process.env.REACT_APP_API_BASE_URL,
          process.env.REACT_APP_PROFILE_PICTURE_API_BASE_URL,
          process.env.REACT_APP_PEOPLE_API_BASE_URL,
        ],
        traceSampleRate: 100,
      });

      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        sessionSampleRate: 100,
        env: process.env.REACT_APP_ENVIRONMENT,
      });
    }
  }

  static setUser(user: User) {
    datadogRum.setUser(user);
  }
}
