import classNames from 'classnames';
import Icon, { IconSize } from 'components/Icon';
import useAnalytics from 'hooks/Adobe/useAdobeAnalytics';
import React, { MouseEvent } from 'react';
import { selectFilterQuery, selectLastFilterQuery } from 'redux/Filters/slice';
import { SearchParamFilters } from 'redux/Filters/typings';
import { useAppSelector } from 'redux/hooks';

import './styles.scss';

export interface TitleAsLinkProps {
  title?: string;
  url?: string;
  target?: string;
  className?: string;
  prependIcon?: string;
  appendIcon?: string;
}

const TitleAsLink: React.FC<TitleAsLinkProps> = ({
  title,
  url,
  target,
  className,
  prependIcon,
  appendIcon,
}) => {
  const { recordSearchResultsInteractionEvent } = useAnalytics();

  const filterQuery = useAppSelector(selectFilterQuery);
  const lastFilterQuery = useAppSelector(selectLastFilterQuery);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (url) {
      e.stopPropagation();
      recordSearchResultsInteractionEvent({
        resultName: title ?? 'NA',
        searchTerm: filterQuery,
        previousTerm: lastFilterQuery,
        previousFilters: {} as SearchParamFilters,
        workspaceName: '',
      });

      window.open(url, target);
    }
  };

  return (
    <div
      onClick={(event: MouseEvent<HTMLDivElement>) => handleClick(event)}
      className={classNames(className, 'TitleAsLink', 'fw-medium', {
        clickableLink: url,
      })}
    >
      {prependIcon && (
        <Icon name={prependIcon} size={IconSize.sm} className='me-2 mb-1' />
      )}
      {title}
      {appendIcon && (
        <Icon name={appendIcon} size={IconSize.sm} className='ms-2 mb-1' />
      )}
    </div>
  );
};

export default TitleAsLink;
