interface LocalStorageItem<T> {
  key: string;
  value: T;
}

const getItem = <T>(key: string): T | null => {
  const data = localStorage.getItem(key);
  if (!data) return null;

  try {
    return JSON.parse(data) as T;
  } catch (error) {
    console.error('Error parsing localStorage data for key:', key, error);
    return null;
  }
};

const setItem = <T>(item: LocalStorageItem<T>): void => {
  localStorage.setItem(item.key, JSON.stringify(item.value));
};

const removeItem = (key: string): void => {
  localStorage.removeItem(key);
};

export const LOCAL_STORAGE_KEYS = {
  SELECTED_WORKSPACE: 'selectedWorkspace',
};

export const LocalStorageUtil = {
  getItem,
  setItem,
  removeItem,
};
