import { ObjectFieldTemplateProps } from '@rjsf/utils';
import React from 'react';
import { Accordion } from 'react-bootstrap';

const ObjectFieldTemplate: React.FC<ObjectFieldTemplateProps> = React.memo(
  (props) => {
    const { title, description, properties, onAddClick, schema } = props;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const addFunction = onAddClick(schema);
      addFunction();
    };

    return (
      <Accordion defaultActiveKey='1'>
        <Accordion.Item eventKey='0'>
          <Accordion.Button>
            <h6>{title}</h6>
          </Accordion.Button>
          <Accordion.Body>
            <p>{description}</p>
            <div>
              {properties.map((element, index) => (
                <div key={index}>{element.content}</div>
                //{/* {title === 'Filter Value to Label Mapping' && <button onClick={element.content.props.onDropPropertyClick(element.content.props.name)}>Delete</button>} */ }
              ))}
            </div>
            {title === 'Filter Value to Label Mapping' && (
              <button onClick={handleClick}>Add</button>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  },
);

export default ObjectFieldTemplate;
