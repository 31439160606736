import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import ErrorContent from 'components/ErrorContent';
import Cookies from 'js-cookie';
import oktaAuthService from 'oktaConfig';
import { getUserClaims } from 'oktaConfig';
import GeneralErrorPage from 'pages/GeneralErrorPage';
import LoadingPage from 'pages/LoadingPage';
import UserListPage from 'pages/UserListPageContent';
import WorkspaceCreatePage from 'pages/WorkspaceCreatePage';
import WorkspaceCustomizationPage from 'pages/WorkspaceCustomizationPage';
import WorkspaceDetailsPage from 'pages/WorkspaceDetailsPage';
import WorkspaceListPage from 'pages/WorkspaceListPage';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { updatePageAndPreviousPage } from 'redux/App/slice';
import { selectFilterData, selectFilterQuery } from 'redux/Filters/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getPrivacyAgreement } from 'redux/PrivacyAgreement/api';
import { getAndSetCurrentUserData } from 'redux/User/graphqlQuery';
import {
  selectCurrentUser,
  selectGetAndSetCurrentUserDataStatus,
  setUserIsBlacklisted,
} from 'redux/User/slice';
import { NEERS_HRID } from 'redux/User/typings';
import { fetchAndSetUsersWorkspaceConfigurations } from 'redux/Workspace/api';
import {
  clearAvailableWorkspaceConfigurations,
  selectAvailableWorkspaceConfigurationsError,
  selectAvailableWorkspaceConfigurationsStatus,
  selectCurrentWorkspace,
  selectHasUserAccessToAnyWorkspace,
} from 'redux/Workspace/slice';
import { usaBillaSetup } from 'Usabilla';
import AccessMessage from 'utils/messages/AccessMessage';
import { testUsersAccountData } from 'utils/users/testUserAccounts';
import { v4 as uuidv4 } from 'uuid';

import RequiredAuth from './SecureRoute';

export interface ErrorRouteState extends Error {
  errorComponentToUse?: 'NO_WORKSPACE_ACCESS';
}

export interface GeneralSecureRoute {
  id: string;
  icon: string;
  label: string;
  path: string;
  showInSidebar: boolean;
  element: JSX.Element;
}

// routes that are not dynamic and
// are not based on workspace features
export const generalSecureRoutes: GeneralSecureRoute[] = [
  {
    id: uuidv4(),
    icon: '',
    label: '',
    path: '/error',
    showInSidebar: false,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <GeneralErrorPage />
      </React.Suspense>
    ),
  },
  {
    id: uuidv4(),
    icon: 'workspace_management_icon',
    label: 'Manage',
    path: '/management/list',
    showInSidebar: true,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <WorkspaceListPage />
      </React.Suspense>
    ),
  },
  {
    id: uuidv4(),
    icon: 'workspace_management_icon',
    label: 'Manage',
    path: '/management/create',
    showInSidebar: false,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <WorkspaceCreatePage />
      </React.Suspense>
    ),
  },
  {
    id: uuidv4(),
    icon: 'workspace_management_icon',
    label: 'Manage',
    path: '/management/details/:workspaceId',
    showInSidebar: false,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <WorkspaceDetailsPage />
      </React.Suspense>
    ),
  },
  {
    id: uuidv4(),
    icon: 'workspace_management_icon',
    label: 'Manage',
    path: '/management/:customerId/user/list',
    showInSidebar: false,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <UserListPage />
      </React.Suspense>
    ),
  },
  {
    id: uuidv4(),
    icon: '',
    label: '',
    path: '/management/customization/:workspaceId',
    showInSidebar: false,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <WorkspaceCustomizationPage />
      </React.Suspense>
    ),
  },
];

const AppRoutes = () => {
  const [dynamicSecureRoutes, setDynamicSecureRoutes] = useState<JSX.Element[]>(
    [],
  );
  const navigate = useNavigate();
  const currentWorkspaceConfig = useAppSelector(selectCurrentWorkspace);
  const workspaceConfigurationsStatus = useAppSelector(
    selectAvailableWorkspaceConfigurationsStatus,
  );
  const workspaceConfigurationError = useAppSelector(
    selectAvailableWorkspaceConfigurationsError,
  );
  const workspaceConfigUserHasAccessToAtleastOneWorkspace = useAppSelector(
    selectHasUserAccessToAnyWorkspace,
  );
  const getCurrentUserDataStatus = useAppSelector(
    selectGetAndSetCurrentUserDataStatus,
  );
  const filterQuery = useAppSelector(selectFilterQuery);
  const filterData = useAppSelector(selectFilterData);
  const currentUserData = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const { oktaAuth, authState } = useOktaAuth();

  const location = useLocation();

  const workspaceRoutes = currentWorkspaceConfig?.routes;

  useEffect(() => {
    if (workspaceRoutes) {
      dispatch(updatePageAndPreviousPage({ location, workspaceRoutes }));
    }
  }, [location, dispatch, workspaceRoutes]);

  useEffect(() => {
    if (window.usabilla_live) {
      try {
        // Trigger virtualPageView by default
        window.usabilla_live('virtualPageView');
      } catch (error) {
        console.error('Error calling usabilla_live:', error);
      }
    } else {
      console.error('usabilla_live is not available');
    }
  }, [location, filterQuery, filterData]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const tokenData = { accessToken: oktaAuthService.getAccessToken() };
      Cookies.set('okta-token-storage_accessToken', JSON.stringify(tokenData));
    }
  }, [oktaAuth, authState, authState?.isAuthenticated]);

  useEffect(() => {
    // workspace config load logic
    loadWorkspaceConfig();
    handleWorkspaceConfigError();
    handleSuccessfulWorkspaceConfigLoad();

    // current user load logic
    loadCurrentUser();
    handleCurrentUserError();
    handleCurrentUserLocationBlacklist();
    loadUsabilla();
    loadPrivacyAgreement();
  }, [
    currentWorkspaceConfig,
    oktaAuth,
    authState,
    authState?.isAuthenticated,
    workspaceConfigurationsStatus,
    getCurrentUserDataStatus,
    currentUserData,
    dispatch,
  ]);

  const loadWorkspaceConfig = () => {
    // load workspace config if authenticated and no config loaded
    if (
      authState?.isAuthenticated &&
      !currentWorkspaceConfig &&
      workspaceConfigurationsStatus === 'idle'
    ) {
      dispatch(fetchAndSetUsersWorkspaceConfigurations());
    }
  };

  const loadPrivacyAgreement = async () => {
    if (
      authState?.isAuthenticated &&
      workspaceConfigurationsStatus === 'succeeded'
    ) {
      setTimeout(async () => {
        dispatch(getPrivacyAgreement());
      }, 2000);
    }
  };

  const handleWorkspaceConfigError = () => {
    // on error loading workspace config
    // send user to error page
    if (workspaceConfigurationsStatus === 'failed') {
      const errorMessage =
        workspaceConfigurationError?.message ||
        'Failed to load workspace configurations, please try again later.';

      const errorRouteState: ErrorRouteState = {
        message: errorMessage,
        name: 'errorRouteState',
      };

      if (!workspaceConfigUserHasAccessToAtleastOneWorkspace) {
        errorRouteState.errorComponentToUse = 'NO_WORKSPACE_ACCESS';
      }

      navigate('/error', { state: errorRouteState });
    }
  };

  const handleSuccessfulWorkspaceConfigLoad = () => {
    // on successful workspace config load
    if (
      currentWorkspaceConfig &&
      workspaceConfigurationsStatus === 'succeeded'
    ) {
      // based on workspace features, build the routes
      const secureRoutes: JSX.Element[] = [];

      currentWorkspaceConfig?.routes.forEach((route) => {
        const Component = React.lazy(() =>
          import(`pages/${route.element}`).catch(
            () => import('pages/NotFoundPage'),
          ),
        );

        secureRoutes.push(
          <Route
            key={route.id}
            path={route.path}
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Component />
              </React.Suspense>
            }
          />,
        );

        // catch all/404 route
        secureRoutes.push(
          <Route
            key='d8d4f109-73bf-4640-b844-86ae5a812e63'
            path='*'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                {secureRoutes[0].props.element}
              </React.Suspense>
            }
          />,
        );
      });

      setDynamicSecureRoutes(secureRoutes);
    }
  };

  const loadUsabilla = async () => {
    if (
      authState?.isAuthenticated &&
      !currentUserData &&
      getCurrentUserDataStatus === 'idle' &&
      currentWorkspaceConfig
    ) {
      const { customerId, workspaceName } = currentWorkspaceConfig;
      usaBillaSetup({ customerId, workspace: workspaceName });
    }
  };

  const loadCurrentUser = async () => {
    // load current user
    if (
      authState?.isAuthenticated &&
      currentWorkspaceConfig &&
      workspaceConfigurationsStatus === 'succeeded' &&
      !currentUserData &&
      getCurrentUserDataStatus === 'idle'
    ) {
      const currentUserClaims = await getUserClaims();
      const currentUserEmail = currentUserClaims.sub;
      let currentUserHrid = currentUserClaims.hrid;

      const isTestUser = testUsersAccountData.some(
        (user) =>
          user.email === currentUserEmail && user.id === currentUserHrid,
      );

      // People API does not have data for test users
      // so we use the HRID of a known user
      if (isTestUser) {
        currentUserHrid = NEERS_HRID;
      }

      dispatch(getAndSetCurrentUserData(currentUserHrid.toString()));
    }
  };

  const handleCurrentUserError = () => {
    // on error loading current user
    if (authState?.isAuthenticated && getCurrentUserDataStatus === 'failed') {
      console.error('Failed to load current user data');
    }
  };

  const handleCurrentUserLocationBlacklist = async () => {
    if (
      authState?.isAuthenticated &&
      getCurrentUserDataStatus === 'succeeded' &&
      currentUserData
    ) {
      const currentUserDataCopy = { ...currentUserData };

      const currentUserClaims = await getUserClaims();
      const currentUserEmail = currentUserClaims.sub;

      // for testing, we override the office location
      // for user Enrich-Test4@bcg.com to Beijing PRC
      const locationBlacklistTestingUser = testUsersAccountData.find(
        (user) => user.email === 'Enrich-Test4@bcg.com',
      );

      if (currentUserEmail === locationBlacklistTestingUser?.email) {
        currentUserDataCopy.hostOfficeLocation = {
          name: locationBlacklistTestingUser.hostOfficeLocation.name,
        };
      }

      const officeLocationBlacklist =
        process.env.REACT_APP_BLACKLISTED_REGIONS_AND_LOCATIONS?.split(',');

      if (
        officeLocationBlacklist?.includes(currentUserDataCopy?.hostOfficeRegion)
      ) {
        // clear workspace state to restrict access
        dispatch(clearAvailableWorkspaceConfigurations());
        navigate('/error', {
          state: {
            message:
              'Sorry, Voyager is currently not available in your region.',
            name: 'errorRouteState',
          } as ErrorRouteState,
        });
      }

      if (
        officeLocationBlacklist?.includes(
          currentUserDataCopy?.hostOfficeLocation?.name,
        )
      ) {
        // clear workspace state to restrict access
        dispatch(clearAvailableWorkspaceConfigurations());
        dispatch(setUserIsBlacklisted(true));
        navigate('/error', {
          state: {
            message:
              'Sorry, Voyager is currently not available in your region.',
            name: 'errorRouteState',
          } as ErrorRouteState,
        });
      }
    }
  };

  // Show spinner while loading workspace configurations
  if (workspaceConfigurationsStatus === 'loading') {
    return <LoadingPage />;
  }

  const staticSecureRoutes: JSX.Element[] = generalSecureRoutes.map((route) => (
    <Route
      key={route.id}
      path={route.path}
      element={
        <React.Suspense fallback={<LoadingPage />}>
          {route.element}
        </React.Suspense>
      }
    />
  ));

  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path='login/callback'
        element={
          <LoginCallback
            loadingElement={<LoadingPage />}
            errorComponent={({ error }) => (
              <ErrorContent
                errorProp={
                  {
                    message: <AccessMessage />,
                  } as unknown as Error
                }
              />
            )}
          />
        }
      />

      {/* Private Routes */}
      <Route path='/' element={<RequiredAuth />}>
        {[...dynamicSecureRoutes, ...staticSecureRoutes]}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
