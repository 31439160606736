import DefaultLayout from 'components/Layout/DefaultLayout';
import WorkspaceDetailsPageContent from 'components/WorkspaceDetailsPageContent';
import React from 'react';

const WorkspaceDetailsPage: React.FC = () => {
  return (
    <DefaultLayout hideWorkspaceDropdown>
      <WorkspaceDetailsPageContent />
    </DefaultLayout>
  );
};

export default WorkspaceDetailsPage;
