import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AdminWorkspaceConfiguration,
  DocData,
  KnowledgeSearchResponseData,
  OnClickAction,
  WorkspaceConfiguration,
  WorkspaceConfigurationDataRepository,
  WorkspaceConfigurationMethod,
  WorkspaceStatus,
} from 'components/WorkspaceConfigurations/typings';
import { AvailableAgentTools } from 'redux/AgentsChat/typings';
import {
  ConsumerOptions,
  RetrievalFilter,
  RetrievalMaterial,
  RetrievalRequestData,
  RetrievalResponseData,
} from 'redux/Retrieval/typings';
import { RootState } from 'redux/store';
import { fetchData } from 'utils/apiRequest';
import { API_ENDPOINTS } from 'utils/constants';
import { validateWorkspaceConfig } from 'utils/workspace';
import { v4 as uuidv4 } from 'uuid';

//import mockedDummyConfigJsonData from '../../dummyData/dummyWorkspaceConfig.json';
import {
  AdminWorkspaceResponse,
  CreatedWorkspaceObj,
  CreateWorkspaceObj,
  CreateWorkspaceObjRequestBody,
  DataSource,
  slideLevelSearchRequestBody,
  SlideLevelSearchResult,
  SourceData,
  WorkspaceApiErrorData,
  WorkspaceOption,
  WorkspaceResponse,
} from './typings';

export const fetchAndSetUsersWorkspaceConfigurations = createAsyncThunk<
  WorkspaceConfiguration[],
  void,
  { rejectValue: WorkspaceApiErrorData }
>(
  'workspace/fetchAndSetUsersWorkspaceConfigurations',

  async (_, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting workspace/fetchAndSetUsersWorkspaceConfigurations, request ID: ${requestId}.`,
    );

    const functionStartTime = new Date().getTime();

    const genaiCustomerDataApiUrl = `${API_ENDPOINTS.user}me`;

    const response = await fetchData(genaiCustomerDataApiUrl, 'GET');

    switch (response.status) {
      case 204:
        console.warn(
          'User recieved 204 status code from the API. User is authorized to access Voyager, but does not have access to any workspaces.',
        );

        return rejectWithValue({
          error: {
            detail:
              'User recieved 204 status code from the API. User is authorized to access Voyager, but does not have access to any workspaces.',
          },
          statusCode: 204,
          name: 'fetchAndSetUsersWorkspaceConfigurations204',
          message:
            'You have been authorized to access Voyager, but do not have access to any workspaces.\nPlease navigate to the Workspace Management page to create your first workspace',
        });

      case 500:
        console.error(
          `User recieved 500 status code from the API. Voyager is currently inaccessible.`,
        );

        return rejectWithValue({
          error: {
            detail:
              'User recieved 500 status code from the API. Voyager is currently inaccessible.',
          },
          statusCode: 500,
          name: 'fetchAndSetUsersWorkspaceConfigurations500',
          message: `Voyager is currently inaccessible, please try again later`,
        });

      default:
        if (response.status < 200 || response.status >= 300) {
          const data = await response.json();

          console.error(
            `Error calling search API for request ID ${requestId}. Data: ${JSON.stringify(
              data,
            )}`,
          );

          return rejectWithValue({
            error: {
              detail: data,
            },
            statusCode: response.status,
            name: 'fetchAndSetUsersWorkspaceConfigurationsError',
            message: `An error occurred while fetching workspace configurations. Please try again later`,
          });
        }
    }

    const data = (await response.json()) as WorkspaceResponse[];

    // line 107 - 109 will be replaced once dummy data is removed
    // if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
    //   data.push(mockedDummyConfigJsonData as unknown as WorkspaceResponse);
    // }

    const workspaceConfigs: WorkspaceConfiguration[] = [];
    data.forEach((workspaceResponse) => {
      if (typeof workspaceResponse.workspace !== 'object') {
        console.error(
          `Workspace configuration for customer ${workspaceResponse.customer_abbr} is not an object. Skipping.`,
        );
        return;
      }

      if (Object.keys(workspaceResponse.workspace).length === 0) {
        console.warn(
          `Workspace configuration for customer ${workspaceResponse.customer_abbr} is empty. Skipping.`,
        );
        return;
      }

      const workspaceWithCustomerId = {
        ...workspaceResponse.workspace,
        customerId: workspaceResponse.customer_id,
        workspaceName: workspaceResponse.customer_abbr,
        updatedDate: workspaceResponse.updated_at,
        status: workspaceResponse.status as WorkspaceStatus,
        chargeCode: workspaceResponse.charge_code,
        createdDate: workspaceResponse.created_at,
        workspaceId: workspaceResponse.workspace_id,
        accessType: workspaceResponse.access_type,
      };

      workspaceConfigs.push(workspaceWithCustomerId);
    });

    // does simple validation of the workspace config.
    // will log warnings to console if there are any issues.
    workspaceConfigs.forEach((workspace) => {
      validateWorkspaceConfig(workspace);
    });
    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Fetching workspace config complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );
    return workspaceConfigs;
  },
);

export const updateWorkspaceConfiguration = createAsyncThunk<
  string,
  WorkspaceConfiguration,
  { rejectValue: WorkspaceApiErrorData }
>(
  'workspace/updateWorkspaceConfiguration',
  async (updatedConfiguration, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting workspace/updateWorkspaceConfiguration, request ID: ${requestId}.`,
    );

    const functionStartTime = new Date().getTime();
    const requestPayload = {
      requestId: requestId,
      consumer_id: updatedConfiguration.workspaceName,
      workspace_id: updatedConfiguration.workspaceId,
      customer_id: updatedConfiguration.customerId,
      workspace_config: updatedConfiguration,
      status: updatedConfiguration.status,
    };

    const genaiupdateWorkspaceApiUrl = `${API_ENDPOINTS.customer}/update`;
    const response = await fetchData(
      genaiupdateWorkspaceApiUrl,
      'PUT',
      requestPayload,
    );
    switch (response.status) {
      case 204:
        console.warn(
          'User recieved 204 status code from the API. User is authorized to access Voyager, but does not have access to any workspaces.',
        );

        return rejectWithValue({
          error: {
            detail:
              'User recieved 204 status code from the API. User is authorized to access Voyager, but does not have access to any workspaces.',
          },
          statusCode: 204,
          name: 'updateWorkspaceConfiguration204',
          message:
            'You have been authorized to access Voyager, but do not have access to any workspaces.\nPlease navigate to the Workspace Management page to create your first workspace',
        });

      case 500:
        console.error(
          `User recieved 500 status code from the API. Voyager is currently inaccessible.`,
        );

        return rejectWithValue({
          error: {
            detail:
              'User recieved 500 status code from the API. Voyager is currently inaccessible.',
          },
          statusCode: 500,
          name: 'updateWorkspaceConfiguration500',
          message: `Voyager is currently inaccessible, please try again later`,
        });

      default:
        if (response.status < 200 || response.status >= 300) {
          const data = await response.json();

          console.error(
            `Error calling update workspace config API for request ID ${requestId}. Data: ${JSON.stringify(
              data,
            )}`,
          );

          return rejectWithValue({
            error: {
              detail: data,
            },
            statusCode: response.status,
            name: 'updateWorkspaceConfigurationError',
            message: `An error occurred while updating workspace configurations. Please try again later`,
          });
        }
    }

    const data = (await response.json()) as string;
    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Updating workspace config complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );
    return data;
  },
);

const getSharePointDataSources = (dataSources: DataSource[]) => {
  const data = dataSources.map((record) => {
    const source = record.sourceData as SourceData;
    if (source?.addedSourceName === 'sharepoint') {
      const form = source.sharePointForm;

      const dbConnection = btoa(form.dbConnection.trim());
      const dbUsername = btoa(form.dbUsername.trim());
      const dbPassword = btoa(form.dbPassword.trim());
      const dbTableName = btoa(form.dbTableName.trim());

      return {
        source: 'sharepoint',
        config_json: {
          source_url: form.sharePointUrl,
          external_metadata_required: form.externalMetadata,
          // metadata_source: {
          //   ...(form.externalMetadata && {
          //     connection_string: dbConnection,
          //     username: dbUsername,
          //     password: dbPassword,
          //     table_name: dbTableName,
          //   }),
          // },
          metadata_source: '',
        },
      };
    } else if (source?.addedSourceName === 'sql') {
      const form = source?.data;

      const dbConnection = btoa(form.dbConnectionString.trim());
      const dbUsername = btoa(form.dbUsername.trim());
      const dbPassword = btoa(form.dbPassword.trim());
      const dbTableName = btoa(form.dbTableName.trim());

      return {
        source: 'sql',
        config_json: {
          connection_string: dbConnection,
          username: dbUsername,
          password: dbPassword,
          table_name: dbTableName,
        },
      };
    }

    return {};
  });

  return data;
};

export const createWorkspace = createAsyncThunk<
  CreatedWorkspaceObj,
  CreateWorkspaceObj
>(
  'workspace/createWorkspace',

  async (createWorkspaceObj, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting workspace/createWorkspace, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    const genaiCreateWorkspaceApiUrl = API_ENDPOINTS.workspace;

    const requestBody: CreateWorkspaceObjRequestBody = {
      request_id: requestId,
      consumer_id: createWorkspaceObj.workspaceName.replace(/\s/g, ''),
      workspace_name: createWorkspaceObj.workspaceName,
      charge_code: createWorkspaceObj.chargeCode,
      generate_semantic: false,
      data_sources: [
        ...(getSharePointDataSources(
          createWorkspaceObj.dataSources,
        ) as CreateWorkspaceObjRequestBody['data_sources']),
      ],
    };

    const response = await fetchData(
      genaiCreateWorkspaceApiUrl,
      'POST',
      requestBody,
    );

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling create workspace API for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue({
        statusCode: response.status,
      });
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `workspace/createWorkspace complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );
    const results = (await response.json()) as CreatedWorkspaceObj;

    const workspaceOptions: WorkspaceOption[] = [];
    createWorkspaceObj.dataSources.forEach((dataSource, index) => {
      const source = dataSource.sourceData as SourceData;
      let form;
      if (source?.addedSourceName === 'sharepoint') {
        form = source.sharePointForm;
      } else if (source?.addedSourceName === 'sql') {
        form = source.data;
      }
      if (form['enableVision'] && form['enableVision'] === true) {
        const option: WorkspaceOption = {
          option_name: 'enable_vision',
          option_value: form.enableVision,
          datasource_id: results['data_sources'][index]['datasource_id'],
        };
        workspaceOptions.push(option);
      }
    });

    if (workspaceOptions.length > 0) {
      const genaiCreateWorkspaceOptionApiUrl = API_ENDPOINTS.workspaceOption;
      await fetchData(
        `${genaiCreateWorkspaceOptionApiUrl}${results.customer_id}`,
        'POST',
        {
          request_id: requestId,
          consumer_id: results['consumer_id'],
          options: workspaceOptions,
        },
      );
    }

    return { ...results, requestId };
  },
);

export const fetchAdminWorkspaceConfigurations = createAsyncThunk<
  AdminWorkspaceConfiguration[],
  void,
  { rejectValue: WorkspaceApiErrorData }
>(
  'workspace/fetchAdminWorkspaceConfigurations',

  async (_, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting workspace/fetchAdminWorkspaceConfigurations, request ID: ${requestId}.`,
    );

    const functionStartTime = new Date().getTime();

    const genaiAdminCustomerDataApiUrl = `${API_ENDPOINTS.user}me/admin`;

    const response = await fetchData(genaiAdminCustomerDataApiUrl, 'GET');

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling genai customer data admin API for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );

      return rejectWithValue({
        error: {
          detail: data,
        },
        statusCode: response.status,
        name: 'fetchAdminWorkspaceConfigurationsError',
        message:
          'An error occurred while fetching admin workspace configurations. Please try again later.',
      });
    }

    const data = (await response.json()) as AdminWorkspaceResponse[];

    const adminWorkspaceConfigs: AdminWorkspaceConfiguration[] = [];

    data.forEach((workspaceResponse) => {
      const config: AdminWorkspaceConfiguration = {
        customerId: workspaceResponse.customer_id,
        customerName: workspaceResponse.customer_name,
        customerChargeCode: workspaceResponse.customer_charge_code,
        workspaceStatus: workspaceResponse.workspace_status,
        workspaceDateCreated: workspaceResponse.workspace_date_created,
        totalUsers: workspaceResponse.total_users,
        workspaceID: workspaceResponse.workspace_id,
        dataSources: workspaceResponse.data_sources,
      };

      adminWorkspaceConfigs.push(config);
    });

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `workspace/fetchAdminWorkspaceConfigurations complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return adminWorkspaceConfigs;
  },
);

export const updateWorkspaceStatus = createAsyncThunk<
  string,
  { workspaceId: number; customerId: number; status: string }
>(
  'workspace/updateWorkspaceStatus',

  async (updateStatusObj, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting update workspace status, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    const genaiUpdateWorkspaceStatusApiUrl = `${API_ENDPOINTS.workspace}/update/status`;

    const requestBody = {
      customer_id: updateStatusObj.customerId,
      status: updateStatusObj.status,
      workspace_id: updateStatusObj.workspaceId,
      workspace_config: {},
    };

    const response = await fetchData(
      genaiUpdateWorkspaceStatusApiUrl,
      'PUT',
      requestBody,
    );

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling update workspace status API for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue(data);
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Update workspace status complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );
    const results = (await response.json()) as string;
    return results;
  },
);

export const deleteWorkspace = createAsyncThunk<string, number>(
  'workspace/deleteWorkspace',

  async (workspaceId, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting update workspace status, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    const genaiDeleteWorkspaceApiUrl = `${API_ENDPOINTS.workspace}/${workspaceId}`;

    const response = await fetchData(genaiDeleteWorkspaceApiUrl, 'DELETE', {
      requestId,
    });

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling delete workspace status API for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue(data);
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Delete workspace status complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );
    const results = (await response.json()) as string;
    return results;
  },
);

export const layoutOnClickAction = createAsyncThunk<
  {
    onClickAction: OnClickAction;
    retreivalData: RetrievalMaterial;
    retreivalDataBeforeFetch?: RetrievalMaterial;
    updatedLayoutOnClickActionStateHistoryIndex: number;
  } | null,
  { onClickAction: OnClickAction; retreivalData: RetrievalMaterial }
>(
  'workspace/layoutOnClickAction',

  async ({ onClickAction, retreivalData }, { rejectWithValue, getState }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting workspace/layoutOnClickAction search for request ID: ${requestId}}`,
    );

    const functionStartTime = new Date().getTime();

    const currentState = getState() as RootState;

    const filtersState = currentState.filters;

    const activeChatHistoryId = currentState?.chatHistory?.workspaceConfig.find(
      (config) =>
        config.customerId ===
        currentState?.workspace?.currentSelectedWorkspace?.customerId,
    )?.activeChatHistoryId;
    const chatHistoryArray = currentState?.chatHistory?.chatHistoryRecords;
    const chatQuery = chatHistoryArray?.filter(
      (chatHistory) => chatHistory?.chatHistoryId === activeChatHistoryId,
    )[0]?.title;

    const retreivalSearchQuery = chatQuery || filtersState.filterQuery;

    const currentLayoutOnClickActionStateHistoryIndex =
      currentState.workspace.layoutOnClickActionStateHistoryIndex;

    if (onClickAction.actionType === 'openPreview') {
      return {
        onClickAction,
        retreivalData,
        updatedLayoutOnClickActionStateHistoryIndex:
          currentLayoutOnClickActionStateHistoryIndex + 1,
      };
    }

    if (onClickAction.actionType === 'closePreview') {
      return {
        onClickAction,
        retreivalData,
        updatedLayoutOnClickActionStateHistoryIndex: -1,
      };
    }

    if (onClickAction.actionType === 'openPreviewWithFetch') {
      const apiToUse = onClickAction?.fetchApiToUse;
      const dataKeys = onClickAction?.fetchApiQueryDataKey;
      if (!apiToUse || !dataKeys) {
        return rejectWithValue(
          `Unable to complete workspace/layoutOnClickAction. API or data key is undefined for request ID: ${requestId}`,
        );
      }

      // some basic validation to ensure that the data keys are present in the data
      let hasRequiredDataForFetch = false;
      if (Array.isArray(dataKeys)) {
        hasRequiredDataForFetch = dataKeys.every(
          (fetchApiQueryDataKey) =>
            fetchApiQueryDataKey.mappedName in retreivalData,
        );
      }
      if (typeof dataKeys === 'string') {
        hasRequiredDataForFetch = dataKeys in retreivalData;
      }
      if (!hasRequiredDataForFetch) {
        return rejectWithValue(
          `Unable to complete workspace/layoutOnClickAction. Data to use as fetch query is undefined for request ID: ${requestId}`,
        );
      }

      switch (apiToUse) {
        case 'retreivalApi':
          const workspaceState = currentState.workspace;

          const currentWorkspaceConfig =
            workspaceState.currentSelectedWorkspace;

          if (!currentWorkspaceConfig) {
            return rejectWithValue(
              `Unable complete workspace/layoutOnClickAction. Workspace is undefined for request ID: ${requestId}`,
            );
          }

          const workspaceSearchConsumerId =
            currentWorkspaceConfig.workspaceName.toLowerCase();
          if (!workspaceSearchConsumerId) {
            return rejectWithValue(
              `Unable to send chat message. Workspace consumer ID is undefined for request ID: ${requestId}`,
            );
          }

          let workspaceDataRepository: WorkspaceConfigurationDataRepository =
            'vespa';
          if (currentWorkspaceConfig.dataRepository) {
            workspaceDataRepository = currentWorkspaceConfig.dataRepository;
          }

          let method: WorkspaceConfigurationMethod = 'hybrid_1';
          if (currentWorkspaceConfig.method) {
            method = currentWorkspaceConfig.method;
          }

          const retrievalApiUrl = API_ENDPOINTS.retrieval;

          const consumerOptions: ConsumerOptions = {
            consumer_key: workspaceSearchConsumerId,
            data_source: workspaceSearchConsumerId,
          };

          if (workspaceDataRepository === 'elastic') {
            consumerOptions.workspace_id = String(
              currentWorkspaceConfig.workspaceId,
            );
          }

          let filtersData: RetrievalFilter[] = [];
          if (Array.isArray(dataKeys)) {
            filtersData = dataKeys.map((dataKey) => ({
              name: dataKey.mappedName,
              type: 'list',
              filter_values: [retreivalData[dataKey.mappedName]],
            }));
          } else if (typeof dataKeys === 'string') {
            filtersData.push({
              name: dataKeys,
              type: 'list',
              filter_values: [retreivalData[dataKeys]],
            });
          }

          const cleanedRetreivalSearchQuery = retreivalSearchQuery.replace(
            /[!^()\-\+\[\]{}:"\/]/g,
            '',
          );

          const requestBody: RetrievalRequestData = {
            query: cleanedRetreivalSearchQuery || '',
            method: method,
            top_n: '100',
            consumer_options: consumerOptions,
            request_id: requestId,
            filters: filtersData,
            data_repository: workspaceDataRepository,
          };

          const response = await fetchData(
            retrievalApiUrl,
            'POST',
            requestBody,
          );

          if (response.status < 200 || response.status >= 300) {
            const data = await response.json();
            console.error(
              `Error calling search API in workspace/layoutOnClickAction for request ID ${requestId}. Data: ${JSON.stringify(
                data,
              )}`,
            );
            return rejectWithValue(data);
          }

          const data = (await response.json()) as RetrievalResponseData;

          let filteredData = {};
          if (
            onClickAction?.fetchedResultsToBeFilteredBy &&
            onClickAction?.fetchedResultsToBeFilteredBy.length > 0
          ) {
            if (data?.results.length === 1) {
              filteredData = data?.results[0];
            }
            if (data?.results.length > 1) {
              filteredData = data?.results?.find((result: RetrievalMaterial) =>
                onClickAction.fetchedResultsToBeFilteredBy?.every(
                  (dataRecord: {
                    key: string;
                    type: string;
                    value: string;
                  }) => {
                    const currentValue = retreivalData[dataRecord.key];
                    const expectedValue = result[dataRecord.value];
                    const expectedType = dataRecord.type.toLowerCase();

                    if (typeof currentValue === expectedType) {
                      return (
                        currentValue === expectedValue ||
                        currentValue.toString() === expectedValue.toString()
                      );
                    } else if (
                      expectedType === 'number' &&
                      !isNaN(currentValue)
                    ) {
                      return Number(currentValue) === Number(expectedValue);
                    } else if (expectedType === 'string') {
                      return (
                        currentValue?.toString() === expectedValue?.toString()
                      );
                    } else {
                      return false;
                    }
                  },
                ),
              );
            }
            return {
              onClickAction,
              retreivalData: filteredData,
              updatedLayoutOnClickActionStateHistoryIndex:
                currentLayoutOnClickActionStateHistoryIndex + 1,
            };
          }

          return {
            onClickAction,
            retreivalData: data,
            retreivalDataBeforeFetch: retreivalData,
            updatedLayoutOnClickActionStateHistoryIndex:
              currentLayoutOnClickActionStateHistoryIndex + 1,
          };

        case 'slidelevelSearch':
          const cleanedslideLevelSearchQuery = retreivalSearchQuery.replace(
            /[!^()\-\+\[\]{}:"\/]/g,
            '',
          );
          const slideLevelSearchUrl = API_ENDPOINTS.slideLevelSearch;

          const slideLevelSearchRequestBody: slideLevelSearchRequestBody = {
            query: cleanedslideLevelSearchQuery || '',
            guid: [retreivalData.kp_cms_id],
            resultsPerPage: 10000,
            matchMode: 'exact',
          };

          const slideLevelSearchResponse = await fetchData(
            slideLevelSearchUrl,
            'POST',
            slideLevelSearchRequestBody,
          );

          if (
            slideLevelSearchResponse.status < 200 ||
            slideLevelSearchResponse.status >= 300
          ) {
            const data = await slideLevelSearchResponse.json();
            console.error(
              `Error calling slide level search API in workspace/layoutOnClickAction for request ID ${requestId}. Data: ${JSON.stringify(
                data,
              )}`,
            );
            return rejectWithValue(data);
          }

          const responseData =
            (await slideLevelSearchResponse.json()) as SlideLevelSearchResult;
          const { attachments } = responseData;
          const docvizData = {
            Url: attachments[0].url,
            previews: attachments[0].previews,
            S3Object: attachments[0].s3Object,
            agentToolName: AvailableAgentTools.KNMaterials,
            page: retreivalData.page,
            FileLeafRef: retreivalData.filename,
            source_url: retreivalData.link,
            Modified:
              currentState.workspace.knowledgeSearchValidationSuccessData.filter(
                (doc: DocData) => doc.kpCmsId === retreivalData.kp_cms_id,
              )[0].dateRevised,
          };
          return {
            onClickAction,
            retreivalData: docvizData,
            retreivalDataBeforeFetch: retreivalData,
            updatedLayoutOnClickActionStateHistoryIndex:
              currentLayoutOnClickActionStateHistoryIndex + 1,
          };
        default:
          return rejectWithValue(
            `Unable to complete workspace/layoutOnClickAction. API not found for request ID: ${requestId}`,
          );
      }
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Slide Level Search complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return null;
  },
);

/**
 * This function will fetch the data for the message source data
 * and set the source to redux state.
 *
 * This will only run if the message source data is not already in the redux state.
 */
export const prefetchVoyagerSourceData = createAsyncThunk<
  RetrievalResponseData | null,
  { docId: string },
  { rejectValue: string }
>(
  'workspace/preFetchValidation',
  async ({ docId }, { rejectWithValue, getState }) => {
    const functionStartTime = Date.now();
    const currentState = getState() as RootState;
    const filtersState = currentState.filters;

    // immiediately return if the docId is already in the redux state.
    // however, because this is a "fire and forget" function, we will still
    // need to make sure to check for duplicate sources in the redux slice.
    if (
      currentState.workspace.prefetchSuccessData.find((d) => d.doc_id === docId)
    ) {
      return null;
    }

    const requestId = uuidv4();
    console.debug(
      `Starting prefetchVoyagerSourceData for request ID: ${requestId}`,
    );

    const activeChatHistoryId = currentState?.chatHistory?.workspaceConfig.find(
      (config) =>
        config.customerId ===
        currentState?.workspace?.currentSelectedWorkspace?.customerId,
    )?.activeChatHistoryId;

    const chatHistoryArray = currentState?.chatHistory?.chatHistoryRecords;
    const chatQuery = chatHistoryArray?.find(
      (chatHistory) => chatHistory?.chatHistoryId === activeChatHistoryId,
    )?.title;

    const retreivalSearchQuery = chatQuery ?? filtersState.filterQuery;

    const currentWorkspaceConfig =
      currentState.workspace.currentSelectedWorkspace;
    if (!currentWorkspaceConfig) {
      return rejectWithValue(
        `Workspace is undefined for request ID: ${requestId}`,
      );
    }

    const workspaceSearchConsumerId =
      currentWorkspaceConfig.workspaceName.toLowerCase();
    if (!workspaceSearchConsumerId) {
      return rejectWithValue(
        `Workspace consumer ID is undefined for request ID: ${requestId}`,
      );
    }

    const workspaceDataRepository: WorkspaceConfigurationDataRepository =
      currentWorkspaceConfig.dataRepository || 'vespa';
    const method: WorkspaceConfigurationMethod =
      currentWorkspaceConfig.method || 'hybrid_1';

    const retrievalApiUrl = API_ENDPOINTS.retrieval;
    const consumerOptions: ConsumerOptions = {
      consumer_key: workspaceSearchConsumerId,
      data_source: workspaceSearchConsumerId,
    };

    if (workspaceDataRepository === 'elastic') {
      consumerOptions.workspace_id = String(currentWorkspaceConfig.workspaceId);
    }

    const filtersData: RetrievalFilter[] = [
      {
        name: 'doc_id',
        type: 'list',
        filter_values: [docId],
      },
    ];

    const cleanedRetreivalSearchQuery = retreivalSearchQuery.replace(
      /[!^()\-\+\[\]{}:"\/]/g,
      '',
    );
    const requestBody: RetrievalRequestData = {
      query: cleanedRetreivalSearchQuery || '',
      method,
      top_n: '100',
      consumer_options: consumerOptions,
      request_id: requestId,
      filters: filtersData,
      data_repository: workspaceDataRepository,
    };

    const response = await fetchData(retrievalApiUrl, 'POST', requestBody);

    if (!response.ok) {
      const data = await response.json();
      console.error(
        `Error calling search API for preFetch Validation for request ID ${requestId}. Data: ${JSON.stringify(data)}`,
      );
      return rejectWithValue(data);
    }

    const data: RetrievalResponseData = await response.json();
    const functionEndTime = Date.now();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Search complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return data;
  },
);

export const conductKnowledgeSearch = createAsyncThunk<
  DocData[],
  { kpCmsIds: string[] },
  { rejectValue: string }
>(
  'workspace/conductKnowledgeSearch',
  async ({ kpCmsIds }, { rejectWithValue }) => {
    const functionStartTime = Date.now();
    const requestId = uuidv4();
    console.debug(
      `Starting knowledgeSearchValidation for request ID: ${requestId}`,
    );

    const transformIds = (ids: string[]) => {
      return ids
        .map((id, index) => {
          const prefix = index === 0 ? '' : ' OR ';
          return `${prefix}kp_cms_id:${id}`;
        })
        .join('');
    };

    const knowledgeSearchApiUrl = `${API_ENDPOINTS.knowledgeSearch}?query=${transformIds(kpCmsIds)}`;
    const response = await fetchData(knowledgeSearchApiUrl, 'GET');

    if (!response.ok) {
      const data = await response.json();
      console.error(
        `Error calling search API for knowledge search Validation for request ID ${requestId}. Data: ${JSON.stringify(data)}`,
      );
      return rejectWithValue(data);
    }

    const data: KnowledgeSearchResponseData = await response.json();
    const functionEndTime = Date.now();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Search complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return data.doc;
  },
);
