import TitleAsLink from 'components/TitleAsLink';
import React from 'react';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const TitleAsLinkDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.title && !props.title_part_1 && !props.title_part_2) {
    console.warn(
      `TitleAsLinkDynamicWrapper: title is missing. title: ${props.title}, title_part_1: ${props.title_part_1}, title_part_2: ${props.title_part_2}`,
    );
    return null;
  }

  return (
    <TitleAsLink
      title={
        props.title ||
        `${props.title_part_1} ${props.separator || ' '} ${
          props.title_part_2 || ''
        }`
      }
      url={props.url}
      target={props.target}
      className={props.className}
      prependIcon={props.prependIconName}
      appendIcon={props.appendIconName}
    />
  );
};

export default TitleAsLinkDynamicWrapper;
