import React from 'react';

/**
 * renders a message for users who do not have access to a workspace.
 * It provides links to the User Guide and FAQs opening them in new tab.
 */
const AccessMessage: React.FC = () => (
  <div className='container mt-4 lh-sm'>
    <p>
      Thank you for your interest in <strong>Voyager</strong>, our exciting
      GenAI tool!
      <br /> Voyager uses your team's data sources to provide synthesized
      insights tailored to your queries. <br />
      It accelerates your research, enhances the quality of outputs, connects
      you with experts, <br /> supports ideation, fosters upskilling, and more!
    </p>
    <p>
      Leverage the{' '}
      <a
        href='https://pages.navigator.bcg.com/kp/12c18a1b-79d2-403b-87e7-a54c568b58da?utm_medium=email&_hsenc=p2ANqtz-9Fyvm-GvAHi3d8Y4BtTPeMbf8x4_LMVRXSVSIcJnEEbntF_X5NsUR6uay8R6NR7YeC2wxw6fvkuliNaaIzl_5AmSJEmQ&_hsmi=96311076&utm_content=96311076&utm_source=hs_email'
        target='_blank'
        rel='noopener noreferrer'
      >
        User Guide
      </a>{' '}
      and{' '}
      <a
        href='https://trello.com/c/qCNoF0vX?utm_medium=email&_hsenc=p2ANqtz--IKjifAXaIqQ_fAU9CvXIdYNA-yIrNlEqX8dW9nqRqj42EnsnbzthLyflwd7BXE7GMefSPlXvspPudST9rc872XYA9Mw&_hsmi=96311076&utm_content=96311076&utm_source=hs_email'
        target='_blank'
        rel='noopener noreferrer'
      >
        FAQs
      </a>{' '}
      to learn more.
    </p>
    <p className='mb-3'>
      You currently do not have access to your team's workspace on Voyager.
      <br /> Your Voyager workspace is exclusive to your team only.
      <br /> To start using Voyager, please request access by reaching out to
      your PA champion.
    </p>
  </div>
);

export default AccessMessage;
