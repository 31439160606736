import { WorkspaceRoutes } from 'components/WorkspaceConfigurations/typings';
import { SearchParamFilters } from 'redux/Filters/typings';

import {
  AdobeDigitalData,
  AnalyticsProperties,
  BaseAnalyticsProperties,
} from './typings';

export const generateAdobeAnalyticsEvent = (
  baseProperties: BaseAnalyticsProperties,
  additionalAnalyticsProperties?: AnalyticsProperties,
): AdobeDigitalData => {
  const adobeAnalyticsEvent: AdobeDigitalData = {
    page: {
      pageInfo: {
        pageName: baseProperties.pageName,
        previousPage: baseProperties.previousPage || '',
        pageURL: baseProperties.pageUrl,
        staffSystemID: baseProperties.staffSystemID,
        documentID: '',
      },
    },
    productName: baseProperties.productName,
    internal: {
      search: {
        term: '',
      },
      previousSearch: {
        term: '',
      },
    },
    preFilters: '',
    filterValue: '',
    filterCategory: '',
    resultName: '',
    linkName: '',
    sectionName: '',
    suggestedTerm: '',
    sessionId: '',
    workspaceName: '',
    promptId: 'NA',
    selectedFeedbackOption: '',
    openEndedFeedback: '',
    summaryId: '',
    responseId: '',
  };

  if (!additionalAnalyticsProperties) {
    return adobeAnalyticsEvent;
  }

  if ('pageName' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.page.pageInfo.pageName =
      additionalAnalyticsProperties.pageName;
  }

  if ('previousPage' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.page.pageInfo.previousPage =
      additionalAnalyticsProperties.previousPage;
  }

  if ('documentID' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.page.pageInfo.documentID =
      additionalAnalyticsProperties.documentID ?? '';
  }

  if ('searchTerm' in additionalAnalyticsProperties) {
    const incomingSearchTerm = additionalAnalyticsProperties.searchTerm;
    const incomingPreviousSearchTerm =
      additionalAnalyticsProperties.previousTerm;

    adobeAnalyticsEvent.internal.search.term = incomingSearchTerm;

    // they want the previous search term to be the same as the current search term
    // if there is no previous search term.
    adobeAnalyticsEvent.internal.previousSearch.term =
      incomingPreviousSearchTerm || '';

    adobeAnalyticsEvent.preFilters = formatFilterValueString(
      additionalAnalyticsProperties.previousFilters,
    );
  }

  if ('sectionName' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.linkName = additionalAnalyticsProperties.linkName;
    adobeAnalyticsEvent.sectionName = additionalAnalyticsProperties.sectionName;
  }

  if ('linkName' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.linkName = additionalAnalyticsProperties.linkName;
  }

  if ('resultName' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.resultName = additionalAnalyticsProperties.resultName;
  }

  if ('suggestedTerm' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.suggestedTerm =
      additionalAnalyticsProperties.suggestedTerm;
  }

  if ('currentFilters' in additionalAnalyticsProperties) {
    let filterValueString = formatFilterValueString(
      additionalAnalyticsProperties.currentFilters,
    );

    adobeAnalyticsEvent.filterValue = filterValueString;
    adobeAnalyticsEvent.filterCategory = filterValueString;
  }

  // Search Summary Feedback additional analytics properties
  if ('openEndedFeedback' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.summaryId = additionalAnalyticsProperties.summaryId;
    adobeAnalyticsEvent.openEndedFeedback =
      additionalAnalyticsProperties.openEndedFeedback;
  }
  if ('selectedFeedbackOption' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.selectedFeedbackOption =
      additionalAnalyticsProperties.selectedFeedbackOption || '';
  }
  if ('workspaceName' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.workspaceName =
      additionalAnalyticsProperties.workspaceName ?? '';
  }

  if ('sessionId' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.sessionId =
      additionalAnalyticsProperties.sessionId ?? '';
  }

  if ('promptId' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.promptId =
      additionalAnalyticsProperties?.promptId ?? 'NA';
  }

  if ('sessionId' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.sessionId =
      additionalAnalyticsProperties.sessionId ?? '';
  }

  if ('responseId' in additionalAnalyticsProperties) {
    adobeAnalyticsEvent.responseId =
      additionalAnalyticsProperties?.responseId ?? 'NA';
  }

  return adobeAnalyticsEvent;
};

const formatFilterValueString = (filters: SearchParamFilters): string => {
  let filterValueString = '';

  for (const filterName in filters) {
    const currFilter = filters[filterName];

    let fString = '';

    for (const f of currFilter) {
      const filterName = f.name;
      const filterValues = f.filter_values.map((filterValue) => filterValue);

      fString += `"${filterName}" : "${filterValues}"`;
    }

    filterValueString += fString + ' | ';
  }

  // Remove the last pipe character
  filterValueString = filterValueString.slice(0, -3);

  return filterValueString;
};

export const getPageNameMapping = (workspaceRoutes: WorkspaceRoutes[]) => {
  const homeRoute = workspaceRoutes?.find(
    (route: WorkspaceRoutes) => route.path === '/',
  );
  const routeToPageNameMap = {
    '/': homeRoute?.label.toLowerCase() ?? '',
    '/search': 'search',
    '/chat': 'chat',
    '/management/list': 'manage/list',
    '/management/create': 'manage/create',
    '/management/details/:workspaceId': 'manage/details',
  };
  return routeToPageNameMap;
};
