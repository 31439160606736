import { ButtonProps } from 'components/Button';
import { CenteredImageWithTextProps } from 'components/CenteredImageWithText';
import { DocvizProps } from 'components/Docviz';
import { EmailProps } from 'components/Email';
import { FilePathProps } from 'components/FilePath';
import { HtmlContentProps } from 'components/HtmlContent';
import { IconProps } from 'components/Icon';
import { LabelOnTopProps } from 'components/LabelOnTop';
import { PillProps } from 'components/Pill';
import { SectionHeaderProps } from 'components/SectionHeader';
import { SummaryProps } from 'components/Summary';
import { TextLinkProps } from 'components/TextAsLink';
import { TitleProps } from 'components/Title';
import { TitleAsLinkProps } from 'components/TitleAsLink';
import {
  AvailableAgentChatOptions,
  AvailableAgentTools,
} from 'redux/AgentsChat/typings';
import {
  RetrievalFilterValues,
  RetrievalMaterialKeys,
} from 'redux/Retrieval/typings';
import { DataSourceType } from 'redux/Workspace/typings';

type Features = {
  hasSearch: boolean;
  hasChat: boolean;
};

type Search = {
  consumerId: string;
  display: {
    searchSummary: boolean;
    relatedRequestThemes: boolean;
    searchResultPreview?: boolean;
  };
  filters: Filter[];
  searchSummary: SearchSummary;
  showUniqueSearchResultsBy: ShowUniqueSearchResultsBy[];
  // allows customers to use multiple layouts
  // for their search result cards. For example,
  // KT RMS wants to show two different card layouts
  // when showing their search results.
  defaultResultCardLayoutName: string | string[];
  defaultNoneActivePreviewLayoutName: string;

  layouts: Layout[];
};

type Chat = {
  consumerId: string;
  chatSourceActions?: ChatSourceActions;
  initialQuickMessages: {
    title: string;
    text: string;
  }[];
  customEmptyChatImage: string;
  customEmptyChatHeader: string;
  customEmptyChatSubHeader: string;
  agentsFrameworkFieldNamesToUse: AgentsFrameworkFieldNamesToUse[];
  agentsAvailableTools: AvailableAgentTools[];
  agentsAvailableChatOptions: AvailableAgentChatOptions[];
  filters: Filter[];
};

type AgentsFrameworkFieldNamesToUse = {
  dataFieldsToInclude: string[];
  dataSourceTypeToApplyTo: string | 'all';
};

type ChatSourceActions = {
  url: {
    onClickAction: OnClickAction;
  };
  fileType: {
    onClickAction: OnClickAction;
  };
};

export type FilterLabelMapping = {
  [key: string]: string;
};

export type FilterTypes = 'list' | 'date' | 'boolean' | 'regex';
/**
 * A generic Filter type that applys to both the Search and Chat
 */
export type Filter = {
  id: string;
  label: string;
  filterType: FilterTypes; // 'dateRange' | 'list' | 'switch';
  sourceDataKey: string;
  filterValueToLabelMapping?: FilterLabelMapping;
  initialFilterValues?: RetrievalFilterValues[];
};

interface SearchSummaryContextFormattingOptionsRegex {
  pattern: string;
  replaceWith: string;
  fieldToApplyTo: string | 'all';
}

interface SearchSummaryContextFormattingOptions {
  dataFieldsToInclude: string[] | 'all';
  regexesToApply?: SearchSummaryContextFormattingOptionsRegex[];
  dataSourceTypeToApplyTo: string | 'all';
}

type SearchSummary = {
  prompt: string;
  maxTokensToSend: number;
  includeUserQueryInRequest: boolean;
  contextFormattingOptions: SearchSummaryContextFormattingOptions[];
};

export type WorkspaceConfigurationCustomerId = number;

// should map to the component names in the dynamic folder
export enum DynamicComponentNames {
  TitleAsLink = 'TitleAsLink',
  LabelOnTop = 'LabelOnTop',
  Summary = 'Summary',
  AttachmentIcon = 'AttachmentIcon',
  DocumentIcon = 'DocumentIcon',
  DocumentIconName = 'DocumentIconName',
  DownloadIcon = 'DownloadIcon',
  HtmlContent = 'HtmlContent',
  Pill = 'Pill',
  SectionHeader = 'SectionHeader',
  TextAslink = 'TextAslink',
  CenteredImageWithText = 'CenteredImageWithText',
  EyeIcon = 'EyeIcon',
  CloseIcon = 'CloseIcon',
  IllustrationIcon = 'IllustrationIcon',
  Divider = 'Divider',
  Title = 'Title',
  Button = 'Button',
  Docviz = 'Docviz',
  Email = 'Email',
  FilePath = 'FilePath',
}

export type Column = {
  id: string;
  size: number;
  requiredComponentRenderParams: RequiredRenderParam[];
  componentName: DynamicComponentNames;
  rows?: Row[];
  onClickAction?: OnClickAction;
};

export type RequiredRenderParam = DynamicRenderParam | StaticRenderParam;

type BaseRenderParam = {
  paramName: ParamName;
  tooltip?: TooltipConfig;
  urlParams?: UrlParamsConfig;

  // use to conditionally show the component
  // based on if its related data field is present.
  // for example, only show title if its related data field is present.
  optionalRelatedDataField?: string;
};

export type UrlParamsConfig = {
  url: string;
  urlPostFixDynamicField: string;
};

export type TooltipConfig = {
  tooltipText: string;
  tooltipOnClickText?: string;
};

export type DynamicRenderParam = BaseRenderParam & {
  paramName: ParamName;
  paramType: 'dynamic';
  paramDynamicLookupKey: RetrievalMaterialKeys;
  paramStaticValue?: never;
  paramPostfix?: string;
  paramFormat?: 'DD/MM/YYYY' | 'MMM DD,YYYY';
};

export type StaticRenderParam = BaseRenderParam & {
  paramName: ParamName;
  paramType: 'static';
  paramDynamicLookupKey?: never;
  paramStaticValue: string;
  paramPostfix?: never;
  paramFormat?: never;
};

export type ParamName =
  | keyof TitleAsLinkProps
  | keyof LabelOnTopProps
  | keyof SummaryProps
  | keyof IconProps
  | keyof PillProps
  | keyof TextLinkProps
  | keyof SectionHeaderProps
  | keyof CenteredImageWithTextProps
  | keyof HtmlContentProps
  | keyof TitleProps
  | keyof ButtonProps
  | keyof DocvizProps
  | keyof EmailProps
  | keyof FilePathProps;

export type Row = {
  id: string;
  columns: Column[];
};

export type Layout = {
  displayName: string;
  internalName: string;
  // used to filter the data sources,
  // for example, with KT RMS, we have different data sources for different layouts
  // so we can filter the data source based on the layout
  layoutDataFilter?: {
    key: string;
    value: string;
  };
  customClassNames?: string;
  rows: Row[];
  onClickAction?: OnClickAction;
};

export type OnClickActionType =
  | 'openUrl'
  | 'openPreview'
  | 'closePreview'
  | 'openPreviewWithFetch';

type FilterKeys = {
  key: string;
  value: string;
  type: string;
};

export type FetchApiQueryDataKey = {
  key: string;
  mappedName: string;
};

type MappedDetails = {
  key: string;
  dataSource: string;
  previewName: string;
};

export type LayoutDataFilter = {
  key: string;
  mappedDetails: MappedDetails[];
};

export type OnClickAction = {
  actionType: OnClickActionType;
  retreivalParamKeys?: string[];
  previewLayoutName?: string;
  extensions?: string[];
  fetchApiToUse?: 'retreivalApi' | 'slidelevelSearch'; // more API's in the future
  fetchApiQueryDataKey?: string | FetchApiQueryDataKey[]; // represents the keys in data to use as query
  fetchedResultsToBeFilteredBy?: FilterKeys[];
  layoutDataFilter?: LayoutDataFilter;
  previewName?: string;
};

export type WorkspaceConfiguration = {
  id: string;
  version?: string; // semver
  customerId: WorkspaceConfigurationCustomerId;
  workspaceName: string;
  features?: Features;
  search: Search;
  chat: Chat;
  routes: WorkspaceRoutes[];
  dataSource: string;
  status: WorkspaceStatus;
  chargeCode: string;
  updatedDate: string;
  createdDate: string;
  usersCount: number;
  dataRepository: WorkspaceConfigurationDataRepository;
  method: WorkspaceConfigurationMethod;
  workspaceId: number;
  customLogoUrl?: string;
  accessType: string;
};

export type WorkspaceStatus = 'Created' | 'In progress' | 'Failed';

export type WorkspaceConfigurationDataRepository = 'vespa' | 'elastic';

export type WorkspaceConfigurationMethod =
  | 'vector'
  | 'bm25'
  | 'hybrid_1'
  | 'hybrid_2'
  | 'nativeRank';

export type AdminWorkspaceConfiguration = {
  customerId: number;
  customerName: string;
  customerChargeCode: string;
  workspaceStatus: [number, string];
  workspaceDateCreated: string;
  totalUsers: number;
  workspaceID: number;
  dataSources: [DataSourceType];
};

export type WorkspaceRoutes = {
  id: string;
  path: string;
  element: string;
  label: string;
};

export enum CustomIconNames {
  AttachFile = 'attach_file',
  Download = 'download',
  ContentCopy = 'content_copy',
  Eye = 'eye',
  Close = 'close',
  Illustartion = 'illustration',
}

interface RecordType {
  [key: string]: string;
}

export interface ShowUniqueSearchResultsBy {
  recordType: RecordType;
  primaryKey: string;
}

export interface KnowledgeSearchResponseData {
  CurrentPage: Number;
  DocumentCount: Number;
  DocumentsPerPage: Number;
  PageCount: Number;
  TotalCount: Number;
  didYouMeans: null;
  methodresult: string;
  queryIntents: [];
  doc: DocData[];
  refiners: Refiners[];
  _links: { self: { href: string } };
}

export interface DocData {
  [key: string]: any | any[];
}

type Refiners = {
  parameterName: string;
  refinerType: string;
  refinerValues: any;
};
