import Icon, { IconSize } from 'components/Icon';
import React, { ChangeEvent, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  FieldError,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { DataSource, SourceData, SQLDataSource } from 'redux/Workspace/typings';
import type { SharePointForm } from 'redux/Workspace/typings';
import { FeatureFlags, isFeatureEnabled } from 'utils/featureflags';

// below line will be enabled once file upload is implemented
// import { calculateTotalFileSize } from 'utils/workspace';

//  props description
//  addNewDataSource - triggers when data source is selected in last row to add new row to dataSources state for showing add another data source in next row
//  sourceDetails - details for selected data source row
//  onClickDataSource - triggers when clicked on data source / update data source selection
//  deleteSourceDetails -triggers when delete icon is clicked to remove data source row from state
//  index - current row index
//  allDataSources - holds entire data sources data
//  showDataSources - triggers when clicked on add another data source button to shows data sources

type SharePointUrlValidation = { errorType: string; sourceId: number };

export interface DataSourcesProps {
  addNewDataSource: () => void;
  sourceDetails: DataSource;
  onClickDataSource: (
    source: string,
    data: string | SQLDataSource | SharePointForm,
    id: number,
  ) => void;
  deleteSourceDetails: (id: number) => void;
  index: number;
  allDataSources: DataSource[];
  showDataSource: (id: number) => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  sharePointUrlsValidationData: SharePointUrlValidation[];
}

const DataSources: React.FC<DataSourcesProps> = ({
  addNewDataSource,
  sourceDetails,
  onClickDataSource,
  deleteSourceDetails,
  index,
  allDataSources,
  showDataSource,
  register,
  errors,
  sharePointUrlsValidationData,
}) => {
  const [showSharePointInput, setShowSharePointInput] =
    useState<boolean>(false);
  const [showSQLInput, setShowSQLInput] = useState<boolean>(false);
  // below code will be enabled once file upload is implemented
  //const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const selectedDataSource = (sourceDetails?.sourceData as SourceData)
    ?.addedSourceName;
  const [sharePointForm, setSharePointForm] = useState<SharePointForm>({
    sharePointUrl: '',
    dbUsername: '',
    dbTableName: '',
    dbConnection: '',
    dbPassword: '',
    externalMetadata: false,
    enableVision: false,
  });

  const initialSQLDataSource: SQLDataSource = {
    dbConnectionString: '',
    dbUsername: '',
    dbPassword: '',
    dbTableName: '',
    enableVision: false,
  };
  const [sqlConnectorData, setSqlConnectorData] =
    useState<SQLDataSource>(initialSQLDataSource);
  const isSpoExternalMetadataEnabled: boolean = isFeatureEnabled(
    FeatureFlags.SharepointExternalMetadata,
  );

  // below code will be enabled once file upload is implemented
  // const handleLabelClick = (event: MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault();
  //   onClickDataSource(
  //     'fileupload',
  //     'dummy file upload data', //needs to be updated when file upload is implemented
  //     sourceDetails.id,
  //   );
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };

  // const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files) {
  //     const files: File[] = Array.from(event.target.files);
  //     setSelectedFiles(files);
  //   }
  // };

  // const onClickFileUploadDataSource = (e: MouseEvent<HTMLButtonElement>) => {
  //   handleLabelClick(e);
  //   setShowSharePointInput(false);
  //   setSharePointUrl('');
  //   addNewRowToShowAddButton();
  // };

  const addNewRowToShowAddButton = () => {
    // create new row object to show add button in next row via newly created object
    if (allDataSources.length - 1 === index) {
      addNewDataSource();
    }
  };

  const onClickSharePointDataSource = () => {
    setShowSharePointInput(true);
    setShowSQLInput(false);
    setSelectedFiles([]);
    onClickDataSource('sharepoint', sharePointForm, sourceDetails.id);
    setSqlConnectorData(initialSQLDataSource);
    addNewRowToShowAddButton();
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    let isCheckbox = false;
    const { type, name, value, checked } = event.target;
    if (type === 'checkbox') {
      isCheckbox = true;
    }
    const trimmedName = `${name}`?.split('-')[0]; // Extracting the portion after the hyphen for eg `sharePointUrl-${sourceDetails.id}`
    setSharePointForm((prev) => {
      const newState = {
        ...prev,
        [trimmedName]: isCheckbox ? checked : value,
      };

      onClickDataSource('sharepoint', newState, sourceDetails.id);
      return newState;
    });
  };

  const renderTooltip = (props: any) => {
    return (
      <Tooltip id={`sql-tooltip`} {...props}>
        For SQL data, please contact Voyager support at voyager@bcg.com
      </Tooltip>
    );
  };

  const validateSpUrlError = (errorType: string, sourceId: number) => {
    return sharePointUrlsValidationData?.find(
      (item: SharePointUrlValidation) =>
        item.sourceId === sourceId && item.errorType === errorType,
    );
  };

  const renderSharePointForm = () => {
    return (
      <div className='mt-3'>
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <label
                htmlFor={`sharePointUrl-${sourceDetails.id}`}
                className='form-label'
              >
                Paste your URL
              </label>
              <div className='col-md-6'>
                <input
                  type='text'
                  className={`form-control ${
                    errors[`sharePointUrl-${sourceDetails.id}`]
                      ? 'is-invalid'
                      : ''
                  }`}
                  id={`sharePointUrl-${sourceDetails.id}`}
                  data-testid={`sharePointUrl-${sourceDetails.id}`}
                  placeholder='URL of the content'
                  value={sharePointForm.sharePointUrl}
                  {...register(`sharePointUrl-${sourceDetails.id}`, {
                    validate: () => {
                      // Custom validation to check for duplicate URLs
                      if (validateSpUrlError('duplicate', sourceDetails.id)) {
                        return 'A SharePoint data source with this URL already exists in this workspace.';
                      }
                      // Custom validation to check for invalid URLs
                      if (validateSpUrlError('invalidUrl', sourceDetails.id)) {
                        return 'Please include Site name and Root URL path.';
                      }
                    },
                  })}
                  onChange={handleFieldChange}
                />
                {errors[`sharePointUrl-${sourceDetails.id}`] && (
                  <span className='text-danger my-1'>
                    {
                      (
                        errors[
                          `sharePointUrl-${sourceDetails.id}`
                        ] as FieldError
                      )?.message
                    }
                  </span>
                )}
              </div>
            </div>
            {isSpoExternalMetadataEnabled && (
              <div className='form-check mt-3'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={`externalMetadata-${sourceDetails.id}`}
                  name='externalMetadata'
                  onChange={handleFieldChange}
                  checked={sharePointForm.externalMetadata}
                />
                <label
                  className='form-check-label'
                  htmlFor={`externalMetadata-${sourceDetails.id}`}
                >
                  Connect SP Data to External Metadata
                </label>
              </div>
            )}
            <div className='form-check mt-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id={`enableVision-${sourceDetails.id}`}
                name='enableVision'
                onChange={handleFieldChange}
                checked={sharePointForm.enableVision}
              />
              <label
                className='form-check-label'
                htmlFor={`enableVision-${sourceDetails.id}`}
              >
                Enable Vision
              </label>
            </div>
          </div>
        </div>

        {isSpoExternalMetadataEnabled && sharePointForm.externalMetadata && (
          <div className='row mt-3'>
            <div className='col-md-6'>
              <div className='row'>
                <label
                  htmlFor={`dbUsername-${sourceDetails.id}`}
                  className='form-label'
                >
                  Username
                </label>
                <div className='col'>
                  <input
                    type='text'
                    className='form-control'
                    id={`dbUsername-${sourceDetails.id}`}
                    name='dbUsername'
                    data-testid='dbUsername'
                    placeholder='Username'
                    value={sharePointForm.dbUsername}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <label
                  htmlFor={`dbTableName-${sourceDetails.id}`}
                  className='form-label'
                >
                  Table Name
                </label>
                <div className='col'>
                  <input
                    type='text'
                    className='form-control'
                    id={`dbTableName-${sourceDetails.id}`}
                    name='dbTableName'
                    data-testid='dbTableName'
                    placeholder='Table Name'
                    value={sharePointForm.dbTableName}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='row'>
                <label
                  htmlFor={`dbConnection-${sourceDetails.id}`}
                  className='form-label'
                >
                  DB Connection Information
                </label>
                <div className='col'>
                  <input
                    type='text'
                    className='form-control'
                    id={`dbConnection-${sourceDetails.id}`}
                    name='dbConnection'
                    data-testid='dbConnection'
                    placeholder='DB Connection Information'
                    value={sharePointForm.dbConnection}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <label
                  htmlFor={`dbPassword-${sourceDetails.id}`}
                  className='form-label'
                >
                  Password
                </label>
                <div className='col'>
                  <input
                    type='password'
                    className='form-control'
                    id={`dbPassword-${sourceDetails.id}`}
                    name='dbPassword'
                    data-testid='dbPassword'
                    placeholder='Password'
                    value={sharePointForm.dbPassword}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  // const onClickSQLDataSource = () => {
  //   setShowSharePointInput(false);
  //   setSelectedFiles([]);
  //   setShowSQLInput(true);
  //   onClickDataSource('sql', initialSQLDataSource, sourceDetails.id);
  //   addNewRowToShowAddButton();
  // };

  const handleSqlConnectorDataChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    let isCheckbox = false;
    const { type, checked } = e.target;
    if (type === 'checkbox') {
      isCheckbox = true;
    }

    const data = {
      ...sqlConnectorData,
      [field]: isCheckbox ? checked : e.target.value,
    };
    setSqlConnectorData(data);
    onClickDataSource('sql', data, sourceDetails.id);
  };

  return (
    <div className='container'>
      {index > 0 && <hr />}
      <div className='row mt-2'>
        {/* add and delete datasource button */}
        {index > 0 && (
          <div
            className={`col-auto mb-4 d-flex mt-${
              selectedFiles.length > 0 ? 2 : 4
            }`}
          >
            <div className='d-flex align-items-center'>
              <button
                type='button'
                className='text-white btn btn-primary rounded d-flex gap-2 align-items-center'
                onClick={() => showDataSource(sourceDetails.id)} // show data sources when add is clicked
                disabled={sourceDetails.showDataSources}
              >
                <Icon name='add_circle_outline' size={IconSize.md} />
                Add Another Data Source
              </button>
              {(sourceDetails.sourceData as SourceData).addedSourceName && (
                <Icon
                  name='delete_outline'
                  size={IconSize.md}
                  onClick={() => {
                    deleteSourceDetails(sourceDetails.id);
                  }}
                />
              )}
            </div>
          </div>
        )}

        {/* datasources */}
        {sourceDetails.showDataSources && (
          <div className='col-auto'>
            <div className='row'>
              <div className='col'>
                <label className='form-label'>Choose your datasource</label>
              </div>
            </div>
            <div className='row'>
              {/*  below code will be enabled once file upload is implemented */}
              {/* <div className='col-auto'>
                <button
                  type='button'
                  className={`text-white btn btn-primary rounded d-flex gap-2 w-100 justify-content-center
                 ${
                   selectedDataSource === 'fileupload'
                     ? 'border border-4 border-grey'
                     : ''
                 }`}
                  onClick={onClickFileUploadDataSource}
                >
                  <Icon name='upload' size={IconSize.md} />
                  Upload file
                </button>
                <input
                  ref={fileInputRef}
                  multiple
                  accept='.pdf, .ppt, .pptx, .doc, .docx'
                  type='file'
                  id={`fileUpload-${sourceDetails.id}`}
                  className='form-control d-none'
                  onChange={handleFileSelect}
                />
                {selectedFiles.length > 0 && (
                  <p>{`${selectedFiles.length} ${
                    selectedFiles.length === 1 ? 'file' : 'files'
                  }, ${calculateTotalFileSize(selectedFiles)} `}</p>
                )}
              </div> */}
              <div className='col-12 mb-3 mb-md-0 col-md'>
                <button
                  type='button'
                  className={`text-white btn btn-primary rounded d-flex gap-2 w-100 justify-content-center
                  ${
                    selectedDataSource === 'sharepoint'
                      ? 'border border-4 border-grey'
                      : ''
                  }`}
                  onClick={onClickSharePointDataSource}
                >
                  <Icon name='sharepoint' size={IconSize.md} />
                  SharePoint
                </button>
              </div>
              <div className='col-12 mb-3 mb-md-0 col-md'>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <button
                    type='button'
                    className={`text-white btn btn-primary rounded d-flex gap-2 w-100 justify-content-center
                    ${
                      selectedDataSource === 'sql'
                        ? 'border border-4 border-grey'
                        : ''
                    }`}
                    // onClick={onClickSQLDataSource}
                  >
                    <Icon name='sql' size={IconSize.md} />
                    SQL
                  </button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* sharepoint input field */}
      {showSharePointInput && renderSharePointForm()}

      {showSQLInput && (
        <>
          <div className='row my-4'>
            <label
              htmlFor={`dbConnectionString-${sourceDetails.id}`}
              className='form-label'
            >
              Enter your SQL database connection string
            </label>
            <div className='col-12 col-md-9 mb-3'>
              <input
                type='text'
                className='form-control'
                id={`dbConnectionString-${sourceDetails.id}`}
                data-testid={`db-connection-string-${sourceDetails.id}`}
                placeholder='Connection String of the SQL Database'
                value={sqlConnectorData.dbConnectionString}
                onChange={(e) =>
                  handleSqlConnectorDataChange(e, 'dbConnectionString')
                }
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-12 col-md-3 mb-3'>
              <label htmlFor='dbUsername' className='form-label'>
                Enter your SQL database username
              </label>
              <div>
                <input
                  type='text'
                  className='form-control'
                  id={`dbUsername-${sourceDetails.id}`}
                  data-testid={`db-username-${sourceDetails.id}`}
                  placeholder='Username of the SQL Database'
                  value={sqlConnectorData.dbUsername}
                  onChange={(e) =>
                    handleSqlConnectorDataChange(e, 'dbUsername')
                  }
                />
              </div>
            </div>

            <div className='col-12 col-md-3 mb-3'>
              <label htmlFor='dbPassword' className='form-label'>
                Enter your SQL database password
              </label>
              <div>
                <input
                  type='password'
                  className='form-control'
                  id={`dbPassword-${sourceDetails.id}`}
                  data-testid={`db-password-${sourceDetails.id}`}
                  placeholder='Password of the SQL Database'
                  value={sqlConnectorData.dbPassword}
                  onChange={(e) =>
                    handleSqlConnectorDataChange(e, 'dbPassword')
                  }
                />
              </div>
            </div>

            <div className='col-12 col-md-3 mb-3'>
              <label htmlFor='dbTableName' className='form-label'>
                Enter your SQL database table name
              </label>
              <div>
                <input
                  type='text'
                  className='form-control'
                  id={`dbTableName-${sourceDetails.id}`}
                  data-testid={`db-table-name-${sourceDetails.id}`}
                  placeholder='Table Name in the SQL Database'
                  value={sqlConnectorData.dbTableName}
                  onChange={(e) =>
                    handleSqlConnectorDataChange(e, 'dbTableName')
                  }
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-3 mb-3'>
              <label htmlFor='dbTableName' className='form-label'>
                Enable Vision
              </label>
              <div>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={`enableVision-${sourceDetails.id}`}
                  name='enableVision'
                  checked={sqlConnectorData.enableVision}
                  onChange={(e) =>
                    handleSqlConnectorDataChange(e, 'enableVision')
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataSources;
