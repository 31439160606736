import Pill from 'components/Pill';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const PillDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);
  if (!props.field) {
    console.warn(`PillDynamicWrapper: field is missing. field: ${props.field}`);
    return null;
  }

  const getOnClickUrl = () => {
    const urlParamsData = requiredRenderParams.find((param) => param.urlParams)
      ?.urlParams;
    if (urlParamsData) {
      return `${urlParamsData?.url}${
        retrievalMaterialData[urlParamsData.urlPostFixDynamicField]
      }`;
    }
    return '';
  };

  return (
    <Pill
      field={props.field}
      url={props.url}
      onClickUrl={getOnClickUrl()}
      showCopyIcon={props.icon ? true : false}
      tooltip={requiredRenderParams.find((param) => param.tooltip)?.tooltip}
    />
  );
};

export default PillDynamicWrapper;
