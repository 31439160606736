import {
  SearchBarWithContext,
  SearchSubject,
} from '@kdshared/search-typeahead';
import Icon, { IconSize } from 'components/Icon';
import DynamicFormModal from 'components/Modal';
import Select from 'components/Select';
import { getUserHrid } from 'oktaConfig';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Image, InputGroup, ProgressBar, Table } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  addUserToWorkspace,
  bulkAddUsersToWorkspace,
  bulkUpdateUserRoles,
  fetchWorkspaceApiAndPeopleApiUsers,
  removeUserFromWorkspace,
} from 'redux/User/api';
import {
  addOrUpdateWorkspaceApiUserInState,
  removePeopleAPIUserFromState,
  removeWorkspaceUserFromState,
  resetBulkAddUsersStates,
  selectAddUserToWorkspaceStatus,
  selectBulkAddUsersFailedUserIds,
  selectBulkUpdateUserRolesStatus,
  selectBulkUserAddedCompletionPercentage,
  selectFetchWorkspaceApiAndPeopleApiUsersStatus,
  selectMergedWorkspaceAPIAndPeopleAPIUsers,
  selectRemoveUserFromWorkspaceStatus,
  selectRoles,
} from 'redux/User/slice';
import {
  MergedWorkspaceAPIAndPeopleAPIUser,
  SelectedRoles,
  UserRole,
} from 'redux/User/typings';
import {
  selectAvailableWorkspaceConfigurations,
  setShouldForceRefreshAvailableWorkspaceConfigurations,
} from 'redux/Workspace/slice';
import 'semantic-ui-css/semantic.min.css';
import { FeatureFlags, isFeatureEnabled } from 'utils/featureflags';

import './index.scss';

const UserListPageContent = () => {
  const navigate = useNavigate();
  const roles = useAppSelector(selectRoles);
  const users = useAppSelector(selectMergedWorkspaceAPIAndPeopleAPIUsers);
  const addUserToWorkspaceStatus = useAppSelector(
    selectAddUserToWorkspaceStatus,
  );
  const removeUserFromWorkspaceStatus = useAppSelector(
    selectRemoveUserFromWorkspaceStatus,
  );
  const fetchWorkspaceApiAndPeopleApiUsersStatus = useAppSelector(
    selectFetchWorkspaceApiAndPeopleApiUsersStatus,
  );
  const availableWorkspaces = useAppSelector(
    selectAvailableWorkspaceConfigurations,
  );
  const bulkUserRolesUpdatedStatus = useAppSelector(
    selectBulkUpdateUserRolesStatus,
  );
  const bulkAddUsersFailedUserIds = useAppSelector(
    selectBulkAddUsersFailedUserIds,
  );
  const bulkUserAddedCompletionPercentage = useAppSelector(
    selectBulkUserAddedCompletionPercentage,
  );

  const dispatch = useAppDispatch();
  const { customerId } = useParams<{ customerId: string }>();

  const selectedWorkspaceConfig = availableWorkspaces?.find(
    (wrkspc) => wrkspc.customerId === Number(customerId),
  );
  const customerName = selectedWorkspaceConfig?.workspaceName;

  const [commaSeparatedEmails, setCommaSeparatedEmails] = useState('');
  const [selectedBulkAddRole, setSelectedBulkAddRole] =
    useState<UserRole>('user');
  const [showBulkAddUsersModal, setShowBulkAddUsersModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [revokeUserId, setRevokeUserId] = useState(0);
  const [validated, setValidated] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<
    MergedWorkspaceAPIAndPeopleAPIUser[]
  >([]);
  const [formData, setFormData] = useState({
    userId: 0,
    role: '' as UserRole,
  });
  const [selectedRoles, setSelectedRoles] = useState<SelectedRoles[]>([]);
  const [activeUserId, setActiveUserId] = useState<string>('');

  const selectedWorkspaceStatus: string = selectedWorkspaceConfig?.status || '';

  const tableHeadings = ['Name', 'E-mail', 'Revoke Access', 'Role'];

  const isString = (value: any): value is string => typeof value === 'string';

  const formRef = useRef<HTMLFormElement>(null);
  const searchTypeAheadRef = useRef<HTMLDivElement>(null);
  const isAddUserStatusLoading = addUserToWorkspaceStatus === 'loading';
  const isRemoveUserStatusLoading = removeUserFromWorkspaceStatus === 'loading';

  const isFetchUserStatusLoading =
    fetchWorkspaceApiAndPeopleApiUsersStatus === 'loading' ||
    bulkUserRolesUpdatedStatus === 'loading' ||
    isRemoveUserStatusLoading;

  const getWorkspaceCustomersUsers = useCallback(() => {
    if (customerId) {
      dispatch(fetchWorkspaceApiAndPeopleApiUsers(parseInt(customerId)));
    }
  }, [customerId, dispatch]);

  useEffect(() => {
    if (!customerId || !getWorkspaceCustomersUsers) {
      return;
    }
    getWorkspaceCustomersUsers();
  }, [customerId, getWorkspaceCustomersUsers]);

  useEffect(() => {
    const filterdUsers = users.filter(
      (user: MergedWorkspaceAPIAndPeopleAPIUser) =>
        Object.values(user as MergedWorkspaceAPIAndPeopleAPIUser).some(
          (value) =>
            isString(value) &&
            value.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
    );
    setFilteredUsers(filterdUsers);
  }, [searchTerm, users]);

  useEffect(() => {
    if (
      getWorkspaceCustomersUsers &&
      bulkUserRolesUpdatedStatus === 'succeeded'
    ) {
      getWorkspaceCustomersUsers();
      setSelectedRoles([]);
    }
  }, [bulkUserRolesUpdatedStatus, getWorkspaceCustomersUsers]);

  useEffect(() => {
    const fetchData = async () => {
      const userHrid = await getUserHrid();
      setActiveUserId(userHrid.toString());
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (bulkUserAddedCompletionPercentage === 100 && customerId) {
      setShowBulkAddUsersModal(false);
      dispatch(setShouldForceRefreshAvailableWorkspaceConfigurations(true));
      dispatch(fetchWorkspaceApiAndPeopleApiUsers(parseInt(customerId)));
      dispatch(resetBulkAddUsersStates());
      setCommaSeparatedEmails('');
      setSelectedBulkAddRole('user');
    }
  }, [bulkUserAddedCompletionPercentage]);

  useEffect(() => {
    if (bulkAddUsersFailedUserIds.length > 0) {
      const failedUsers = bulkAddUsersFailedUserIds.join(', ');
      console.error(
        `There was an issue adding ${bulkAddUsersFailedUserIds.length} user${bulkAddUsersFailedUserIds.length > 1 ? 's' : ''} to the workspace. Please check the following user ids: ${failedUsers}`,
      );
    }
  }, [bulkAddUsersFailedUserIds]);

  const getSelectedUserRole = (userId: number) => {
    if (!selectedRoles.length) return '';
    const selectedRole = selectedRoles.find(
      (role: SelectedRoles) => role.user_id === userId,
    );
    return selectedRole ? (selectedRole.is_cust_admin ? 'admin' : 'user') : '';
  };

  const handleFieldChange = useCallback(
    (
      id: string,
      value: string,
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement
      > | null = null,
    ) => {
      let fieldValue: number | string = value;
      if (id === 'userId') {
        const foundUser = filteredUsers.find(
          (user) => user.userId === parseInt(value),
        );
        if (foundUser) {
          event?.target.setCustomValidity('User already exists in workspace');
        } else {
          event?.target.setCustomValidity('');
        }

        fieldValue = parseInt(value);
      }

      setFormData((prevData) => ({ ...prevData, [id]: fieldValue }));
    },
    [filteredUsers],
  );

  const handleRoleChange = useCallback(
    (userId: number, role: string) => {
      setSelectedRoles((prevItems) => {
        const updatedItems = [...prevItems];
        const index = updatedItems.findIndex((item) => item.user_id === userId);

        // check if user role update exists in state
        if (index !== -1) {
          const updatedItem = {
            ...updatedItems[index],
            is_cust_admin: role === 'admin',
            isRoleUpdated: true,
          };
          updatedItems[index] = updatedItem;

          // Check if the role is the same after the update
          const userIndex = users.findIndex(
            (item: MergedWorkspaceAPIAndPeopleAPIUser) =>
              item.userId === userId &&
              item.isCustomerAdmin === updatedItem.is_cust_admin,
          );
          // Remove from state if role is the same after update
          if (userIndex !== -1) {
            updatedItems.splice(index, 1);
          }
        } else {
          // If not found, add a new entry
          updatedItems.push({
            user_id: userId,
            is_cust_admin: role === 'admin',
            isRoleUpdated: true,
          });
        }
        return updatedItems;
      });
    },
    [users],
  );

  const handleSelectChange = useCallback(
    (id: string, value: string) => {
      handleRoleChange(formData.userId, value);
      handleFieldChange(id, value);
    },
    [formData.userId, handleRoleChange, handleFieldChange],
  );

  const checkSearchTypeahedInputValue = () => {
    // ref used to check typeahead search value
    if (searchTypeAheadRef.current) {
      const inputField =
        searchTypeAheadRef.current.querySelector<HTMLInputElement>(
          'input[type="text"][placeholder="Search directory"]',
        );
      if (inputField && !inputField.value.trim()) {
        setFormData((prevData) => ({
          ...prevData,
          userId: 0,
        }));
      }
    }
  };

  useEffect(() => {
    const subscription = SearchSubject.subscribe({
      next: (props: any) => {
        if (props?.data?.typeAheadSuggestion) {
          handleFieldChange(
            'userId',
            props?.data?.typeAheadSuggestion?.id,
            null,
          );
        }
        if (props.type === 'focused' || props.type === 'blurred') {
          checkSearchTypeahedInputValue();
        }
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [handleFieldChange]);

  const handleAddUserToWorkspace = () => {
    setValidated(false);
    setShowAddUserModal(true);
  };

  const validateAndSubmit = () => {
    const form = formRef.current!;

    if (form.checkValidity() === false) {
      setValidated(true);
      form.reportValidity();
      return false;
    }
    return true;
  };

  const handleSaveUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!validateAndSubmit()) {
      console.error('Unable to add user to customer. Form validation failed.');
      return;
    }

    if (!selectedWorkspaceConfig) {
      console.error('Unable to add user to customer. No workspace selected.');
      return;
    }

    closeUserAddModal();

    await dispatch(addUserToWorkspace([selectedWorkspaceConfig, formData]));
    dispatch(setShouldForceRefreshAvailableWorkspaceConfigurations(true));
    const workspaceUserData = {
      isCustomerAdmin: formData.role === 'admin',
      userId: formData.userId,
      customerId: selectedWorkspaceConfig.customerId,
      role: formData.role,
    };

    dispatch(addOrUpdateWorkspaceApiUserInState(workspaceUserData));

    await dispatch(
      fetchWorkspaceApiAndPeopleApiUsers(selectedWorkspaceConfig.customerId),
    );

    setSelectedRoles([]);
  };

  const userRemoveFromWorkspace = async (userId: number) => {
    setRevokeUserId(userId);
    setShowConfirmationModal(false);

    if (isRemoveUserStatusLoading) {
      return;
    }

    if (!selectedWorkspaceConfig) {
      console.error(
        'Unable to remove user from workspace. No workspace selected.',
      );
      return;
    }
    await dispatch(removeUserFromWorkspace([selectedWorkspaceConfig, userId]));
    setRevokeUserId(0);
    dispatch(setShouldForceRefreshAvailableWorkspaceConfigurations(true));
    dispatch(removeWorkspaceUserFromState(userId.toString()));
    dispatch(removePeopleAPIUserFromState(userId.toString()));
    setFilteredUsers((prevUsers) => {
      return prevUsers.filter((user) => user.userId !== userId);
    });
    setSelectedRoles([]);
  };

  const getformatRoles = () => {
    return roles.map((role) => ({
      value: role,
      label: role,
    }));
  };

  const closeUserAddModal = () => {
    setShowAddUserModal(false);
    setValidated(false);
    setFormData({
      userId: 0,
      role: '' as UserRole,
    });
  };

  const getInitials = (name: string) => {
    const nameParts = name.split(' ');
    const firstInitial = nameParts[0]?.charAt(0).toUpperCase() || '';
    const lastInitial =
      nameParts.length > 1
        ? nameParts[nameParts.length - 1].charAt(0).toUpperCase()
        : '';
    return firstInitial + lastInitial || 'NA';
  };

  const saveUserRolesUpdates = () => {
    const updatedRoles = selectedRoles?.map((userRoles: SelectedRoles) => ({
      user_id: userRoles.user_id,
      is_cust_admin: userRoles.is_cust_admin,
    }));
    const userRolesUpdatedObj = {
      customer_id: customerId || '',
      updatedRoles,
    };
    dispatch(bulkUpdateUserRoles(userRolesUpdatedObj));
  };

  const renderAddUserToWorkspaceModalContent = () => {
    return (
      <Form
        noValidate
        validated={validated}
        ref={formRef}
        onSubmit={(e) => e.preventDefault()}
      >
        <div className='mb-4 searchBar' ref={searchTypeAheadRef}>
          <SearchBarWithContext
            defaultSearchScope='directory'
            enabledScopes='directory'
            autofillOnHoverSuggestion={false}
            expanded={true}
            peopleApiVersion='v2'
            allowNavigation={false}
            resultsTopPx={50}
            customApiParams={{
              directory: {
                api_url: `${process.env.REACT_APP_PEOPLE_API_BASE_URL}/domain/v2/people`,
                api_key: process.env.REACT_APP_PEOPLE_X_API_KEY,
              },
            }}
            environment={
              process.env.REACT_APP_ENVIRONMENT === 'local'
                ? 'dev'
                : process.env.REACT_APP_ENVIRONMENT
            }
          />
        </div>
        <Form.Group className='mb-4' controlId='userForm.SelectInput'>
          <Select
            options={getformatRoles()}
            value={formData.role || ''}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleSelectChange('role', e.target.value)
            }
            placeholder='Select user role'
          />
        </Form.Group>
      </Form>
    );
  };

  const handleBulkAddUsersToWorkspace = () => {
    if (!selectedWorkspaceConfig) {
      console.error('Unable to add users to workspace. No workspace selected.');
      return;
    }

    dispatch(
      bulkAddUsersToWorkspace([
        selectedWorkspaceConfig,
        {
          role: selectedBulkAddRole,
          userEmails: commaSeparatedEmails
            .split(',')
            .map((email) => email.trim()),
        },
      ]),
    );
  };

  const renderBulkAddUsersToWorkspaceModalContent = () => {
    return (
      <Form onSubmit={(e) => e.preventDefault()}>
        <div className='mb-4 searchBar'>
          <div className='mb-3'>
            <label htmlFor='comma-separated-emails' className='form-label'>
              Comma separated list of emails
            </label>
            <div className='input-group'>
              <textarea
                className='form-control'
                aria-label='User emails'
                onChange={(e) => setCommaSeparatedEmails(e.target.value)}
              ></textarea>
            </div>
            <div className='form-text'>Example: foo@bcg.com,bar@bcg.com</div>
          </div>
        </div>

        <Form.Group className='mb-4' controlId='userForm.SelectInput'>
          <Select
            options={getformatRoles()}
            value={selectedBulkAddRole}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setSelectedBulkAddRole(e.target.value as UserRole)
            }
            placeholder='Select user role'
          />
        </Form.Group>

        {bulkUserAddedCompletionPercentage > 0 && (
          <div className='mb-4'>
            <p className='mb-2'>Bulk user addition in progress...</p>
            <ProgressBar
              now={bulkUserAddedCompletionPercentage}
              animated
              variant='primary'
            />
          </div>
        )}
      </Form>
    );
  };

  const getColumnWidthClass = (index: number) => {
    switch (index) {
      case 0:
        return 'firstColumn';
      case 1:
        return 'secondColumn';
      case 2:
        return 'thirdColumn';
      default:
        return 'defaultColumn';
    }
  };

  const isActiveUser = (userId: string) => {
    return activeUserId === userId;
  };

  const disableActionOnLoadingData = (userId: string) => {
    if (isActiveUser(userId)) return true;
    return isRemoveUserStatusLoading || isAddUserStatusLoading;
  };

  const revokeAccessConfirmationModal = (userId: number) => {
    if (isActiveUser(userId.toString())) return true;
    setRevokeUserId(userId);
    setShowConfirmationModal(true);
  };

  const renderUserTable = () => {
    return (
      <div className='mb-2 overflow-auto customTable'>
        <Table className='tableWrapper'>
          <thead>
            <tr>
              {tableHeadings.map((heading, index) => (
                <th
                  className={`fw-medium ${
                    heading === 'Role' ? 'text-center ps-4' : 'text-start'
                  } ${getColumnWidthClass(index)}`}
                  key={index}
                >
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='overflow-auto customTableBody'>
            {fetchWorkspaceApiAndPeopleApiUsersStatus === 'failed' ? (
              <tr>
                <td
                  colSpan={tableHeadings.length}
                  style={{ textAlign: 'center' }}
                  className={`border-0`}
                >
                  Something went wrong. Please try again later!
                </td>
              </tr>
            ) : null}
            {isFetchUserStatusLoading ? (
              <tr>
                <td colSpan={tableHeadings.length}>
                  <SkeletonTheme baseColor='#f2f2f2' highlightColor='#ccc'>
                    <Skeleton
                      count={4}
                      style={{ marginBottom: '1rem', width: '100%' }}
                      height={30}
                    />
                  </SkeletonTheme>
                </td>
              </tr>
            ) : null}
            {!isFetchUserStatusLoading &&
              filteredUsers?.map(
                (user: MergedWorkspaceAPIAndPeopleAPIUser, index) => {
                  return (
                    <tr className='align-middle' key={index}>
                      <td>
                        <span className='d-inline-block'>
                          {user?.picture ? (
                            <Image
                              src={user.picture}
                              alt={`${user.firstName}'s Image`}
                              className='circle me-2'
                            />
                          ) : (
                            <div
                              className={`rounded-circle d-flex align-items-center justify-content-center text-white me-2 defaultAvatar user-${
                                index + 1
                              }`}
                            >
                              {getInitials(
                                user.firstName + ' ' + user.lastName,
                              )}
                            </div>
                          )}
                        </span>
                        <span className='d-inline-block'>
                          {user.firstName + ' ' + user.lastName || user.userId}
                        </span>
                      </td>
                      <td>{user.email}</td>
                      <td
                        className={`ps-5 ${
                          isActiveUser(user.userId?.toString())
                            ? ''
                            : 'cursorPointer'
                        }`}
                      >
                        <Icon
                          name={
                            isActiveUser(user.userId?.toString())
                              ? 'delete_outline_gray'
                              : 'delete_outline'
                          }
                          size={IconSize.md}
                          onClick={() =>
                            revokeAccessConfirmationModal(user.userId)
                          }
                        />
                      </td>
                      <td
                        className={`ps-0 userRoleSelect ${
                          disableActionOnLoadingData(user.userId?.toString())
                            ? 'hideCaret'
                            : ''
                        }`}
                      >
                        <Select
                          options={getformatRoles()}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            handleRoleChange(user.userId, e.target.value)
                          }
                          value={getSelectedUserRole(user.userId) || user.role}
                          disabled={disableActionOnLoadingData(
                            user.userId?.toString(),
                          )}
                          width='w-50'
                        />
                      </td>
                    </tr>
                  );
                },
              )}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderConfirmationModal = (userId: number) => {
    return (
      <DynamicFormModal
        title={`Remove user ${userId} from workspace`}
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        onSave={() => userRemoveFromWorkspace(userId)}
        buttonLabel='Remove'
        children={
          <div>
            <p>Are you sure you want to remove this user from the workspace?</p>
          </div>
        }
      />
    );
  };

  const isBulkAddUsersEnabled = isFeatureEnabled(FeatureFlags.BulkAddUsers);

  const hasValidCommaseparatedEmails = (emails: string) => {
    const emailList = emails.split(',');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailList.every((email) => emailRegex.test(email.trim()));
  };

  return (
    <>
      {selectedWorkspaceStatus !== 'Deleted' ? (
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-12'>
              <h2>User management</h2>
            </div>
          </div>
          <div className='row mt-2 justify-content-end me-1 me-md-0'>
            <div className='col-2 text-end'>
              <button
                onClick={() => navigate(-1)}
                type='button'
                className='text-white btn btn-primary rounded'
                data-testid='back-button'
              >
                <Icon name='left_arrow_white' size={IconSize.md} />
                Back
              </button>
            </div>

            <div className='row mt-5 mb-4'>
              <div className='col-12'>
                <div className='d-flex justify-content-between'>
                  <h5>{customerName}</h5>
                  <div className='d-flex ms-5 justify-content-end'>
                    <InputGroup className='w-auto'>
                      <Form.Control
                        type='text'
                        placeholder='Search user'
                        className='border-end-0'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        disabled={disableActionOnLoadingData('')}
                      />
                      <InputGroup.Text
                        className='bg-transparent cursor-pointer rounded-end'
                        id='basic-addon1'
                      >
                        <Icon name='search_dark' size={IconSize.sm} />
                      </InputGroup.Text>
                    </InputGroup>

                    <div className='d-flex justify-content-center ms-3'>
                      {isBulkAddUsersEnabled && (
                        <button
                          onClick={() => setShowBulkAddUsersModal(true)}
                          type='button'
                          className='text-white btn btn-primary rounded me-3'
                          data-testid='add-new-user-button'
                        >
                          <Icon
                            name='add_black'
                            className='me-1'
                            size={IconSize.md}
                          />
                          Bulk add users
                        </button>
                      )}
                      <button
                        onClick={handleAddUserToWorkspace}
                        type='button'
                        className='text-white btn btn-primary rounded'
                        disabled={disableActionOnLoadingData('')}
                        data-testid='add-new-user-button'
                      >
                        {isAddUserStatusLoading ? (
                          <span
                            className='spinner-border spinner-border-sm me-2'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          <Icon
                            name='add_black'
                            className='me-1'
                            size={IconSize.md}
                          />
                        )}
                        Add new user
                      </button>
                      <button
                        onClick={() => saveUserRolesUpdates()}
                        type='button'
                        disabled={
                          selectedRoles.length === 0 || isFetchUserStatusLoading
                        }
                        className='text-white btn btn-primary rounded ms-3 saveBtn'
                        data-testid='save-user-roles-button'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {users && (
              <div className='row'>
                <div className='col-12'>{renderUserTable()}</div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='container mt-5'>
          <div className='row mt-5'>
            <div className='col-12'>
              <div className='d-flex justify-content-center align-items-center mt-5'>
                <div className='p-4 shadow-lg bg-white rounded'>
                  <h5>
                    Selected workspace is in Inactive state. Please select an
                    active workspace to manage users.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAddUserModal && (
        <DynamicFormModal
          title={`Add new user to workspace`}
          show={showAddUserModal}
          onHide={closeUserAddModal}
          onSave={handleSaveUser}
          buttonLabel='Save'
          disableSaveButton={formData?.userId === 0}
        >
          {renderAddUserToWorkspaceModalContent()}
        </DynamicFormModal>
      )}

      {showBulkAddUsersModal && (
        <DynamicFormModal
          title={`Bulk add user emails to workspace`}
          show={showBulkAddUsersModal}
          onHide={() => setShowBulkAddUsersModal(false)}
          onSave={handleBulkAddUsersToWorkspace}
          buttonLabel='Save'
          disableSaveButton={
            !selectedBulkAddRole ||
            !hasValidCommaseparatedEmails(commaSeparatedEmails)
          }
        >
          {renderBulkAddUsersToWorkspaceModalContent()}
        </DynamicFormModal>
      )}

      {showConfirmationModal && renderConfirmationModal(revokeUserId)}
    </>
  );
};

export default UserListPageContent;
