import DefaultAvatar from 'assets/images/default_avatar.png';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import Icon, { IconSize } from 'components/Icon';
import Loading from 'components/Loading';
import MenuIconButton from 'components/Sidebar/MenuIconButton';
import SidebarDrawer from 'components/SidebarDrawer';
import {
  WorkspaceConfiguration,
  WorkspaceConfigurationCustomerId,
} from 'components/WorkspaceConfigurations/typings';
import useAdobeAnalytics from 'hooks/Adobe/useAdobeAnalytics';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { clearChatMessages } from 'redux/AgentsChat/slice';
import { selectPageName, selectPreviousPageName } from 'redux/App/slice';
import {
  resetChatHistory,
  setActiveChatHistoryId,
  setNewChatObjectToChatHistory,
} from 'redux/ChatHistory/slice';
import {
  resetFilterData,
  resetFilterQuery,
  resetlastAppliedFilterData,
} from 'redux/Filters/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { clearRetrievalState } from 'redux/Retrieval/slice';
import { selectCurrentUserPicture } from 'redux/User/slice';
import {
  selectAvailableWorkspaceConfigurations,
  selectAvailableWorkspaceConfigurationsStatus,
  selectCurrentWorkspace,
  selectHasUserAccessToAnyWorkspace,
  setCurrentSelectedWorkspace,
} from 'redux/Workspace/slice';
import {
  clearFilterDataFromUrlParam,
  clearFilterQueryFromUrlParam,
} from 'utils/filters';
import { LOCAL_STORAGE_KEYS, LocalStorageUtil } from 'utils/localStorage';

import HeaderTitle from './HeaderTitle';
import styles from './index.module.scss';

interface HeaderProps {
  hideWorkspaceDropdown?: boolean;
}

const Header: React.FC<HeaderProps> = ({ hideWorkspaceDropdown = false }) => {
  const { recordLinkInteractionEvent } = useAdobeAnalytics();
  const currentlySelectedWorkspace = useAppSelector(selectCurrentWorkspace);

  const customLogo = currentlySelectedWorkspace?.customLogoUrl;
  const currentPageName = useAppSelector(selectPageName);
  const previousPageName = useAppSelector(selectPreviousPageName);

  const availableWorkspaces = useAppSelector(
    selectAvailableWorkspaceConfigurations,
  );
  const workspaceConfigurationsStatus = useAppSelector(
    selectAvailableWorkspaceConfigurationsStatus,
  );
  const userHasWorkspaceAccess = useAppSelector(
    selectHasUserAccessToAnyWorkspace,
  );
  const profilePictureSource = useAppSelector(selectCurrentUserPicture);
  const dispatch = useAppDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  const handleWorkspaceChange = (
    id: string,
    customerId: WorkspaceConfigurationCustomerId,
    workspace: WorkspaceConfiguration,
  ) => {
    if (workspace.workspaceName === currentlySelectedWorkspace?.workspaceName)
      return;
    LocalStorageUtil.setItem({
      key: LOCAL_STORAGE_KEYS.SELECTED_WORKSPACE,
      value: workspace,
    });
    dispatch(setNewChatObjectToChatHistory(customerId));
    dispatch(setCurrentSelectedWorkspace(workspace));
    dispatch(clearRetrievalState());
    dispatch(resetFilterData());
    dispatch(resetlastAppliedFilterData());
    dispatch(resetFilterQuery());
    dispatch(clearChatMessages());
    dispatch(
      setActiveChatHistoryId({ id: 0, customerId: workspace.customerId }),
    );
    dispatch(resetChatHistory());
    clearFilterDataFromUrlParam();
    clearFilterQueryFromUrlParam();

    recordLinkInteractionEvent({
      linkName: `workspace-dropdown-${id}`,
      sectionName: 'Workspace Dropdown',
      workspaceName: workspace?.workspaceName ?? '',
      pageName: currentPageName,
      previousPage: previousPageName,
    });
  };

  const profileImageSource = profilePictureSource || DefaultAvatar;
  const workspacesWithCreatedStatus = availableWorkspaces.filter(
    (wrkspc) =>
      wrkspc.status === 'Created' && wrkspc.accessType === 'VOYAGER_FE',
  );
  return (
    <>
      <div className={`container-fluid ${styles.headerContainer}`}>
        <div className='row'>
          <div className='col-6'>
            <div className='d-flex flex-grow-1 flex-row align-items-center gap-2'>
              <div className='d-block d-lg-none'>
                <MenuIconButton isExpanded={false} onClick={openDrawer} />
              </div>

              {customLogo ? (
                <img
                  src={customLogo}
                  alt='Logo'
                  className={styles.customLogo}
                />
              ) : (
                <Icon name='synap_logo' size={IconSize.lg} />
              )}
              <HeaderTitle />
            </div>
          </div>
          <div className='col-6'>
            {userHasWorkspaceAccess && (
              <div className='d-flex justify-content-end align-items-center gap-3'>
                {!hideWorkspaceDropdown && (
                  <Dropdown className='border rounded' align='end'>
                    <Dropdown.Toggle
                      disabled={workspaceConfigurationsStatus === 'loading'}
                      variant='bcg-light'
                      id='workspace-dropdown'
                      className={`text-truncate ${styles.customDropdownButton}`}
                    >
                      {workspaceConfigurationsStatus === 'loading' ? (
                        <Loading className={styles.customWidth} />
                      ) : (
                        currentlySelectedWorkspace?.workspaceName ||
                        'Select Workspace'
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className={`${styles.customDropdown}`}
                      {...(workspacesWithCreatedStatus.length > 10 && {
                        as: CustomDropdownMenu,
                      })}
                    >
                      {workspacesWithCreatedStatus
                        .sort((a, b) =>
                          a.workspaceName
                            .toLowerCase()
                            .localeCompare(b.workspaceName.toLowerCase()),
                        )
                        .map((wrkspc) => (
                          <Dropdown.Item
                            key={wrkspc.customerId}
                            id={wrkspc.id}
                            onClick={() =>
                              handleWorkspaceChange(
                                wrkspc.id,
                                wrkspc.customerId,
                                wrkspc,
                              )
                            }
                            active={
                              wrkspc.customerId ===
                              currentlySelectedWorkspace?.customerId
                            }
                          >
                            {wrkspc.workspaceName}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                <div className='d-inline-block'>
                  <img
                    src={profileImageSource}
                    width='36'
                    height='36'
                    alt='Avatar'
                    className='rounded-circle'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <SidebarDrawer open={drawerOpen} onClose={closeDrawer} />
    </>
  );
};

export default Header;
