import DOMPurify from 'dompurify';
import React from 'react';

import styles from './index.module.scss';

export interface EmailProps {
  emailData: string;
}

const Email: React.FC<EmailProps> = ({ emailData }) => {
  const formattedEmail = emailData
    ?.split('\n')
    ?.map((line: string, index: number) => {
      const sanitizedEmailText = DOMPurify.sanitize(line);
      const anchorTagRegex = /<a([^>]+)href="([^"]+?)"[^>]*>/g;
      const processedEmailTextWithTargetBlank = sanitizedEmailText.replace(
        anchorTagRegex,
        (match, attributes, href) => {
          return `<a href="${href}" target="_blank" rel="noopener noreferrer">`;
        },
      );

      const emailContentWithLinksAndLineBreaks =
        processedEmailTextWithTargetBlank + '<br />';
      return (
        <div
          key={index}
          dangerouslySetInnerHTML={{
            __html: emailContentWithLinksAndLineBreaks,
          }}
          className={`${styles.customEmailContent}`}
        ></div>
      );
    });

  return <div style={{ overflowWrap: 'anywhere' }}>{formattedEmail}</div>;
};

export default Email;
