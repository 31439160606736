import styles from 'components/Header/index.module.scss';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';

interface CustomDropdownMenuProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const CustomDropdownMenu = React.forwardRef(
  ({ children, style, className }: CustomDropdownMenuProps) => {
    const [searchValue, setSearchValue] = useState('');

    function isReactElement(child: React.ReactNode): child is JSX.Element {
      return child !== null && typeof child === 'object' && 'props' in child;
    }

    function fuzzyMatch(input: string, search: string): boolean {
      return input.toLowerCase().includes(search.toLowerCase());
    }

    return (
      <div className={className} style={style}>
        <Form.Control
          className={`mt-2 mb-3 mx-3 ${styles.customInput}`}
          placeholder='Type to filter Workspaces...'
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          autoFocus
        />
        <ul className={`list-unstyled ${styles.customDropdownList}`}>
          {React.Children.toArray(children).filter((child: React.ReactNode) => {
            if (isReactElement(child)) {
              return (
                !searchValue ||
                fuzzyMatch(child.props.children.toLowerCase(), searchValue)
              );
            }
            return false;
          })}
        </ul>
      </div>
    );
  },
);

export default CustomDropdownMenu;
