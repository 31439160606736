import Title from 'components/Title';
import React from 'react';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const TitleDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.title) {
    console.warn(
      `TitleDynamicWrapper: title is missing. title: ${props.title}`,
    );
    return null;
  }

  return <Title title={props.title} />;
};

export default TitleDynamicWrapper;
