import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Filter,
  WorkspaceConfigurationDataRepository,
  WorkspaceConfigurationMethod,
} from 'components/WorkspaceConfigurations/typings';
import { RootState } from 'redux/store';
import { fetchData } from 'utils/apiRequest';
import { API_ENDPOINTS } from 'utils/constants';
import {
  setFilterDataToUrlParam,
  setFilterQueryToUrlParam,
} from 'utils/filters';
import { v4 as uuidv4 } from 'uuid';

import { updateRetrievalRequestId } from './slice';
// Commenting out import, as now retrievalData is automatically mocked via msw transparently
// import mockedRetrievalData from '../../dummyData/retreivalResponse.json';
import {
  ConsumerOptions,
  RetrievalFilter,
  RetrievalRequestData,
  RetrievalResponseData,
} from './typings';

export const conductRetrievalSearch = createAsyncThunk<RetrievalResponseData>(
  'retrieval/conductRetrievalSearch',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const requestId = uuidv4();
    console.debug(`Starting retrieval search for request ID: ${requestId}}`);

    const functionStartTime = new Date().getTime();

    const currentState = getState() as RootState;
    const filtersState = currentState.filters;
    const workspaceState = currentState.workspace;

    const retreivalSearchQuery = filtersState.filterQuery;

    const retreivalSearchFilterData = filtersState.filters;

    /**
     * MAJOR TODO:
     *
     * THIS WILL NOT WORK CURRENTLY BECAUSE ALL WORKSPACE
     * CONFIGURATIONS ARE CREATED OFF OF THE SAME TEMPLATE
     * AND SHARE THE SAME ID.
     *
     * REVIEW THIS PR LATER AND REMOVE CHANGES TO FILES
     * THAT WERE MADE TO MAKE THIS WORK. PRIMARILY
     * RECORDING THE currentSelectedWorkspace RATHER THAN
     * JUST THE ID OF THE WORKSPACE.
     */
    const currentWorkspaceConfig = workspaceState.currentSelectedWorkspace;

    if (!currentWorkspaceConfig) {
      return rejectWithValue(
        `Unable complete retrieval/conductRetrievalSearch. Workspace is undefined for request ID: ${requestId}`,
      );
    }

    // set query to URL params
    setFilterQueryToUrlParam(retreivalSearchQuery);
    // set filter params to URL params
    setFilterDataToUrlParam(retreivalSearchFilterData);

    const cleanedSearchQuery = retreivalSearchQuery.replace(
      /[!^()\-\+\[\]{}:"\/]/g,
      '',
    );
    console.debug(
      `Using the following search query, filters, and workspace for request ID: ${requestId}. Search Params: `,
      cleanedSearchQuery,
      retreivalSearchFilterData,
      currentWorkspaceConfig,
    );

    const workspaceSearchConsumerId =
      currentWorkspaceConfig.workspaceName.toLowerCase();
    if (!workspaceSearchConsumerId) {
      return rejectWithValue(
        `Unable to send chat message. Workspace consumer ID is undefined for request ID: ${requestId}`,
      );
    }

    const workspaceFilters = currentWorkspaceConfig.search.filters as Filter[];
    if (!workspaceFilters) {
      return rejectWithValue(
        `Unable to send chat message. Workspace filters are undefined for request ID: ${requestId}`,
      );
    }

    let workspaceDataRepository: WorkspaceConfigurationDataRepository = 'vespa';
    if (currentWorkspaceConfig.dataRepository) {
      workspaceDataRepository = currentWorkspaceConfig.dataRepository;
    }

    let method: WorkspaceConfigurationMethod = 'hybrid_1';
    if (currentWorkspaceConfig.method) {
      method = currentWorkspaceConfig.method;
    }

    const retrievalApiUrl = API_ENDPOINTS.retrieval;

    const consumerOptions: ConsumerOptions = {
      consumer_key: workspaceSearchConsumerId,
      data_source: workspaceSearchConsumerId,
    };

    if (workspaceDataRepository === 'elastic') {
      consumerOptions.workspace_id = String(currentWorkspaceConfig.workspaceId);
    }

    const requestBody: RetrievalRequestData = {
      query: cleanedSearchQuery || '',
      method: method,
      top_n: '100',
      consumer_options: consumerOptions,
      request_id: requestId,
      filters: [],
      data_repository: workspaceDataRepository,
    };

    // Add workspace filters to the retrieval request body
    if (workspaceFilters.length > 0) {
      workspaceFilters.forEach((filter) => {
        const retrievalFilter = {
          name: filter.sourceDataKey,
          type: filter.filterType,
        } as RetrievalFilter;

        requestBody.filters?.push(retrievalFilter);
      });
    }

    // Add search parameters to the retrieval request body
    if (retreivalSearchFilterData) {
      Object.keys(retreivalSearchFilterData).forEach((filterKey) => {
        const filterGroup = retreivalSearchFilterData?.[filterKey];
        if (!filterGroup) {
          return;
        }

        filterGroup.forEach((filterValue) => {
          const retrievalFilter = {
            name: filterValue.name,
            type: filterValue.type,
            filter_values: filterValue.filter_values,
            predicate: filterValue.predicate,
          } as RetrievalFilter;

          requestBody.filters?.push(retrievalFilter);
        });
      });
    }

    //line 204 - 206 will be removed once dummy data is removed
    /* if (
      workspaceSearchConsumerId === 'dummy' &&
      process.env.REACT_APP_ENVIRONMENT !== 'prod'
    ) {
      return mockedRetrievalData as unknown as RetrievalResponseData;
    } */

    const response = await fetchData(retrievalApiUrl, 'POST', requestBody);

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling search API for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue(data);
    }

    const data = (await response.json()) as RetrievalResponseData;
    dispatch(updateRetrievalRequestId(requestId));

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `Search complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return data;
  },
);

export const getRetrievalSearchFilters =
  createAsyncThunk<RetrievalResponseData>(
    'retrieval/conductRetrievalSearch',
    async (_, { rejectWithValue, dispatch, getState }) => {
      const requestId = uuidv4();
      console.debug(`Starting retrieval search for request ID: ${requestId}}`);

      const functionStartTime = new Date().getTime();

      const currentState = getState() as RootState;
      const filtersState = currentState.filters;
      const workspaceState = currentState.workspace;

      const retreivalSearchQuery = filtersState.filterQuery;
      const retreivalSearchFilterData = filtersState.filters;

      /**
       * MAJOR TODO:
       *
       * THIS WILL NOT WORK CURRENTLY BECAUSE ALL WORKSPACE
       * CONFIGURATIONS ARE CREATED OFF OF THE SAME TEMPLATE
       * AND SHARE THE SAME ID.
       *
       * REVIEW THIS PR LATER AND REMOVE CHANGES TO FILES
       * THAT WERE MADE TO MAKE THIS WORK. PRIMARILY
       * RECORDING THE currentSelectedWorkspace RATHER THAN
       * JUST THE ID OF THE WORKSPACE.
       */
      const currentWorkspaceConfig = workspaceState.currentSelectedWorkspace;
      const cleanedSearchQuery = retreivalSearchQuery.replace(
        /[!^()\-\+\[\]{}:"\/]/g,
        '',
      );

      const workspaceSearchConsumerId =
        currentWorkspaceConfig?.workspaceName.toLowerCase() || '';

      let workspaceDataRepository: WorkspaceConfigurationDataRepository =
        'vespa';
      if (currentWorkspaceConfig?.dataRepository) {
        workspaceDataRepository = currentWorkspaceConfig.dataRepository;
      }

      let method: WorkspaceConfigurationMethod = 'hybrid_1';
      if (currentWorkspaceConfig?.method) {
        method = currentWorkspaceConfig.method;
      }

      const retrievalApiUrl = API_ENDPOINTS.retrieval;

      const consumerOptions: ConsumerOptions = {
        consumer_key: workspaceSearchConsumerId,
        data_source: workspaceSearchConsumerId,
      };

      if (workspaceDataRepository === 'elastic') {
        consumerOptions.workspace_id = String(
          currentWorkspaceConfig?.workspaceId,
        );
      }

      const requestBody: RetrievalRequestData = {
        query: cleanedSearchQuery || '',
        method: method,
        top_n: '100',
        consumer_options: consumerOptions,
        request_id: requestId,
        filters: [],
        data_repository: workspaceDataRepository,
      };

      // Add workspace filters to the retrieval request body
      const workspaceFilters = currentWorkspaceConfig?.search
        .filters as Filter[];
      if (workspaceFilters?.length > 0) {
        workspaceFilters.forEach((filter) => {
          const retrievalFilter = {
            name: filter.sourceDataKey,
            type: filter.filterType,
          } as RetrievalFilter;

          requestBody.filters?.push(retrievalFilter);
        });
      }

      // Add search parameters to the retrieval request body
      if (retreivalSearchFilterData) {
        Object.keys(retreivalSearchFilterData).forEach((filterKey) => {
          const filterGroup = retreivalSearchFilterData?.[filterKey];
          if (!filterGroup) {
            return;
          }

          filterGroup.forEach((filterValue) => {
            const retrievalFilter = {
              name: filterValue.name,
              type: filterValue.type,
              filter_values: filterValue.filter_values,
              predicate: filterValue.predicate,
            } as RetrievalFilter;

            requestBody.filters?.push(retrievalFilter);
          });
        });
      }

      const response = await fetchData(retrievalApiUrl, 'POST', requestBody);

      if (response.status < 200 || response.status >= 300) {
        const data = await response.json();
        console.error(
          `Error calling search API for request ID ${requestId}. Data: ${JSON.stringify(
            data,
          )}`,
        );
        return rejectWithValue(data);
      }

      const data = (await response.json()) as RetrievalResponseData;
      dispatch(updateRetrievalRequestId(requestId));

      const functionEndTime = new Date().getTime();
      const elapsedFunctionTime = functionEndTime - functionStartTime;
      console.debug(
        `Search complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
      );

      return data;
    },
  );
