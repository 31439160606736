import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { sendGenerativeMessage } from './api';
import { GenerativeMessage, GenerativeState } from './typings';

const initialState: GenerativeState = {
  messages: [],
  lastReceivedUserQuery: '',
  status: 'idle',
  error: null,
  requestId: '',
};

const generativeSlice = createSlice({
  name: 'generative',
  initialState,
  reducers: {
    updateGenerativeStreamData: (state, action) => {
      const generativeMessage: GenerativeMessage = action.payload;
      state.messages.push(generativeMessage);
    },
    popLastGenerativeMessage: (state) => {
      state.messages.pop();
    },
    clearGenerativeMessages: (state) => {
      state.messages = [];
    },
    updateGenerativeRequestId: (state, action) => {
      state.requestId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendGenerativeMessage.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(sendGenerativeMessage.fulfilled, (state, action) => {
      state.lastReceivedUserQuery = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(sendGenerativeMessage.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as Error;
    });
  },
});

export const {
  updateGenerativeStreamData,
  popLastGenerativeMessage,
  clearGenerativeMessages,
  updateGenerativeRequestId,
} = generativeSlice.actions;

// selectors
export const selectGenerativeMessages = (state: RootState) =>
  state.generative.messages;

export const selectGenerativeStatus = (state: RootState) =>
  state.generative.status;

export const selectGenerativeRequestId = (state: RootState) =>
  state.generative.requestId;

export default generativeSlice.reducer;
