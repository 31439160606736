import Icon, { IconSize } from 'components/Icon';
import {
  CustomIconNames,
  TooltipConfig,
} from 'components/WorkspaceConfigurations/typings';
import useAnalytics from 'hooks/Adobe/useAdobeAnalytics';
import React, { useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { selectFilterQuery } from 'redux/Filters/slice';
import { SearchParamFilters } from 'redux/Filters/typings';
import { useAppSelector } from 'redux/hooks';

import styles from './index.module.scss';

const { ContentCopy } = CustomIconNames;

export interface PillProps {
  showCopyIcon?: boolean;
  field: string;
  url?: string;
  tooltip?: TooltipConfig;
  onClickUrl?: string;
}

const Pill: React.FC<PillProps> = ({
  field,
  url,
  showCopyIcon,
  tooltip,
  onClickUrl,
}) => {
  const filterQuery = useAppSelector(selectFilterQuery);
  const [isCopied, setIsCopied] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const { recordSearchResultsOtherLinksEvent } = useAnalytics();

  const renderTooltip = (props: any) => {
    if (!tooltip) return null;

    return (
      <Tooltip id={`${field}-tooltip`} {...props}>
        {isCopied ? tooltip.tooltipOnClickText : tooltip.tooltipText}
      </Tooltip>
    );
  };

  const handleCopy = () => {
    const textToCopy = textRef.current?.innerText;
    if (!textToCopy) return;

    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const isCommaSeperatedString = field.split(',').length > 1;

  const renderPill = (pillText: string) => {
    return (
      <div
        className={` rounded-3 ${
          styles.pill
        } px-2 py-2 fw-medium d-flex justify-content-center align-items-center ${
          showCopyIcon ? styles.copyIcon : ''
        }`}
        ref={textRef}
        onClick={() => {
          recordSearchResultsOtherLinksEvent({
            resultName: pillText,
            linkName: pillText,
            searchTerm: filterQuery,
            previousTerm: '',
            previousFilters: {} as SearchParamFilters,
          });
        }}
      >
        {url && <img src={url} alt={url} width='15px' height='15px' />}
        <div
          className={
            onClickUrl ? ` ${styles.cursorPointer} ${styles.textUnderline}` : ''
          }
          onClick={() => onClickUrl && window.open(onClickUrl, '_blank')}
        >
          {pillText}
        </div>
        {showCopyIcon && (
          <>
            {tooltip ? (
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <div>
                  <Icon
                    name={ContentCopy}
                    size={IconSize.sm}
                    onClick={handleCopy}
                  />
                </div>
              </OverlayTrigger>
            ) : (
              <div>
                <Icon
                  name={ContentCopy}
                  size={IconSize.sm}
                  onClick={() => {
                    recordSearchResultsOtherLinksEvent({
                      linkName: `${pillText} copy icon`,
                      resultName: 'pill copy',
                      searchTerm: filterQuery,
                      previousTerm: '',
                      previousFilters: {} as SearchParamFilters,
                    });
                    handleCopy();
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  if (isCommaSeperatedString) {
    return (
      <div className={`${styles.pillWrapper} d-flex flex-row flex-wrap`}>
        {field.split(',').map((pillText, index) => (
          <div key={index}>{renderPill(pillText)}</div>
        ))}
      </div>
    );
  }

  return <>{renderPill(field)}</>;
};

export default Pill;
