import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { conductRetrievalSearch } from './api';
import { RetrievalState } from './typings';

const initialState: RetrievalState = {
  results: [],
  filters: [],
  lastReceivedUserQuery: '',
  status: 'idle',
  error: null,
  requestId: '',
};

const retrievalSlice = createSlice({
  name: 'retrieval',
  initialState,
  reducers: {
    clearRetrievalState: (state) => {
      state.results = [];
      state.filters = [];
      state.lastReceivedUserQuery = '';
    },

    updateRetrievalRequestId: (state, action) => {
      state.requestId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(conductRetrievalSearch.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(conductRetrievalSearch.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.results = action.payload.results;
      state.filters = action.payload.filters;
      state.lastReceivedUserQuery = action.payload.query;
    });
    builder.addCase(conductRetrievalSearch.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as Error;
    });
  },
});

export const { clearRetrievalState, updateRetrievalRequestId } =
  retrievalSlice.actions;

// selectors
export const selectRetrievalResults = (state: RootState) =>
  state.retrieval.results;

export const selectRetrievalFilters = (state: RootState) =>
  state.retrieval.filters;

export const selectRetrievalStatus = (state: RootState) =>
  state.retrieval.status;

export const selectRetrievalResultsCount = (state: RootState) =>
  state.retrieval.results.length;

export const selectLastReceivedUserQuery = (state: RootState) =>
  state.retrieval.lastReceivedUserQuery;

export const selectGeneratitveRequestId = (state: RootState) =>
  state.retrieval.requestId;

export default retrievalSlice.reducer;
