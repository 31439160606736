import { AccessToken, TokenManagerInterface } from '@okta/okta-auth-js';
import oktaAuthService from 'oktaConfig';
import { useLocation } from 'react-router-dom';
import { selectPageName, selectPreviousPageName } from 'redux/App/slice';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentWorkspace } from 'redux/Workspace/slice';

import {
  AdobeDigitalData,
  AnalyticsEventName,
  AnalyticsProperties,
  BaseAnalyticsProperties,
  ChatResponseAnalyticsProperties,
  ChatResultFileInteractionAnalyticsProperties,
  CopyChatResponseAnalyticsProperties,
  LinkInteractionAnalyticsProperties,
  NewChatAnalyticsProperties,
  PreviousChatInteractionsAnalyticsProperties,
  SearchAnalyticsProperties,
  SearchPageLoadAnalyticsProperties,
  SearchResultsDownloadAttachmentAnalyticsProperties,
  SearchResultsInteractionAnalyticsProperties,
  SearchResultsInteractionsWithFilters,
  SearchResultsOtherLinksAnalyticsProperties,
  SearchResultsPreviewAttachmentAnalyticsProperties,
  SearchSummaryFeedbackAnalyticsProperties,
  SuggestedTermInteractionAnalyticsProperties,
} from './typings';
import { generateAdobeAnalyticsEvent } from './utils';

const useAnalytics = () => {
  const selectedWorkspace = useAppSelector(selectCurrentWorkspace);
  const currentPageName = useAppSelector(selectPageName);
  const previousPageName = useAppSelector(selectPreviousPageName);

  const location = useLocation();

  const recordAnalyticsEvent = async (
    eventName: AnalyticsEventName,
    eventProperties?: AnalyticsProperties,
  ) => {
    const workspaceRoutes = selectedWorkspace?.routes;
    if (!workspaceRoutes) {
      console.error('recordAnalyticsEvent: Workspace routes are not available');
      return;
    }

    const adobeAnalytics = window?._satellite;
    if (!adobeAnalytics) {
      console.error('recordAnalyticsEvent: Adobe Analytics is not available');
      return;
    }

    const pageUrl = window.location.href;

    const pageName: string = currentPageName;
    const previousPage: string = previousPageName;

    const tokenManager: TokenManagerInterface = oktaAuthService.tokenManager;
    const accessToken: AccessToken = (await tokenManager.get(
      'accessToken',
    )) as AccessToken;
    const userClaims = accessToken.claims;
    const userEmail = userClaims.sub;

    let hashedEmail = '';
    if (userEmail) {
      // hash the email address for privacy
      const emailBuffer = new TextEncoder().encode(userEmail.toLowerCase());
      const emailHash = await window.crypto.subtle.digest(
        'SHA-256',
        emailBuffer,
      );
      const emailHashArray = Array.from(new Uint8Array(emailHash));
      const emailHashHex = emailHashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');

      hashedEmail = emailHashHex;
    }

    const staffSystemID = hashedEmail;

    const baseAnalyticsEvent: BaseAnalyticsProperties = {
      pageName,
      previousPage,
      pageUrl,
      staffSystemID,
      productName: 'Voyager',
    };

    const adobeAnalyticsEvent: AdobeDigitalData = generateAdobeAnalyticsEvent(
      baseAnalyticsEvent,
      eventProperties,
    );

    try {
      // the .track() method uses th window.digitalData
      // object to get the event data
      window.digitalData = adobeAnalyticsEvent;
      adobeAnalytics.track(eventName);
    } catch (error) {
      console.error(
        `recordAnalyticsEvent: Error tracking event ${eventName}`,
        error,
      );
    }

    console.log(
      `recordAnalyticsEvent completed for event ${eventName}`,
      adobeAnalyticsEvent,
    );
  };

  const recordPageViewEvent = (
    properties: SearchPageLoadAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Page_Load', properties);
  };

  // aka event1
  const recordLinkInteractionEvent = (
    properties: LinkInteractionAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Link_Interactions', properties);
  };

  // aka event2
  const recordSearchEvent = (properties: SearchAnalyticsProperties) => {
    recordAnalyticsEvent('Internal_Search', properties);
  };

  // aka event3
  const recordSuggestedTermInteractionEvent = (
    properties: SuggestedTermInteractionAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Suggested_Term_Interactions', properties);
  };

  // aka event4
  const recordSearchResultsInteractionEvent = (
    properties: SearchResultsInteractionAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Internal_Search_Result_Interaction', properties);
  };

  // aka event5
  const recordSearchResultsDownloadAttachmentEvent = (
    properties: SearchResultsDownloadAttachmentAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Download', properties);
  };

  // aka event6
  const recordSearchResultsPreviewAttachmentEvent = (
    properties: SearchResultsPreviewAttachmentAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Preview', properties);
  };

  // aka event7
  const recordSearchResultsOtherLinksEvent = (
    properties: SearchResultsOtherLinksAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Interactions_within_search_result', properties);
  };

  // aka event8
  const recordSearchResultsInteractionsWithFiltersEvent = (
    properties: SearchResultsInteractionsWithFilters,
  ) => {
    recordAnalyticsEvent('Apply_Filters', properties);
  };

  // aka event9
  const recordChatResponseInteractionEvent = (
    properties: ChatResponseAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Chat_Response', properties);
  };

  // aka event11
  const recordCopyChatResponseInteractionEvent = (
    properties: CopyChatResponseAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Interaction_Copy_Response', properties);
  };

  // aka event12
  const recordChatResultFileInteractionEvent = (
    properties: ChatResultFileInteractionAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Result_Clicks', properties);
  };

  // aka event13
  const recordNewChatEvent = (properties: NewChatAnalyticsProperties) => {
    recordAnalyticsEvent('New_Chat', properties);
  };

  // aka event14
  const recordPreviousChatInteractionEvent = (
    properties: PreviousChatInteractionsAnalyticsProperties,
  ) => {
    recordAnalyticsEvent('Previous_Chat_Interactions', properties);
  };

  // aka event15 and event16
  const recordSearchSummaryFeedbackEvent = (
    properties: SearchSummaryFeedbackAnalyticsProperties,
    event: 'Thumbs_Up' | 'Thumbs_Down',
  ) => {
    recordAnalyticsEvent(event, properties);
  };

  return {
    recordPageViewEvent,
    recordLinkInteractionEvent,
    recordSearchEvent,
    recordSuggestedTermInteractionEvent,
    recordSearchResultsInteractionEvent,
    recordSearchResultsDownloadAttachmentEvent,
    recordSearchResultsPreviewAttachmentEvent,
    recordSearchResultsOtherLinksEvent,
    recordSearchResultsInteractionsWithFiltersEvent,
    recordSearchSummaryFeedbackEvent,
    recordNewChatEvent,
    recordPreviousChatInteractionEvent,
    recordCopyChatResponseInteractionEvent,
    recordChatResultFileInteractionEvent,
    recordChatResponseInteractionEvent,
  };
};

export default useAnalytics;
