import oktaAuthService from 'oktaConfig';

function buildHeader(): Headers {
  const headers = new Headers();
  const token = oktaAuthService.getAccessToken();
  headers.append('Content-Type', 'application/json');
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('x-api-key', process.env.REACT_APP_X_API_KEY);
  }
  return headers;
}

// Generic function to fetch data from an API endpoint
export async function fetchData(
  url: string,
  method: string,
  data?: any,
  extraParams?: any,
): Promise<Response> {
  const requestOptions: RequestInit = {
    method,
    headers: buildHeader(),
    ...extraParams,
  };

  if (data && method !== 'GET') {
    requestOptions.body = JSON.stringify(data);
  }

  const response: Response = await fetch(url, requestOptions);
  return response;
}
