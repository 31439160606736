import { RJSFSchema, UiSchema } from '@rjsf/utils';

export const schema: RJSFSchema = {
  type: 'object',
  title: 'Customization Form',
  properties: {
    dataRepository: {
      title: 'Data Repository',
      type: 'string',
    },
    method: {
      title: 'Method',
      type: 'string',
    },
    routes: {
      type: 'array',
      title: 'Route',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
            title: 'ID',
          },
          path: {
            type: 'string',
            title: 'Path',
          },
          label: {
            type: 'string',
            title: 'Label',
          },
          element: {
            type: 'string',
            title: 'Element',
          },
        },
      },
    },
    chat: {
      title: 'Chat',
      type: 'object',
      properties: {
        chatSourceActions: {
          title: 'Chat Source Actions',
          type: 'object',
          properties: {
            fileType: {
              title: 'File Type',
              type: 'object',
              properties: {
                onClickAction: {
                  type: 'object',
                  properties: {
                    actionType: {
                      type: 'string',
                      title: 'Action Type',
                    },
                    fetchApiToUse: {
                      type: 'string',
                      title: 'Fetch API to Use',
                    },
                    layoutDataFilter: {
                      type: 'object',
                      title: 'Layout Data Filter',
                      properties: {
                        key: {
                          type: 'string',
                          title: 'Key',
                        },
                        mappedDetails: {
                          type: 'array',
                          title: 'Mapped Details',
                          items: {
                            type: 'object',
                            properties: {
                              key: {
                                type: 'string',
                                title: 'Key',
                              },
                              dataSource: {
                                type: 'string',
                                title: 'Data Source',
                              },
                              previewName: {
                                type: 'string',
                                title: 'Preview Name',
                              },
                            },
                          },
                        },
                      },
                    },
                    retreivalParamKeys: {
                      type: 'array',
                      title: 'Retrieval Parameter Key',
                      items: {
                        type: 'string',
                      },
                    },
                    fetchApiQueryDataKey: {
                      type: 'array',
                      title: 'Fetch Api Query Data Key',
                      items: {
                        type: 'object',
                        properties: {
                          key: {
                            type: 'string',
                            title: 'Key',
                          },
                          mappedName: {
                            type: 'string',
                            title: 'Mapped Name',
                          },
                        },
                      },
                    },
                    fetchedResultsToBeFilteredBy: {
                      type: 'array',
                      title: 'Fetched Results to be Filtered by',
                      items: {
                        type: 'object',
                        properties: {
                          key: {
                            type: 'string',
                            title: 'Key',
                          },
                          type: {
                            type: 'string',
                            title: 'Type',
                          },
                          value: {
                            type: 'string',
                            title: 'Value',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        customEmptyChatImage: {
          type: 'string',
          title: 'Custom Empty Chat Image',
        },
        initialQuickMessages: {
          type: 'array',
          title: 'Initial Quick Message',
          items: {
            type: 'string',
          },
        },
        customEmptyChatHeader: {
          type: 'string',
          title: 'Custom Empty Chat Header',
        },
        customEmptyChatSubHeader: {
          type: 'string',
          title: 'Custom Empty Chat Subheader',
        },
      },
    },
    search: {
      type: 'object',
      title: 'Search',
      properties: {
        display: {
          type: 'object',
          properties: {
            searchSummary: {
              type: 'boolean',
              title: 'Show Search Summary',
            },
            relatedRequestThemes: {
              title: 'Request Related Themes',
              type: 'boolean',
            },
          },
        },
        filters: {
          type: 'array',
          title: 'Search Filters',
          items: {
            type: 'object',
            properties: {
              id: {
                type: 'string',
              },
              label: {
                type: 'string',
              },
              filterType: {
                type: 'string',
                enum: ['date', 'list'],
              },
              sourceDataKey: {
                type: 'string',
              },
              // filterValueToLabelMappingArray: {
              //   type: 'array',
              //   items: {
              //     type: 'object',
              //     properties: {
              //       key: {
              //         type: 'string',
              //       },
              //       value: {
              //         type: 'string',
              //       },
              //     },
              //   },
              // },
              filterValueToLabelMapping: {
                title: 'Filter Value to Label Mapping',
                type: 'object',
                additionalProperties: {
                  type: 'string',
                },
              },
            },
          },
        },
        searchSummary: {
          type: 'object',
          title: 'Search Summary',
          properties: {
            prompt: {
              title: 'Prompt',
              type: 'string',
            },
          },
        },
        defaultNoneActivePreviewLayoutName: {
          title: 'Default None Active Preview Layout Name',
          type: 'string',
        },
        defaultResultCardLayoutName: {
          title: 'Default Result Card Layout Name',
          type: 'array',
          items: {
            type: 'string',
          },
        },
        layouts: {
          title: 'Layouts',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              displayName: {
                type: 'string',
                title: 'Display Name',
              },
              internalName: {
                type: 'string',
                title: 'Internal Name',
              },
              layoutDataFilter: {
                type: 'object',
                properties: {
                  key: {
                    type: 'string',
                    title: 'Filter Key',
                  },
                  value: {
                    type: 'string',
                    title: 'Filter Value',
                  },
                },
              },
              onClickAction: {
                type: 'object',
                properties: {
                  actionType: {
                    type: 'string',
                    title: 'Action Type',
                  },
                  previewLayoutName: {
                    type: 'string',
                    title: 'Preview Layout Name',
                  },
                },
              },
              rows: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    id: {
                      type: 'string',
                      title: 'Row ID',
                    },
                    columns: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          id: {
                            type: 'string',
                            title: 'Column ID',
                          },
                          size: {
                            type: 'integer',
                            title: 'Column Size',
                          },
                          componentName: {
                            type: 'string',
                            title: 'Component Name',
                          },
                          requiredComponentRenderParams: {
                            type: 'array',
                            items: {
                              type: 'object',
                              properties: {
                                paramName: {
                                  type: 'string',
                                  title: 'Parameter Name',
                                },
                                paramType: {
                                  type: 'string',
                                  enum: ['dynamic', 'static'],
                                  title: 'Parameter Type',
                                },
                                paramDynamicLookupKey: {
                                  type: 'string',
                                  title: 'Dynamic Lookup Key',
                                },
                                paramStaticValue: {
                                  type: 'string',
                                  title: 'Static Value',
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export const uiSchema: UiSchema = {
  search: {
    searchSummary: {
      prompt: {
        'ui:widget': 'textarea',
        'ui:options': { rows: 10 },
      },
    },
  },
};
