import { LocalStorageUtil } from 'utils/localStorage';

export enum FeatureFlags {
  WorkspaceManagement = 'workspace-management',
  SharepointExternalMetadata = 'sharepoint-external-metadata',
  WorkspaceCreation = 'workspace-creation',
  BulkAddUsers = 'bulk-add-users',
  EnableChatSuggestions = 'enable-chat-suggestions',
}

/**
 * Combines the feature flags from the environment variable and the local storage
 */
export const isFeatureEnabled = (feature: FeatureFlags): boolean => {
  const enabledFeaturesFromLocalStorage: string[] = [];

  const localStorageEnabledFeaturesString: string | null =
    LocalStorageUtil.getItem<string>('FEATURE_FLAGS');

  if (Array.isArray(localStorageEnabledFeaturesString)) {
    enabledFeaturesFromLocalStorage.push(...localStorageEnabledFeaturesString);
  }

  const enabledFeaturesFromEnv: string[] =
    process.env.REACT_APP_FEATURE_FLAGS?.split(',') || [];

  const enabledFeatures = [
    ...enabledFeaturesFromLocalStorage,
    ...enabledFeaturesFromEnv,
  ];

  return enabledFeatures.includes(feature);
};
