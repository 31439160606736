import React from 'react';

import './styles.scss';

export interface TitleProps {
  title: string;
}

const Title: React.FC<TitleProps> = ({ title }) => {
  return <div className='Title fw-medium'>{title}</div>;
};

export default Title;
