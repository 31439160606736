import { createAsyncThunk } from '@reduxjs/toolkit';
import { WorkspaceConfiguration } from 'components/WorkspaceConfigurations/typings';
import { fetchData } from 'utils/apiRequest';
import { API_ENDPOINTS } from 'utils/constants';
import { extractUserIds } from 'utils/users';
import { v4 as uuidv4 } from 'uuid';

import {
  getManyUsersByEmailFromPeopleApi,
  getManyUsersByIdsFromPeopleApi,
} from './graphqlQuery';
import { updateBulkUserAddedCompletionPercentage } from './slice';
import {
  AddUserToWorkspaceObjRequestBody,
  BulkUserRequestPayload,
  PeopleAPIUser,
  RemoveUserFromWorkspaceObjRequestBody,
  updateBulkUsers,
  UserRequestPayload,
  UserRole,
  WorkspaceAPIUser,
  WorkspaceAPIUserResponse,
} from './typings';

export const addUserToWorkspace = createAsyncThunk<
  string,
  [WorkspaceConfiguration, UserRequestPayload]
>(
  'workspace/addUserToWorkspace',
  async ([currentWorkspaceConfig, userRequestPayload], { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting workspace/addUserToWorkspace, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    const workspaceConsumerId = currentWorkspaceConfig.chat.consumerId;
    const workspaceCustomerId = currentWorkspaceConfig.customerId;
    const isAdmin = userRequestPayload.role === 'admin';

    const userExists = await fetchUserById(userRequestPayload.userId);
    if (!userExists) {
      const createUserResponse = await createUser(
        userRequestPayload,
        workspaceCustomerId,
        workspaceConsumerId,
        isAdmin,
      );

      if (createUserResponse.status < 200 || createUserResponse.status >= 300) {
        const data = await createUserResponse.json();
        console.error(
          `Error in workspace/addUserToWorkspace (createUser) for request ID ${requestId}. Data: ${JSON.stringify(
            data,
          )}`,
        );
        return rejectWithValue({
          error: JSON.parse(data?.detail),
          statusCode: createUserResponse.status,
        });
      }
    }

    const response = await addUserToCustomer(
      userRequestPayload,
      workspaceCustomerId,
      workspaceConsumerId,
      isAdmin,
    );

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error in workspace/addUserToWorkspace (addUserToCustomer) for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue({
        error: JSON.parse(data?.detail),
        statusCode: response.status,
      });
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `workspace/addUserToWorkspace complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    const result: string = await response.json();

    // Example response: 'User added to customer successfully'
    return result;
  },
);

export const removeUserFromWorkspace = createAsyncThunk<
  string,
  [WorkspaceConfiguration, number]
>(
  'workspace/removeUserFromWorkspace',
  async ([currentWorkspaceConfig, userId], { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting workspace/removeUserFromWorkspace, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    const genaiRemoveUserFromWorkspaceAPIUrl = `${API_ENDPOINTS.user}remove_from_customer`;
    const workspaceConsumerId = currentWorkspaceConfig.chat.consumerId;
    const workspaceCustomerId = currentWorkspaceConfig.customerId;

    const requestBody: RemoveUserFromWorkspaceObjRequestBody = {
      request_id: requestId,
      consumer_id: workspaceConsumerId,
      user_id: userId,
      customer_id: workspaceCustomerId,
    };

    const response = await fetchData(
      genaiRemoveUserFromWorkspaceAPIUrl,
      'PUT',
      requestBody,
    );

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error in workspace/removeUserFromWorkspace for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue({
        error: JSON.parse(data?.detail),
        statusCode: response.status,
      });
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `workspace/removeUserFromWorkspace complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    const result: string = await response.json();

    return result;
  },
);

export const fetchWorkspaceApiAndPeopleApiUsers = createAsyncThunk<
  { workspaceApiUsers: WorkspaceAPIUser[]; peopleApiUsers: PeopleAPIUser[] },
  number
>(
  'workspace/fetchWorkspaceApiAndPeopleApiUsers',
  async (workspaceCustomerId, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting workspace/fetchWorkspaceApiAndPeopleApiUsers, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    const genaiFetchUserToWorkspaceApiUrl = `${API_ENDPOINTS.user}customer/${workspaceCustomerId}`;

    const response = await fetchData(genaiFetchUserToWorkspaceApiUrl, 'GET');

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling workspace/fetchWorkspaceApiAndPeopleApiUsers for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue(data);
    }

    const results = (await response.json()) as WorkspaceAPIUserResponse[];

    const workspaceUsers: WorkspaceAPIUser[] = [];
    const peopleApiUsers: PeopleAPIUser[] = [];

    // fetch all users for the customer
    results.forEach((user) => {
      let role: UserRole = 'user';
      if (user.is_cust_admin === true) role = 'admin';
      const userObj: WorkspaceAPIUser = {
        customerId: user.customer_id,
        userId: user.user_id,
        isCustomerAdmin: user.is_cust_admin,
        role: role,
      };
      workspaceUsers.push(userObj);
    });

    // in order to get the user data, we need to fetch the user data from the people API
    // however, they only allow 100 users at a time, so we need to batch the requests
    if (workspaceUsers.length > 0) {
      const userIds = extractUserIds(workspaceUsers);

      // Calculate the number of batches needed
      const batchSize = 100;
      const numBatches = Math.ceil(userIds.length / batchSize);

      // Iterate over batches and dispatch fetchUserDataAsync for each batch
      for (let i = 0; i < numBatches; i++) {
        const start = i * batchSize;
        const end = Math.min((i + 1) * batchSize, userIds.length);
        const batchedUserIds = userIds.slice(start, end);

        // for each batch, we fetch the user data from the people API
        const users = await getManyUsersByIdsFromPeopleApi(batchedUserIds);
        peopleApiUsers.push(...users);
      }
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `workspace/fetchUserFromWorkspace complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return {
      workspaceApiUsers: workspaceUsers,
      peopleApiUsers: peopleApiUsers,
    };
  },
);

export const bulkUpdateUserRoles = createAsyncThunk<string, updateBulkUsers>(
  'user/bulkUpdateUserRoles',
  async (updatedUserRolesData, { rejectWithValue }) => {
    const requestId = uuidv4();
    console.debug(
      `Starting user/bulkUpdateUserRoles, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    const genaiBulkUpdateRolesApiUrl = `${API_ENDPOINTS.user}bulk_update_cust_admin`;

    const requestPayload = {
      customer_id: parseInt(updatedUserRolesData.customer_id),
      users_profile: updatedUserRolesData.updatedRoles,
    };

    const response = await fetchData(
      genaiBulkUpdateRolesApiUrl,
      'PUT',
      requestPayload,
    );

    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error in user/bulkUpdateUserRoles for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue({
        error: JSON.parse(data?.detail),
        statusCode: response.status,
      });
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `user/bulkUpdateUserRoles completed. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );
    const result: string = await response.json();

    return result;
  },
);

export const bulkAddUsersToWorkspace = createAsyncThunk<
  {
    successfullyAddedUserIds: string[];
    failedUserIds: string[];
  },
  [WorkspaceConfiguration, BulkUserRequestPayload]
>(
  'workspace/bulkAddUsersToWorkspace',
  async (
    [currentWorkspaceConfig, bulkUserRequestPayload],
    { dispatch, rejectWithValue },
  ) => {
    const requestId = uuidv4();
    console.debug(
      `Starting workspace/bulkAddUsersToWorkspace, request ID: ${requestId}.`,
    );
    const functionStartTime = new Date().getTime();

    try {
      const workspaceConsumerId = currentWorkspaceConfig.chat.consumerId;
      const workspaceCustomerId = currentWorkspaceConfig.customerId;
      const isAdmin = bulkUserRequestPayload.role === 'admin';

      const successfullyAddedUserIds: string[] = [];
      const failedUserIds: string[] = [];

      // fetch users from the people API
      const users = await getManyUsersByEmailFromPeopleApi(
        bulkUserRequestPayload.userEmails,
      );

      // this is a bit confusing, but if the user is not found by
      // their email via the people API, we consider it an error
      // because we can't add a user we cant look up in people API.
      if (users.length !== bulkUserRequestPayload.userEmails.length) {
        console.error(
          `Error in workspace/bulkAddUsersToWorkspace. Some users were not found in the people API. Request ID: ${requestId}.`,
        );
        failedUserIds.push(
          ...bulkUserRequestPayload.userEmails.filter(
            (email) =>
              !users.find(
                (user) => user.email?.toLowerCase() === email.toLowerCase(),
              ),
          ),
        );
      }

      for (const user of users) {
        const userExists = await fetchUserById(Number(user.id));
        if (!userExists) {
          const createUserPayload: UserRequestPayload = {
            role: bulkUserRequestPayload.role,
            userId: Number(user.id),
          };
          const createUserResponse = await createUser(
            createUserPayload,
            workspaceCustomerId,
            workspaceConsumerId,
            isAdmin,
          );

          if (
            createUserResponse.status < 200 ||
            createUserResponse.status >= 300
          ) {
            const data = await createUserResponse.json();
            console.error(
              `Error in workspace/bulkAddUsersToWorkspace (createUser) for request ID ${requestId}. Data: ${JSON.stringify(
                data,
              )}`,
            );
          }
        }

        const addUserToCustomerPayload: UserRequestPayload = {
          role: bulkUserRequestPayload.role,
          userId: Number(user.id),
        };
        const response = await addUserToCustomer(
          addUserToCustomerPayload,
          workspaceCustomerId,
          workspaceConsumerId,
          isAdmin,
        );
        if (response.status < 200 || response.status >= 300) {
          const data = await response.json();
          console.error(
            `Error in workspace/bulkAddUsersToWorkspace (addUserToCustomer) for request ID ${requestId}. Data: ${JSON.stringify(
              data,
            )}`,
          );
          failedUserIds.push(user?.id || '');
        } else {
          successfullyAddedUserIds.push(user?.id || '');
        }

        const completedPercentage =
          (successfullyAddedUserIds.length / users.length) * 100;
        dispatch(updateBulkUserAddedCompletionPercentage(completedPercentage));
      }

      dispatch(updateBulkUserAddedCompletionPercentage(100));
      const functionEndTime = new Date().getTime();
      const elapsedFunctionTime = functionEndTime - functionStartTime;
      console.debug(
        `workspace/bulkAddUsersToWorkspace complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
      );

      // Example response:
      /*
        {
          "successfullyAddedUserIds": [
            "Successfully added user 344199 to customer 2781",
            "Successfully added user 423345 to customer 2781",
          ],
          "failedUserIds": [
            "Failed to add user 344199 to customer 2781",
            "Failed to add user 423345 to customer 2781",
          ]
        }
      */
      return {
        successfullyAddedUserIds,
        failedUserIds,
      };
    } catch (error) {
      console.error(
        `Error in workspace/bulkAddUsersToWorkspace for request ID ${requestId}`,
        error,
      );
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue(error as Error);
    }
  },
);

const fetchUserById = async (userId: number): Promise<boolean> => {
  try {
    const requestId = uuidv4();
    console.debug(`Starting fetchUserById, request ID: ${requestId}.`);
    const functionStartTime = new Date().getTime();

    const genaiFetchUserByIdApiUrl = `${API_ENDPOINTS.user}${userId}`;

    const response = await fetchData(genaiFetchUserByIdApiUrl, 'GET');

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `fetchUserById complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return response.status === 200;
  } catch (error) {
    console.error('Error in fetchUserById: ', error);
    throw error; // rethrow to be caught by caller
  }
};

const createUser = async (
  userRequestObj: UserRequestPayload,
  customerId: number,
  workspaceConsumerId: string,
  admin: boolean,
): Promise<Response> => {
  try {
    const requestId = uuidv4();
    console.debug(`Starting createUser, request ID: ${requestId}.`);
    const functionStartTime = new Date().getTime();

    const genaiCreateUserApiUrl = `${API_ENDPOINTS.user}create`;

    const requestBody = {
      user_id: userRequestObj.userId,
      request_id: requestId,
      consumer_id: workspaceConsumerId,
      is_app_admin: admin,
      cust_ids: [customerId],
    };

    const response = await fetchData(
      genaiCreateUserApiUrl,
      'POST',
      requestBody,
    );

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `createUser complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return response;
  } catch (error) {
    console.error('Error in createUser: ', error);
    throw error; // rethrow to be caught by caller
  }
};

const addUserToCustomer = async (
  userRequestObj: UserRequestPayload,
  customerId: number,
  workspaceConsumerId: string,
  isAdmin: boolean,
): Promise<Response> => {
  try {
    const requestId = uuidv4();
    console.debug(`Starting addUserToCustomer, request ID: ${requestId}.`);
    const functionStartTime = new Date().getTime();

    let genaiAddUserToCustomerApiUrl = `${API_ENDPOINTS.user}add_to_customer`;

    const requestBody: AddUserToWorkspaceObjRequestBody = {
      user_id: userRequestObj.userId,
      request_id: requestId,
      consumer_id: workspaceConsumerId,
      is_cust_admin: isAdmin,
      customer_id: customerId,
    };

    const response = await fetchData(
      genaiAddUserToCustomerApiUrl,
      'PUT',
      requestBody,
    );

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.debug(
      `addUserToCustomer complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return response;
  } catch (error) {
    console.error('Error in addUserToCustomer: ', error);
    throw error; // rethrow to be caught by caller
  }
};
