import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import agentChatReducer from 'redux/AgentsChat/slice';
import appReducer from 'redux/App/slice';
// import chatReducer from 'redux/Chat/slice';
import chatHistoryReducer from 'redux/ChatHistory/slice';
import filtersReducer from 'redux/Filters/slice';
import generativeReducer from 'redux/Generative/slice';
import privacyAgreementReducer from 'redux/PrivacyAgreement/slice';
import retrievalReducer from 'redux/Retrieval/slice';
import userReducer from 'redux/User/slice';
import workspaceReducer from 'redux/Workspace/slice';

const rootReducer = combineReducers({
  app: appReducer,
  // chat: chatReducer,
  retrieval: retrievalReducer,
  generative: generativeReducer,
  workspace: workspaceReducer,
  chatHistory: chatHistoryReducer,
  filters: filtersReducer,
  user: userReducer,
  privacyAgreement: privacyAgreementReducer,
  agentChat: agentChatReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
