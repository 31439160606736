import React from 'react';

import styles from './index.module.scss';

const HeaderTitle = () => (
  <div className={`border-0 pr-5 text-left fw-light ${styles.textWrapper}`}>
    voyager
  </div>
);

export default HeaderTitle;
