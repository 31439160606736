import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import {
  Filter,
  WorkspaceConfiguration,
} from 'components/WorkspaceConfigurations/typings';
import {
  setActiveChatHistoryId,
  setActiveChatHistoryTitle,
  setNewChatHistoryData,
} from 'redux/ChatHistory/slice';
import { SearchParamFilters } from 'redux/Filters/typings';
import { clearRetrievalState } from 'redux/Retrieval/slice';
import { RetrievalFilterValues } from 'redux/Retrieval/typings';
import { RootState } from 'redux/store';
import { conductKnowledgeSearch } from 'redux/Workspace/api';
import { fetchData } from 'utils/apiRequest';
import {
  incomingMessageTextContainsCitations,
  replaceMessageCitationsWithHtmlTags,
} from 'utils/chat';
import { API_ENDPOINTS } from 'utils/constants';
import { v4 as uuidv4 } from 'uuid';

import {
  popLastChatMessage,
  setChatFilters,
  setChatLoadingState,
  setMostRecentChatRequestId,
  updateChatStreamData,
} from './slice';
import {
  AgentChatMessage,
  AgentChatMessageTypes,
  AgentMessageData,
  AgentsFrameworkRequestBody,
  AvailableAgentTools,
  ChatFilter,
  ChatHistoryId,
  ChatRoles,
  ExaAISource,
  ExpertsSource,
  KNMaterialsSource,
  SourceData,
  UserChatMessage,
  VOYAGER_AGENT_ID,
  VoyagerAgentExecutionPayload,
  VoyagerSource,
} from './typings';

const defaultRegionFilter: RetrievalFilterValues = {
  name: 'Global',
  value: 'Global',
};

export const sendAgentChatMessage = createAsyncThunk<
  void,
  [
    UserChatMessage,
    WorkspaceConfiguration,
    ChatHistoryId,
    AvailableAgentTools,
    SearchParamFilters,
  ]
>(
  'agentsChat/sendAgentChatMessage',
  async (
    [
      chatMessage,
      currentWorkspaceConfig,
      chatHistoryId,
      selectedAgentTool,
      searchParamFilters,
    ],
    { rejectWithValue, dispatch, getState, signal },
  ) => {
    const requestId = uuidv4();
    console.debug(`Starting send chat message for request ID: ${requestId}`);
    const functionStartTime = new Date().getTime();

    try {
      const workspaceChatConsumerId = currentWorkspaceConfig.workspaceName;
      if (!workspaceChatConsumerId) {
        return rejectWithValue(
          `Unable to send chat message. Workspace consumer ID is undefined for request ID: ${requestId}`,
        );
      }

      // Used for analytics tracking
      dispatch(setMostRecentChatRequestId(requestId));

      // Add users message to state to be displayed in chat
      dispatch(updateChatStreamData(chatMessage));

      // Show loader after users message has been added to state/chat screen
      dispatch(setChatLoadingState(true));

      // If this is the first chat of a conversation,
      // set the chat history title to the users first message
      if (chatHistoryId === 0) {
        dispatch(
          setActiveChatHistoryTitle({
            title: chatMessage.content,
            filters: chatMessage.filters,
            customerId: currentWorkspaceConfig.customerId,
          }),
        );
      }

      const agentsRequestPayload: AgentsFrameworkRequestBody = {
        agent_id: VOYAGER_AGENT_ID,
        input_query: chatMessage.content,
        request_id: requestId,
        consumer_id: workspaceChatConsumerId,
        selected_tools: [selectedAgentTool],
      };

      // apply chat_history_id only if it's not 0
      if (chatHistoryId > 0) {
        agentsRequestPayload.chat_history_id = chatHistoryId;
      }

      // override the chat history customer ID
      let agentsExecutionPayload: VoyagerAgentExecutionPayload = {
        chat_history_metadata: {
          customer_id: currentWorkspaceConfig.customerId.toString(),
        },
      };

      // set execution payload based on the tool
      if (selectedAgentTool === AvailableAgentTools.Voyager) {
        const todayDate = new Date().toISOString().split('T')[0];

        agentsExecutionPayload = {
          ...agentsExecutionPayload,
          voyager: {
            Voyager: {
              workspace_id: currentWorkspaceConfig.workspaceId.toString(),
              filters: [],
            },
            prompt_input_variables: {
              date: todayDate,
            },
          },
        };
      }

      // Add workspace filters to the retrieval request body
      const workspaceFilters =
        (currentWorkspaceConfig.chat.filters as Filter[]) || [];
      const filtersState = (getState() as RootState).filters;

      if (workspaceFilters.length > 0) {
        workspaceFilters.forEach((filter) => {
          const chatFilter = {
            name: filter.sourceDataKey,
            type: filter.filterType,
          } as ChatFilter;

          agentsExecutionPayload?.voyager?.Voyager.filters?.push(chatFilter);
        });
      }

      // Add search parameters to the retrieval request body
      const retreivalSearchFilterData = filtersState.filters;
      if (retreivalSearchFilterData) {
        Object.keys(retreivalSearchFilterData).forEach((filterKey) => {
          const filterGroup = retreivalSearchFilterData?.[filterKey];
          if (!filterGroup) {
            return;
          }

          filterGroup.forEach((filterValue) => {
            let filter_values = filterValue.filter_values;
            // Data driven has "Region" as "Geo_x002f_System" where `x002f` is a dash `\`
            // UI: For Region only, there is a need to always apply the default Global.
            if (filterValue.name.toLowerCase() === 'geo_x002f_system') {
              filter_values = [
                defaultRegionFilter.value,
                ...filterValue.filter_values,
              ];
            }

            const chatFilter = {
              name: filterValue.name,
              type: filterValue.type,
              filter_values: filter_values,
              predicate: filterValue.predicate,
            } as ChatFilter;

            agentsExecutionPayload?.voyager?.Voyager.filters?.push(chatFilter);
          });
        });
      }
      agentsRequestPayload.execution_payload = agentsExecutionPayload;

      const endpoint = API_ENDPOINTS.agents;

      const response = await fetchData(endpoint, 'POST', agentsRequestPayload, {
        signal,
      });

      if (!response.ok) {
        const data = await response.json();
        console.error(
          `Error calling agents execution with tool ${selectedAgentTool} for request ID ${requestId}. Data: ${data}`,
        );
        return rejectWithValue(data);
      }

      const streamReader = response.body?.getReader();
      if (!streamReader) {
        return rejectWithValue(
          `Unable to get reader from response body, request ID: ${requestId}`,
        );
      }

      let bufferedText = '';
      let sourceData: SourceData | null = null;
      let customMarkDown: boolean = false;
      let boldMarkDown: boolean = false;
      let updatedAssistantText: string = '';
      while (true) {
        const { done, value } = await streamReader.read();

        if (signal.aborted) {
          streamReader?.cancel();
          dispatch(setChatLoadingState(false));
          break;
        }

        if (done) {
          console.debug(`Chat stream completed for request ID: ${requestId}.`);
          dispatch(setChatLoadingState(false));
          break;
        }

        // Decode the incoming chunk
        const assistantResponseChunkText = new TextDecoder().decode(value);

        // Add the current chunk to the buffer
        bufferedText += assistantResponseChunkText;

        // Split the buffer into lines, where each line is potentially a message
        const lines = bufferedText.split('\n\n');

        // Process each line except the last one (since it might be incomplete)
        for (let i = 0; i < lines.length - 1; i++) {
          const line = lines[i].trim();

          if (!line) {
            continue; // Skip empty lines
          }

          if (!line.startsWith('data:')) {
            console.log(
              `Line ${line} from ${lines} does not start with "data:". Skipping...`,
            );
            continue;
          }

          const assistantResponseChunkTextWithoutData = line.replace(
            /^data:/g,
            '',
          );

          try {
            const agentMessageObject = JSON.parse(
              assistantResponseChunkTextWithoutData.trim(),
            ) as AgentMessageData;

            // Skip agent log messages
            if (agentMessageObject.message_type === AgentChatMessageTypes.Log) {
              continue;
            }

            // Set the chat history ID if it's not set
            if (
              agentMessageObject.chat_history_id !== 0 &&
              chatHistoryId === 0
            ) {
              dispatch(
                setActiveChatHistoryId({
                  id: agentMessageObject.chat_history_id,
                  customerId: currentWorkspaceConfig.customerId,
                }),
              );
              dispatch(
                setNewChatHistoryData({
                  chatHistoryId: agentMessageObject.chat_history_id,
                  customerId: currentWorkspaceConfig.customerId,
                }),
              );
            }

            // If the message is a status message, show it in the chat
            if (
              agentMessageObject.message_type === AgentChatMessageTypes.Status
            ) {
              // Agents back end will send the `filters` on a status message.
              // No need to update the chat stream on this info though.
              const pattern: RegExp = /filters available/;
              if (pattern.test(agentMessageObject.message_text)) {
                const filters = agentMessageObject.tool_data.raw_output;
                if (filters.length) {
                  dispatch(clearRetrievalState());
                  dispatch(setChatFilters(filters));
                }
                continue;
              }

              dispatch(
                updateChatStreamData({
                  content: agentMessageObject.message_text,
                  role: ChatRoles.Agent,
                  type: agentMessageObject.message_type,
                  tool: selectedAgentTool,
                }),
              );

              // continue to the next iteration
              continue;
            }

            // based on the tool, we need to handle the source message differently
            const isSourceData = messageIsSourceData(agentMessageObject);
            if (isSourceData) {
              sourceData = getRawOutputFromAgentMessage(agentMessageObject);
              // for validating materials sources having documents
              if (selectedAgentTool === AvailableAgentTools.KNMaterials) {
                const kpCmsIds = Array.from(
                  new Set(
                    Object.values(sourceData).map(
                      (item: any) => item.kp_cms_id,
                    ),
                  ),
                );
                if (kpCmsIds.length > 0) {
                  dispatch(conductKnowledgeSearch({ kpCmsIds }));
                }
              }
              continue;
            }

            // handle showing agent messages
            const currentState = getState() as RootState;
            const currentChatMessages = currentState.agentChat.messages;
            const lastMessage =
              currentChatMessages[currentChatMessages.length - 1];

            if (
              agentMessageObject.message_type === AgentChatMessageTypes.Output
            ) {
              const textOutput =
                agentMessageObject.tool_data.text_output[0].text;

              // Update markdown states
              boldMarkDown = textOutput.includes('**')
                ? !boldMarkDown
                : boldMarkDown;
              customMarkDown =
                textOutput.includes('[') ||
                (customMarkDown && !textOutput.includes(']'));

              if (customMarkDown || boldMarkDown) {
                updatedAssistantText += textOutput;
              } else {
                updatedAssistantText = updatedAssistantText
                  ? updatedAssistantText + textOutput
                  : textOutput;
                handleAssistantOutputResponse(
                  sourceData,
                  selectedAgentTool,
                  lastMessage,
                  updatedAssistantText,
                  searchParamFilters,
                  dispatch,
                );
                updatedAssistantText = '';
              }
            }
          } catch (error) {
            console.error(
              'Encountered an error while processing a chunk, skipping and will use incomplete chunk in the next iteration if possible',
              error,
            );
            // This acts as a continue and accumulates more data in the buffer if incomplete
          }
        }

        // Reassign the buffer to the last line, which might be incomplete
        bufferedText = lines[lines.length - 1].trim();
      }

      const functionEndTime = new Date().getTime();
      const elapsedFunctionTime = functionEndTime - functionStartTime;
      console.debug(
        `Send Agent Chat Message complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}`,
      );
    } catch (error) {
      console.error(
        `Unhandled error sending chat, request ID: ${requestId}, error: ${error}`,
      );
      return rejectWithValue(error as Error);
    }
  },
);

const messageIsSourceData = (agentMessageObject: AgentMessageData): boolean => {
  // Agent Tools uses a message_Type of "output", but the message_text is
  // "COMBINED TOOL EXECUTION OUTPUT". Thats how we know its a source message.
  // See below for an example of a KN Materials source message.
  return agentMessageObject.message_text === 'COMBINED TOOL EXECUTION OUTPUT';
};

const getRawOutputFromAgentMessage = (
  agentMessobject: AgentMessageData,
): KNMaterialsSource | ExaAISource | ExpertsSource | VoyagerSource => {
  const rawOutput = agentMessobject.tool_data.raw_output;

  const isToolThatHasSourcesInOneIndex =
    agentMessobject.tool === AvailableAgentTools.KNMaterials ||
    agentMessobject.tool === AvailableAgentTools.Experts ||
    agentMessobject.tool === AvailableAgentTools.ExaAI;

  // these tools put all their sources in the
  // 0th index of the raw_output array
  if (isToolThatHasSourcesInOneIndex) {
    return rawOutput[0];
  }

  // Voyager tool does not put all its sources in the
  // 0th index of the raw_output array. So for Voyager
  // we need to loop through the raw_output array and
  // normalize it to match the other tools.
  const sourceData = (rawOutput as VoyagerSource[]).reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});

  return sourceData;
};

const handleAssistantOutputResponse = (
  sourceData: SourceData | null,
  selectedAgentTool: AvailableAgentTools,
  lastMessage: AgentChatMessage | UserChatMessage,
  incomingAssistantMessage: string,
  lastFilters: SearchParamFilters,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const lastMessageWasTypeOutput = isLastMessageTypeOutput(lastMessage);

  if (lastMessageWasTypeOutput) {
    handleConcatenatedAgentMessage(
      lastMessage as AgentChatMessage, // we can assume it was agent chat message if it was output
      incomingAssistantMessage,
      sourceData,
      selectedAgentTool,
      lastFilters,
      dispatch,
    );
  } else {
    handleNewAgentMessage(
      incomingAssistantMessage,
      selectedAgentTool,
      lastFilters,
      dispatch,
    );
  }
};

const isLastMessageTypeOutput = (
  lastMessage: AgentChatMessage | UserChatMessage,
): boolean => {
  return (
    lastMessage.role === ChatRoles.Agent &&
    lastMessage.type === AgentChatMessageTypes.Output
  );
};

const handleConcatenatedAgentMessage = (
  lastMessage: AgentChatMessage,
  incomingMessageText: string,
  sourceData: SourceData | null,
  selectedAgentTool: AvailableAgentTools,
  searchParamFilters: SearchParamFilters,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  dispatch(popLastChatMessage());
  const concatenatedMessage = lastMessage.content + incomingMessageText;

  const updateChatStreamPayload: AgentChatMessage = {
    content: concatenatedMessage,
    role: ChatRoles.Agent,
    type: AgentChatMessageTypes.Output,
    tool: selectedAgentTool,
    filters: searchParamFilters,
  };

  // if the message contains citations, we need to add the source data to it.
  const messageContainsCitations = incomingMessageTextContainsCitations(
    selectedAgentTool,
    concatenatedMessage,
  );

  // replace citations with html sup tags
  // only if the message contains citations
  if (sourceData && messageContainsCitations) {
    updateChatStreamPayload.modifiedContent =
      replaceMessageCitationsWithHtmlTags(
        concatenatedMessage,
        selectedAgentTool,
        sourceData,
      );
  }

  dispatch(updateChatStreamData(updateChatStreamPayload));
};

const handleNewAgentMessage = (
  incomingMessageText: string,
  selectedAgentTool: AvailableAgentTools,
  searchParamFilters: SearchParamFilters,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const updateChatStreamPayload: AgentChatMessage = {
    content: incomingMessageText,
    role: ChatRoles.Agent,
    type: AgentChatMessageTypes.Output,
    tool: selectedAgentTool,
    filters: searchParamFilters,
  };

  dispatch(updateChatStreamData(updateChatStreamPayload));
};
