import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const ChatSidebarSkeletonLoader = () => (
  <SkeletonTheme baseColor='#f2f2f2' highlightColor='#ccc'>
    <Skeleton
      count={1}
      style={{ width: '150px', margin: '1rem' }}
      height={30}
    />

    <Skeleton
      count={2}
      style={{
        width: 'calc(100% - 2rem)',
        margin: '1rem',
        marginTop: '0.5rem',
      }}
      height={20}
    />

    <Skeleton
      count={1}
      style={{ width: '150px', margin: '1rem', marginTop: '2rem' }}
      height={30}
    />

    <Skeleton
      count={2}
      style={{
        width: 'calc(100% - 2rem)',
        margin: '1rem',
        marginTop: '0.5rem',
      }}
      height={20}
    />

    <Skeleton
      count={1}
      style={{ width: '150px', margin: '1rem', marginTop: '2rem' }}
      height={30}
    />

    <Skeleton
      count={2}
      style={{
        width: 'calc(100% - 2rem)',
        margin: '1rem',
        marginTop: '0.5rem',
      }}
      height={20}
    />
  </SkeletonTheme>
);

export default ChatSidebarSkeletonLoader;
